import {FormattedMessage} from "react-intl";
import img1 from "../images/demoImage/logo1.png";
import img2 from "../images/demoImage/logo2.png";
import img3 from "../images/demoImage/logo3.png";
import img4 from "../images/demoImage/logo4.png";
import uuid from "../services/uuid";

const constants = {
  notAutorized: {
    creditList: {
      title:<FormattedMessage id="titleCreditType" />,
      back:"/",
      notification: 2,
      menuList: [
        {
          title: <FormattedMessage id="titleCredit" />,
          description: "loan offers",
          icon: "icon-moneys",
          link: "creditOffers"
        },
        {
          title: "Microloan",
          description: "microloan offers",
          icon: "icon-coin2",
          link: "microloans"
        },
        {
          title: "Credit Card",
          description: "credit card offers",
          icon: "icon-card",
          link: "cards"
        },
        {
          title: "Car loan",
          description: "car loan offers",
          icon: "icon-bus",
          link: "autoCredit"
        },
        {
          title: "Mortgage",
          description: "mortgage offers",
          icon: "icon-home-hashtag",
          link: "mortgage"
        },
      ]
    },
    cardList: {
      title:<FormattedMessage id="titleCardType" />,
      back:"/",
      notification: 2,
      menuList: [
        {
          title: "Credit Cards",
          description: "credit card offers",
          icon: "icon-card",
          link: "credit"
        },
        {
          title: "Debit cards",
          description: "credit card offers",
          icon: "icon-card",
          link: "debit"
        },
      ]
    },
    cardOrder: {
      title: <FormattedMessage id="titleOrderCard" />,
      back:"/cards",
      notification: 2,
      menuList: [
        {
          title: "Credit Cards",
          description: "credit card offers",
          icon: "icon-card",
          link: "credit"
        },
        {
          title: "Debit cards",
          description: "credit card offers",
          icon: "icon-card",
          link: "debit"
        },
      ]
    },
    transfersList: {
      title:<FormattedMessage id="titleTransferType" />,
      back:"/",
      notification: 2,
      menuList: [
        {
          title: "QR translation",
          description: "QR code scanner",
          icon: "icon-scan",
          link: "/signIn"
        },
        {
          title: "Between your accounts",
          description: "cards, deposits",
          icon: "icon-convert-card",
          link: "/signIn"
        },
        {
          title: "By phone number",
          description: "bank client",
          icon: "icon-call",
          link: "/signIn"
        },
        {
          title: "To a card of any bank",
          description: "the whole world",
          icon: "icon-card-pos",
          link: "/signIn"
        },
        {
          title: "International transfers",
          description: "between countries",
          icon: "icon-bank",
          link: "/signIn"
        },
        {
          title: "Online conversion",
          description: "between accounts",
          icon: "icon-bitcoin-convert",
          link: "/signIn"
        },
        {
          title: "By details",
          description: "to account number",
          icon: "icon-strongbox",
          link: "/signIn"
        },
      ]
    },
    creditItem: {
      title:<FormattedMessage id="titleCreditType" />,
      back:"/credits",
      notification: 2,
      menuList: [
        {
          title: "Car loan without collateral",
          description: "Get a loan from 10 to 200 million soums with flexible terms from 6 to 60 months at a rate of 18% per annum.",
          image: "iconCards",
        },
        {
          title: "Business Development",
          description: "Get a loan from 10 to 200 million soums with flexible terms from 6 to 60 months at a rate of 18% per annum.",
          image: "iconPayment",
        },
      ]
    },
    creditDetail: {
      back:"credits",
      link:"order",
      notification: 2,
      menuList: [
        {
          title: "Car loan without collateral",
          description: "Get a loan from 10 to 200 million soums with flexible terms from 6 to 60 months at a rate of 18% per annum.",
          image: "iconCards",
        },
        {
          title: "Business Development",
          description: "Get a loan from 10 to 200 million soums with flexible terms from 6 to 60 months at a rate of 18% per annum.",
          image: "iconPayment",
        },
      ]
    },
    cardDetail: {
      back:"cards",
      link:"order",
      notification: 2,
      menuList: [
        {
          title: "Car loan without collateral",
          description: "Get a loan from 10 to 200 million soums with flexible terms from 6 to 60 months at a rate of 18% per annum.",
          image: "iconCards",
        },
        {
          title: "Business Development",
          description: "Get a loan from 10 to 200 million soums with flexible terms from 6 to 60 months at a rate of 18% per annum.",
          image: "iconPayment",
        },
      ]
    },
    cardItem: {
      title:<FormattedMessage id="titleCardType" />,
      back:"/cards",
      notification: 2,
      menuList: [
        {
          title: "Car loan without collateral",
          description: "Get a loan from 10 to 200 million soums with flexible terms from 6 to 60 months at a rate of 18% per annum.",
          image: "iconCards",
        },
        {
          title: "Business Development",
          description: "Get a loan from 10 to 200 million soums with flexible terms from 6 to 60 months at a rate of 18% per annum.",
          image: "iconPayment",
        },
      ]
    },
    cardHistory: {
      title:<FormattedMessage id="titleHistoryOperations" />,
      back:"/myBank/card",
      notification: 2,
      historyList: [
        {
          date: "Jan. 7",
          operations: [
            {
              icon: "icon-bag",
              label: "Dana Shop",
              description: "Grocery stores, supermarkets",
              value: "-20 295.9 UZS",
              status: 0,
              score: "+0б.",
            },
            {
              icon: "icon-wallet-money",
              label: "Анастасия Т.",
              description: "Replenishments",
              value: "+200 000.0 UZS",
              status: 1,
              score: "+100б.",
            },
            {
              icon: "icon-percentage-square",
              label: "Loan repayment",
              description: "Loans, Credits, Mortgages",
              value: "-20 295.9 UZS",
              status: 2,
              score: "+0б.",
            },
            {
              icon: "icon-shop",
              label: "Wolt",
              description: "Cafes and restaurants",
              value: "-20 295.9 UZS",
              status: 0,
              score: "+0б.",
            },
          ],
        },
        {
          date: "Jan. 6",
          operations: [
            {
              icon: "icon-bag",
              label: "Dana Shop",
              description: "Grocery stores, supermarkets",
              value: "-20 295.9 UZS",
              status: 0,
              score: "+0б.",
            },
            {
              icon: "icon-bus",
              label: "Yandex taxi",
              description: "Taxi, Public transport",
              value: "-20 295.9 UZS",
              status: 0,
              score: "0б.",
            },
            {
              icon: "icon-gas-station",
              label: "Helios",
              description: "Gas stations",
              value: "-20 295.9 UZS",
              status: 0,
              score: "+0б.",
            },
          ],
        },
      ],
    },
    creditHistory: {
      title:<FormattedMessage id="titleHistoryOperations" />,
      back:"/myBank/credit",
      notification: 2,
      historyList: [
        {
          date: "Jan. 7",
          operations: [
            {
              icon: "icon-bag",
              label: "Dana Shop",
              description: "Grocery stores, supermarkets",
              value: "-20 295.9 UZS",
              status: 0,
              score: "+0б.",
            },
            {
              icon: "icon-wallet-money",
              label: "Анастасия Т.",
              description: "Replenishments",
              value: "+200 000.0 UZS",
              status: 1,
              score: "+100б.",
            },
            {
              icon: "icon-percentage-square",
              label: "Loan repayment",
              description: "Loans, Credits, Mortgages",
              value: "-20 295.9 UZS",
              status: 2,
              score: "+0б.",
            },
            {
              icon: "icon-shop",
              label: "Wolt",
              description: "Cafes and restaurants",
              value: "-20 295.9 UZS",
              status: 0,
              score: "+0б.",
            },
          ],
        },
        {
          date: "Jan. 6",
          operations: [
            {
              icon: "icon-bag",
              label: "Dana Shop",
              description: "Grocery stores, supermarkets",
              value: "-20 295.9 UZS",
              status: 0,
              score: "+0б.",
            },
            {
              icon: "icon-bus",
              label: "Yandex taxi",
              description: "Taxi, Public transport",
              value: "-20 295.9 UZS",
              status: 0,
              score: "0б.",
            },
            {
              icon: "icon-gas-station",
              label: "Helios",
              description: "Gas stations",
              value: "-20 295.9 UZS",
              status: 0,
              score: "+0б.",
            },
          ],
        },
      ],
    },
    accountHistory: {
      title:<FormattedMessage id="titleHistoryOperations" />,
      back:"/myBank/account",
      notification: 2,
      historyList: [
        {
          date: "Jan. 7",
          operations: [
            {
              icon: "icon-bag",
              label: "Dana Shop",
              description: "Grocery stores, supermarkets",
              value: "-20 295.9 UZS",
              status: 0,
              score: "+0б.",
            },
            {
              icon: "icon-wallet-money",
              label: "Анастасия Т.",
              description: "Replenishments",
              value: "+200 000.0 UZS",
              status: 1,
              score: "+100б.",
            },
            {
              icon: "icon-percentage-square",
              label: "Loan repayment",
              description: "Loans, Credits, Mortgages",
              value: "-20 295.9 UZS",
              status: 2,
              score: "+0б.",
            },
            {
              icon: "icon-shop",
              label: "Wolt",
              description: "Cafes and restaurants",
              value: "-20 295.9 UZS",
              status: 0,
              score: "+0б.",
            },
          ],
        },
        {
          date: "Jan. 6",
          operations: [
            {
              icon: "icon-bag",
              label: "Dana Shop",
              description: "Grocery stores, supermarkets",
              value: "-20 295.9 UZS",
              status: 0,
              score: "+0б.",
            },
            {
              icon: "icon-bus",
              label: "Yandex taxi",
              description: "Taxi, Public transport",
              value: "-20 295.9 UZS",
              status: 0,
              score: "0б.",
            },
            {
              icon: "icon-gas-station",
              label: "Helios",
              description: "Gas stations",
              value: "-20 295.9 UZS",
              status: 0,
              score: "+0б.",
            },
          ],
        },
      ],
    },
    paymentsList: {
      title: <FormattedMessage id="settingsListPayments" />,
      service: [
        {
          title: "Internet",
          icon: "icon-wifi",
          link: "/signIn",
        },
        {
          title: "Mobile communications",
          icon: "icon-call",
          link: "/signIn",
        },
        {
          title: "TV",
          icon: "icon-video-horizontal",
          link: "/signIn",
        },
        {
          title: "Commercial services",
          icon: "icon-home-hashtag",
          link: "/signIn",
        },
        {
          title: "Budget payments",
          icon: "icon-bank",
          link: "/signIn",
        },
        {
          title: "All payments",
          icon: "icon-archive",
          link: "/signIn",
        },
      ],
      favoriteData: [
        {
          title: "RentaNet",
          description: "Mobile communication",
          link: "/signIn",
          icon: img1,
        },
      ],
      providersData: [
        {
          title: "RentaNet",
          description: "payment for Internet",
          link: "/signIn",
          icon: img1,
        },
        {
          title: "RentaNet",
          description: "payment for Internet",
          link: "/signIn",
          icon: img2,
        },
        {
          title: "RentaNet",
          description: "payment for Internet",
          link: "/signIn",
          icon: img3,
        },
        {
          title: "RentaNet",
          description: "payment for Internet",
          link: "/signIn",
          icon: img4,
        },
      ],
      paymentItems: [
        {
          blocked: false,
          card: {
            type: 1,
            number: '• 7811',
          },
          balance: '182.87 UZS',
          label: 'My VISA card',
          description: 'RUB, UZS',
          value: '182.87 UZS',
        },
        {
          blocked: false,
          card: {
            type: 2,
            number: '• 7811',
          },
          balance: '247 145.87 UZS',
          label: 'Linked map',
          description: 'USD, RUB, UZS',
          value: '190 182.22 UZS',
        },
        {
          blocked: false,
          card: {
            type: 3,
            number: '• 7846',
          },
          balance: '46 718.22 UZS',
          label: 'Linked map',
          description: 'USD, RUB',
          value: '194.22 UZS',
        },
      ]
    },
  },
  autorized: {
    transfersList: {
      title:<FormattedMessage id="titleTransferType" />,
      back:"/",
      notification: 2,
      menuList: [
        {
          title: "QR translation",
          description: "QR code scanner",
          icon: "icon-scan",
          link: "qrTransfer"
        },
        {
          title: "Between your accounts",
          description: "cards, deposits",
          icon: "icon-convert-card",
          link: "betweenAccounts"
        },
        {
          title: "By phone number",
          description: "bank client",
          icon: "icon-call",
          link: "phoneNumber"
        },
        {
          title: "To a card of any bank",
          description: "the whole world",
          icon: "icon-card-pos",
          link: "card2card"
        },
        {
          title: "International transfers",
          description: "between countries",
          icon: "icon-bank",
          link: "swift"
        },
        {
          title: "Online conversion",
          description: "between accounts",
          icon: "icon-bitcoin-convert",
          link: "conversion"
        },
        {
          title: "By details",
          description: "to account number",
          icon: "icon-strongbox",
          link: "account"
        },
      ]
    },
    paymentsList: {
      title: <FormattedMessage id="settingsListPayments" />,
      service: [
        {
          title: "Internet",
          icon: "icon-wifi",
          link: "internet",
        },
        {
          title: "Mobile communications",
          icon: "icon-call",
          link: "cellular",
        },
        {
          title: "TV",
          icon: "icon-video-horizontal",
          link: "tv",
        },
        {
          title: "Commercial services",
          icon: "icon-home-hashtag",
          link: "public Facilities",
        },
        {
          title: "Budget payments",
          icon: "icon-bank",
          link: "budgetPayments",
        },
        {
          title: "All payments",
          icon: "icon-archive",
          link: "allPayments",
        },
      ],
      favoriteData: [
        {
          title: "RentaNet",
          description: "Mobile communication",
          link: "internet/RentaNet",
          icon: img1,
        },
      ],
      providersData: [
        {
          title: "RentaNet",
          description: "payment for Internet",
          link: "internet/RentaNet",
          icon: img1,
        },
        {
          title: "RentaNet",
          description: "payment for Internet",
          link: "internet/RentaNet",
          icon: img2,
        },
        {
          title: "RentaNet",
          description: "payment for Internet",
          link: "internet/RentaNet",
          icon: img3,
        },
        {
          title: "RentaNet",
          description: "payment for Internet",
          link: "internet/RentaNet",
          icon: img4,
        },
      ],
      paymentItems: [
        {
          blocked: false,
          card: {
            type: 1,
            number: '• 7811',
          },
          balance: '182.87 UZS',
          label: 'My VISA card',
          description: 'RUB, UZS',
          value: '182.87 UZS',
        },
        {
          blocked: false,
          card: {
            type: 2,
            number: '• 7811',
          },
          balance: '247 145.87 UZS',
          label: 'Linked map',
          description: 'USD, RUB, UZS',
          value: '190 182.22 UZS',
        },
        {
          blocked: false,
          card: {
            type: 3,
            number: '• 7846',
          },
          balance: '46 718.22 UZS',
          label: 'Linked map',
          description: 'USD, RUB',
          value: '194.22 UZS',
        },
      ]
    },
    myBank: {
      cardItems: [
        {
          label: <FormattedMessage id="settingsListCards" />,
          children: [
            {
              card: {
                type: 2,
                number: "• 5576"
              },
              label: "MasterCard",
              description: "USD, RUB, UZS",
              value: "190 182.22 UZS",
              cashback: "563.1 б.",
              link: `card/${uuid()}`,
            },
            {
              card: {
                type: 1,
                number: "• 4320"
              },
              label: "Моя карта Visa",
              description: "RUB, UZS",
              value: "182.22 UZS",
              link: `card/${uuid()}`,
            },
            {
              card: {
                type: 2,
                number: "• 0243"
              },
              label: "MasterCard",
              description: "UZS",
              value: "1 182.22 UZS",
              cashback: "563.1 б.",
              link: `card/${uuid()}`,
            },
          ],
        },
        {
          label: <FormattedMessage id="titleCreditCards" />,
          children: [
            {
              card: {
                type: 2,
                number: "• 7811"
              },
              label: "MasterCard",
              description: "USD, RUB, UZS",
              value: "-2 082.22 UZS",
              payment: "100 000 UZS",
              link: `card/${uuid()}`,
            },
          ],
        },
        {
          label: <FormattedMessage id="settingsListCredits" />,
          children: [
            {
              icon: "icon-percentage-square",
              label: <FormattedMessage id="labelCarPurchasing" />,
              description: <><FormattedMessage id="labelCarPurchasing" /> 22.02.2024</>,
              value: "-2 082.22 UZS",
              debt: "33 982 UZS",
              link: `credit/${uuid()}`,
            },

          ],
        },
        {
          label: <FormattedMessage id="titleAccounts" />,
          children: [
            {
              icon: "icon-strongbox",
              label: <FormattedMessage id="labelCurrentAccount" />,
              description: "UZ91 •••2948",
              value: "54 295.9 UZS",
              link: `account/${uuid()}`,
            },
            {
              icon: "icon-strongbox-2",
              label: <FormattedMessage id="labelDepositAccount" />,
              description: "UZ91 •••8473",
              value: "54 295.9 UZS",
              link: `account/${uuid()}`,
            },
          ],
        },
      ],
      reasonList: [
        {
          value:1,
          label: "The card is damaged and does not work"
        }
      ],
      deliveryMethodList: [
        {label: "I'll pick it up at the department", value: 1},
        {label: "Delivery by courier", value: 2}
      ],
      departmentList: [
        {label: "Department 1", value: 1},
        {label: "Department 2", value: 2}
      ],
      cityList: [
        {label: "Москва", value: 1}
      ],
      streetList: [
        {label: "Малая Никитская", value: 1}
      ],
      periodTypeList: [
        {value: 1, label: "For a while"},
        {value: 2, label: "Forever"},
        {value: 3, label: "Forever and order a new one"},
      ],
    },
    creditInfo: {
      title: "Car purchase",
      infoCreditFormData: {
        title: <FormattedMessage id="titleLinkedAccount"/>,
        infoList: [
          {
            title: "Contract:",
            text: "#237468456"
          },
          {
            title: "The interest rate:",
            text: "20% годовых"
          },
          {
            title: "Issued / Before:",
            text: "February 20, 2024 / January 19, 2025"
          },
        ]
      }
    },
    notification: {
      notificationList: [
        {
          date: "05/20/2024",
          message: [
            {
              icon: "icon-send-2",
              title: "Your opinion is important!",
              text: [
                "Rate the work of our consultant.",
                "<a href=''>More details</a>"
              ]
            }
          ]
        },
        {
          date: "05/14/2024",
          message: [
            {
              icon: "icon-password-check",
              title: "One-time passwords",
              text: [
                "Do not tell anyone the code: <b>11122&</b><br/>Write off 1000 UZS in Internet-shop."
              ]
            },
            {
              icon: "icon-strongbox-2",
              title: "Withdrawal from deposit",
              text: [
                "Withdrawal from deposit UZ•7473<br/> in the amount of: <b>183,000 UZS</b>",
                "Available: <b>465,000,000 UZS</b>"
              ]
            },
          ]
        },
        {
          date: "10.05.2024",
          message: [
            {
              icon: "icon-flash2",
              title: "2$ for transfer to foreign cards!",
              text: [
                "Make instant transfers at the lowest rates to foreign cards."
              ]
            },
          ]
        },
      ]
    },
  }
}

export default constants