import {combineReducers} from 'redux'
import authorization from './authorization';
import registration from './registration'
import transfer from  './transfer'


export default combineReducers({
  authorization,
  registration,
  transfer,
})