import React from 'react';
import classes from './button.module.scss';
import {Link} from "react-router-dom";
import mapIconItem from "../../../../services/mapIconItem";
import mapClassName from "../../../../services/mapClassName";
import Icon from "../../Icon";

const ServiceButton = (props) => {
  const {link, title, description, icon, vertical, bordered, size, action} = props
  const classNames = () => {
    const style = ["vertical", "left", "small"]
    const property = Object.keys(props)
    return mapClassName(property.filter(i => style.includes(i)), classes)
  }

  const children = (
    <div className={`${classes.wrapper} ${classNames()}`}>
      {
        icon ?
          <div className={`${bordered ? classes.bordered : null}`}>
            <Icon name={icon} size={size}/>
          </div> : null
      }
      <div className={vertical ? classes.verticalText : classes.text}>
        {title}
      </div>
      {
        description &&
        <div className={`${vertical ? classes.verticalText : classes.text} ${classes.description}`}>
          {description}
        </div>
      }
    </div>
  )

  return (
    <div style={{height: "100%"}}>
      {
        link ? <Link to={`${link}`}>
          {children}
        </Link> : <a onClick={action}>
          {children}
        </a>
      }

    </div>
  );
};

export default ServiceButton;