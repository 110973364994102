import React from 'react';
import {Input} from "antd";
import classes from "./textArea.module.scss"
import {useIntl} from "react-intl";

const TextArea = (props) => {
  const intl = useIntl()
  return (
    <>
      <Input.TextArea
        {...props}
        className={classes.input}
        placeholder={props.locale ? intl.formatMessage({id: props.locale}): props.placeholder ? props.placeholder : ""}
      />
    </>
  );
};

export default TextArea;