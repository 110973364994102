import React from 'react';
import classes from './slider.module.scss'
import {useNavigate} from "react-router-dom";




const SlideItem = (props) => {
  let navigate = useNavigate();

  const action = () => {
    console.log("CLICK", props.link, props.action)
    if (props.action){
      console.log("CLICK1", props.link)
      props.action()
    } else if (props.link){
      console.log("CLICK2", props.link)
      navigate(props.link, { state: { from: "slider" } })
    } else null
  }
  return (
    <div className={classes.slideBlock}>
      <div className={classes.slideItem} style={props?.bgGradient ? {background: props?.bgGradient} : null}>
        <div
          className={classes.slideItem}
          onClick={action}
          style={{backgroundColor:props?.bgColor, color:props?.color, backgroundImage: `url(${props?.background})`}}
        >
          {
            props?.image && <img alt="" src={props.image} className={classes.banner} />
          }
        </div>
      </div>

    </div>
  );
};

export default SlideItem;