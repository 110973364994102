import React from 'react';
import {Col, Flex, Row, Slider} from "antd";
import InputNumber from "../../inputs/InputNumber";
import classes from "./termSelect.module.scss"

const TermSelect = (props) => {
  const {min, max} = props
  const marks = {0:0,3:3,6:6,9:9,12:12,15:15,18:18,21:21,24:24,27:27}
  return (
    <div className={classes.wrapper}>
      <Flex gap={16} wrap="nowrap" >
        <div style={{width: "100%"}}>
          <Slider
            {...props}
            min={min}
            max={max}
            marks={marks}
            tooltip={{
              open: false
            }}
          />
        </div>
        <div>
          <InputNumber
            {...props}
            defaultValue={1}
            style={{width: 50}}
          />
        </div>
      </Flex>


    </div>

  );
};

export default TermSelect;