import React, {useState, useEffect} from 'react';
import classes from "./scannerQR.module.scss"
import {Html5Qrcode} from "html5-qrcode";
import Icon from "../Icon";
import {QRCode, Row} from "antd";
import ContentModal from "../modal/ContentModal/ContentModal";
import {FormattedMessage} from "react-intl";

const ScannerQr = (props) => {

  const [isEnabled, setEnabled] = useState(true)
  const [qrData, setQrData] = useState(true)

  useEffect(() => {
    const config = {
      fps: 10,
      qrbox: {width: 300, height:300}
    }
    const html5QrCode = new Html5Qrcode("qrCodeContainer")
    const qrScannerStop = () => {
      if(html5QrCode && html5QrCode.isScanning){
        html5QrCode.stop().then(()=> {})
      }
    }

    const qrCodeSuccess = (decodedText) => {
      setQrData(decodedText)
      props?.callback(decodedText)
      setEnabled(false)
    }

    if(isEnabled){
      html5QrCode.start({facingMode: "environment"}, config, qrCodeSuccess)
      setQrData("")
    } else {
      qrScannerStop()
    }

    return(() => {
      qrScannerStop()
    })

  }, [isEnabled])

  return (
    <div className={classes.wrapper} >
      <div className={classes.scanner} id="qrCodeContainer"></div>
      <p>
        <FormattedMessage id="textPointCameraAtQRCode" /><br/>
        <FormattedMessage id="textAppWillAutomaticallyRecognize" />
      </p>
      <div className={classes.buttonWrapper} onClick={() => props.showQR()}>
        <Icon name={"icon-scan-barcode " + classes.button }/>
        <FormattedMessage id="bottomMenuMyQR" />
      </div>
    </div>
  );
};

export default ScannerQr;