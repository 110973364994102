import React from 'react';
import classes from "./providerList.module.scss"
import {Image, List} from "antd";
import {Link} from "react-router-dom";
import ProviderListItem from "../ProviderListItem";

const ProviderList = (props) => {
  return (
    <div className={classes.wrapper}>
      <List
        size="small"
        dataSource={props.data}
        renderItem={(item) => {
          return (
            <List.Item>
              <Link to={`${item.link}`} style={{width:"100%"}}>
                <ProviderListItem data={item}/>
              </Link>
            </List.Item>
          )
        }}
      />
    </div>

  );
};

export default ProviderList;