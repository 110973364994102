import React from 'react';
import classes from "./wrapper.module.scss";
import mapClassName from "../../../services/mapClassName";



const Wrapper = (props) => {
  const classNames = () => {
    const style = ["nopadding", "transparent", "nomargin", "left", "height100"]
    const property = Object.keys(props)
    return mapClassName(property.filter(i => style.includes(i)), classes)
  }
  return (
    <div style={{...props?.style}} className={`${classes.wrapper} ${classNames()}`}>
      {props?.children}
    </div>
  );
};

export default Wrapper;