import React from 'react';
import {Select as ANTSelect } from "antd"
import classes from "./select.module.scss"
import mapIconItem from "../../../../services/mapIconItem";
import {useIntl} from "react-intl";

const { Option } = ANTSelect;

const Select = (props) => {
  const intl = useIntl()

  return (
    <div className={classes.wrapper}>
      <ANTSelect
        {...props}
        className={classes.select}
        onChange={props.onChange}
        suffixIcon={mapIconItem("arrowDown")}
        placeholder={props.locale ? intl.formatMessage({id: props.locale}): props.placeholder ? props.placeholder : ""}
        //defaultValue={props.setDefault ? props.data[props.setDefault] : ""}
      >
        {props.data.map((el,ind)=>{
          return <Option key={ind} value={el.value}>{el.label}</Option>
        })}
      </ANTSelect>
    </div>
  );
};

export default Select;