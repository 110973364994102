import React, {Component} from 'react';
import {connect} from 'react-redux';
import classes from './creditRepayment.module.scss';
import Header from "../../../../Header";
import BottomMenu from "../../../../ui/menu/BottomMenu";
import Wrapper from "../../../../ui/wrapper";
import CardListItem from "../../../../ui/infoBlocks/CardListItem";
import {Col, Form, Input, Row} from "antd";
import Services from "../../../../../services/Services";
import TextInput from "../../../../ui/inputs/TextInput";
import PrimaryButton from "../../../../ui/buttons/PrimaryButton";
import withRouter from "../../../../../hoc/withRouter";
import {setBackLink} from "../../../../../store/actions/authorization";
import TextArea from "../../../../ui/inputs/TextArea";
import {FormattedMessage} from "react-intl";
import dayjs from "dayjs";



class CreditRepayment extends Component {
  repaymentForm = React.createRef()

  componentDidMount() {
    const {setBackLink, location: {pathname}, params:{id}} =this.props
    setBackLink(`/${pathname.split("/")[1]}/${pathname.split("/")[2]}/${id}`)
  }

  render() {
    const getRules = Services.formValidateRules
    const {params: {id}, partial, backLink} = this.props
    const accountData = {
      blocked: true,
      icon: "icon-strongbox",
      label: <FormattedMessage id="titleLinkedAccount" />,
      description: "UZ91 •••2948",
      value: "10 662.1 UZS",
      balance: '247 145.87 UZS',
      paymentPeriod: dayjs('2024-02-22').format('D MMMM'),
      totalSum: '1 000 000 UZS',
      amountDeposit: '645 000 UZS',
      creditDebt: '355 000 UZS',
    };

    const formRepayment = [
      {
        name: "email",
        label: "E-mail",
        rules: getRules("email"),
        children: <TextInput placeholder="Введите E-mail" locale="placeholderEnterEmail"/>
      },
      {
        name: "comment",
        label: <FormattedMessage id="labelComment" />,
        children: <TextArea rows={5} placeholder="(не обязательно)" locale="placeholderNotNecessary"/>
      },
    ]

    return (
      <div className={classes.wrapper}>
        <Header back={backLink} title={ <><FormattedMessage id="titleToRepay" />&nbsp;{partial ? <FormattedMessage id="titlePartially" /> : <FormattedMessage id="titleFully" />}</>} empty/>
        <Wrapper>
          <CardListItem data={accountData} green />
        </Wrapper>
        <Wrapper style={{paddingBottom: 24, paddingTop: 24}}>
          <Form
            ref={this.repaymentForm}
            size="large"
            layout="vertical"
            className={classes.form}
          >
            {Services.mapFormItem(formRepayment, this.repaymentForm)}
          </Form>
          <p><FormattedMessage id="textCommission" />: <FormattedMessage id="textNotCharged" /></p>
          <p style={{marginBottom: 24}}>
            <FormattedMessage id="textHaveBankCardIssued" />
          </p>
          <PrimaryButton title={ <><FormattedMessage id="titleToRepay" />{` ${accountData.creditDebt}`}</>} secondary/>
        </Wrapper>
        <BottomMenu/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    backLink : state.authorization.backLink
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setBackLink: (backLink) => dispatch(setBackLink({backLink})),
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(CreditRepayment));