import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../../Header';
import classes from './creditInfo.module.scss';
import Wrapper from '../../../../ui/wrapper';
import ServiceList from "../../../../ui/infoBlocks/ServiceList";
import CardListItem from "../../../../ui/infoBlocks/CardListItem";
import ListMenu from "../../../../ui/menu/ListMenu";
import BottomMenu from "../../../../ui/menu/BottomMenu";
import {Col, List, Progress, Row} from "antd";
import PrimaryButton from "../../../../ui/buttons/PrimaryButton";
import Services from "../../../../../services/Services";
import mapIconItem from "../../../../../services/mapIconItem";
import ContentModal from "../../../../ui/modal/ContentModal/ContentModal";
import withRouter from "../../../../../hoc/withRouter";
import {setBackLink} from "../../../../../store/actions/authorization";
import {FormattedMessage} from "react-intl";
import dayjs from 'dayjs'

class CreditInfo extends Component {
  state = {
    creditInfo: false,
  }

  componentDidMount() {
    const {setBackLink, location: {pathname}} =this.props
    setBackLink(`/${pathname.split("/")[1]}`)
  }

  render() {
    const {navigate, config} = this.props
    const showModal = (state) => {

      const prevState = this.state[state]
      this.setState({
        [state]: !prevState
      });
    }

    const src = [
      {
        text: <FormattedMessage id="titlePartialRepayment" />,
        icon: 'icon-menu-board',
        action: () =>navigate("repaymentPartial"),
      },
      {
        text: <FormattedMessage id="titleFullRepayment" />,
        icon: 'icon-receipt',
        action: () =>navigate("repayment"),
      },
      {
        text: <FormattedMessage id="titleAboutLoan" />,
        icon: 'icon-discount-circle',
        action: () => showModal("creditInfo")
      },
    ];

    const menuList = [
      {
        title: <FormattedMessage id="titlePaymentSchedule" />,
        description: <FormattedMessage id="titleOnLoan" />,
        icon: 'icon-calendar',
        link: 'calendar',
      },
      {
        title: <FormattedMessage id="titleExtract" />,
        description: <FormattedMessage id="titleHistoryOperations" />,
        icon: 'icon-receipt-search',
        link: 'history',
      },
    ];

    const accountData = {
      blocked: true,
      icon: "icon-strongbox",
      label: <FormattedMessage id="titleLinkedAccount" />,
      description: "UZ91 •••2948",
      value: "10 662.1 UZS",
      balance: '247 145.87 UZS',
      paymentPeriod: dayjs('2024-01-20').format('D MMMM'),
      totalSum: '1 000 000 UZS',
      amountDeposit: '645 000 UZS',
      creditDebt: '355 000 UZS',
    };

    const infoCreditFormData = {
      title: "О кредите",
      infoList: [
        {
          title: "Договор:",
          text: "#237468456"
        },
        {
          title: "Процентная ставка:",
          text: "20% годовых"
        },
        {
          title: "Выдан / До:",
          text: "20 февраля 2024 / 19 января 2025"
        },
      ]
    }

    const form = (data) => {
      return <div className={classes.modalPadding}>
        <Row gutter={[16,16]}>
          <Col span={24}>
            <h3>{data?.title}</h3>
            {
              data?.text &&
              <p>{data?.text}</p>
            }
            {
              data?.infoList && <List
                //bordered={false}
                dataSource={data.infoList}
                renderItem={(item, index) => (
                  <List.Item className={classes.listItem}>
                    <Row className={classes.listItemRow} gutter={[16,16]}>
                      <Col span={12}>
                        <span className={classes.titleAbout}>{item.title}</span>
                      </Col>
                      <Col span={12}>
                        <span>{item.text}</span>
                      </Col>
                    </Row>
                  </List.Item>
                )}
              />
            }
          </Col>
          <Col span={24}>
            <Row gutter={[16,16]}>
              <Col span={24}>
                <PrimaryButton bordered onClick={() => showModal("creditInfo")} title={<FormattedMessage id="buttonBack" />} small/>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    }

    return (
      <div className={classes.wrapper + ` ${accountData.blocked ? classes.danger : ""}`}>
        <Header back="/myBank" title={config?.title} transparent danger={accountData.blocked} showInfo={() => {}} />
        <Wrapper transparent style={{marginTop:0}}>
          <div className={classes.title}>
            <label>
              <FormattedMessage id="labelPaymentAmount" />
            </label>
            <div>{accountData.balance}</div>
            <div className={classes.paymentPeriod}>
              {
                accountData.blocked ? <span><FormattedMessage id="textPayLoanToday" />,<br/>
                <FormattedMessage id="textYouAreOverdue" />.</span> : <span><FormattedMessage id="textMandatoryPayment" /><br/>
                <FormattedMessage id="textBefore" /> {accountData.paymentPeriod}</span>
              }

            </div>
          </div>
        </Wrapper>
        <Wrapper style={{ marginBottom: 24 }}>
          <Row gutter={[24,18]}>
            {/*<Col span={24}>
              <Row>
                <Col span={12} className={Services.mapClassName(["block"],classes)}><FormattedMessage id="labelCreditAmount" /></Col>
                <Col span={12} className={Services.mapClassName(["block", "textRight"],classes)}>{accountData.totalSum}</Col>
              </Row>
            </Col>*/}

            <Col span={24}>
              <Row>
                <Col span={24} className={Services.mapClassName(["block"],classes)}>
                  <label><FormattedMessage id="labelDebtAmount" /></label>
                  {accountData.creditDebt}
                </Col>
              </Row>
              <Progress
                size={["100%",8]}
                showInfo={false}
                percent={70}
              />
              {/*<Row>
                <Col span={12} className={Services.mapClassName(["block"],classes)}>
                  <label><FormattedMessage id="labelTotalContributed" /></label>
                  {accountData.amountDeposit}
                </Col>
                <Col span={12} className={Services.mapClassName(["block", "textRight"],classes)}>
                  <label><FormattedMessage id="labelDebtAmount" /></label>
                  {accountData.creditDebt}
                </Col>
              </Row>*/}
            </Col>
            <Col span={24}>
              <PrimaryButton title={<><FormattedMessage id="buttonPay" />{` ${accountData.balance}`}</>}/>
            </Col>
          </Row>
        </Wrapper>
        <Wrapper nopadding transparent >
          <ServiceList data={menuList} nopadding nomargin size={22} borderIcon />
        </Wrapper>
        <Wrapper>
          <CardListItem data={accountData} green />
        </Wrapper>
        <Wrapper nopadding>
          <ListMenu borderIcon data={src} />
        </Wrapper>
        <ContentModal
          open={this.state.creditInfo}
          showModal={() => showModal("creditInfo")}
          data={form(config?.infoCreditFormData)}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setBackLink: (backLink) => dispatch(setBackLink({backLink})),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreditInfo));
