import React from 'react';
import classes from "./listMenu.module.scss"
import {ConfigProvider, List} from "antd";
import mapIconItem from "../../../../services/mapIconItem";
import mapClassName from "../../../../services/mapClassName";
import Icon from "../../Icon";

const ListMenu = (props) => {
  const {data} = props

  const classNames = () => {
    const style = ["borderIcon"]
    const property = Object.keys(props)
    return mapClassName(property.filter(i => style.includes(i)), classes)
  }




  return (
    <ConfigProvider
      theme={{
        components: {
          List: {
            itemPaddingSM: '0px 0px',
          },
        },
      }}
    >
      <List
        dataSource={data}
        renderItem={(item) => {
          console.log("ITEM", item)
          return <List.Item
            onClick={()=>item?.action()}
            size="small"
          >
            <div className={classes.item}>
              <span className={`${classes.icon} ${classNames()}`}><Icon name={item.icon}/></span> <span className={classes.text}>{item.text}</span> <span className={classes.arrow}>{mapIconItem("arrowRight")}</span>
            </div>
          </List.Item>

        }
        }
      />
    </ConfigProvider>
  );
};

export default ListMenu;