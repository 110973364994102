import React from 'react';
import {Button, Space} from 'antd';
import classes from './button.module.scss'
import {Link} from "react-router-dom";
import mapClassName from "../../../../services/mapClassName";

const PrimaryButton = (props) => {
  const {title, link, size, type, timer, disabled} = props

  const [counter, setCounter] = React.useState(false);

  if(timer && counter === false) {
    setCounter(timer)
  }

  React.useEffect(() => {
    const setTimer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => {
      clearInterval(setTimer)};
  }, [counter]);


  const classNames = () => {
    const style = ["bordered", "secondary", "default", "small", "danger", "disabled"]
    const property = Object.keys(props)
    return mapClassName(property.filter(i => style.includes(i)), classes)
  }

  return (
    <Space
      direction="vertical"
      style={{
        width: '100%',
      }}
    >
      {link ?
        <Link to={`${link}`}>
          <Button
            {...props}
            size={size ? size : "large"}
            type="primary"
            block
            className={`${classes.button} ${classNames()}`}
          >
            {title}
          </Button>
        </Link> :
        <Button
          {...props}
          size={size ? size : "large"}
          type="primary"
          block
          className={`${classes.button} ${classNames()}`}
          onClick={props?.onClick}
          disabled={disabled || timer ? counter > 0 : false}
        >
          {title}
          {counter > 0 ? ` (${counter})` : null}
        </Button>
      }
    </Space>
  );
};

export default PrimaryButton;