import classes from './App.module.scss';
import './fonts/gilroy.css';
import './fonts/iconly.min.css';
import { Component, useEffect } from 'react';
import TgNav from './components/TgNav';
import { useTelegram } from './hooks/useTelegram';
import { IntlProvider } from 'react-intl'
import { LOCALES } from 'i18n/locales'
import { messages } from 'i18n/messages'

import { Route, Routes, Navigate } from 'react-router-dom';
import SignIn from './components/pages/SignIn';
import { connect } from 'react-redux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Register from './components/pages/Register';
import withRouter from './hoc/withRouter';
import { changeInput, setBackLink, signIn } from './store/actions/authorization';
import IndexPage from './components/pages/IndexPage';
import Home from './components/pages/autorized/Home';
import Profile from './components/pages/autorized/Profile';
import Notification from './components/pages/autorized/Notification';
import Identification from './components/pages/Identification';
import CreditList from './components/pages/notAutorized/credits/CreditList';
import Credits from './components/pages/notAutorized/credits/Credits';
import CreditDetail from './components/pages/notAutorized/credits/CreditDetail';
import MyBank from './components/pages/autorized/MyBank';
import CardInfo from './components/pages/autorized/cards/CardInfo';
import CardRequisites from './components/pages/autorized/cards/CardRequisites';
import CardBlock from './components/pages/autorized/cards/CardBlock';
import AccountInfo from './components/pages/autorized/account/AccountInfo';
import AccountRequisites from './components/pages/autorized/account/AccountRequisites/';
import CreditInfo from "./components/pages/autorized/credit/CreditInfo";
import CreditRepayment from "./components/pages/autorized/credit/CreditRepayment";
import Payments from "./components/pages/autorized/payments/Payments";
import Providers from "./components/pages/autorized/payments/Providers";
import Calendar from "./components/pages/autorized/payments/Calendar";
import PaymentItem from "./components/pages/autorized/payments/PaymentItem";
import Services from "./services/Services";
import SecurityCode from "./components/pages/SecurityCode";
import BottomMenu from "./components/ui/menu/BottomMenu";
import constantsRus from "./constants/constants";
import constantsEng from "./constants/constantsEng";
import CardReissue from "./components/pages/autorized/cards/CardReissue";
import History from "./components/pages/autorized/History";
import OrderCard from "./components/pages/notAutorized/cards/OrderCard/OrderCard";
import OrderCredit from "./components/pages/notAutorized/credits/OrderCredit/OrderCredit";
import TransferForm from "./components/pages/notAutorized/transfers/TransferForm/TransferForm";
import QRCodeRequisites from "./components/pages/notAutorized/transfers/QRCodeRequisites/QRCodeRequisites";
import ForgotAccessCode from "./components/pages/ForgotAccessCode/ForgotAccessCode";
import TestStorage from "./components/pages/TestStorage/TestStorage";

const { tg, onToggleButton } = useTelegram();

class App extends Component {

  readCookie(name) {
    const matches = document.cookie.match(new RegExp(
      `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  componentDidMount() {
    tg.ready();
    //Подключение демо api удалить
    Services.demo()
    ///
    if (navigator.cookieEnabled === true) {
      const login = this.readCookie('uzb-app-login');
      const password = this.readCookie('uzb-app-pass');
      if ((login !== null && login !== undefined) && (password !== null && password !== undefined)) {
        this.props.login();
      }
    }
  }

  render() {
    const { backLink, signIn, locale } = this.props;
    const login = document.cookie.match(/uzb-app-login=(.+?)(;|$)/);
    const password = document.cookie.match(/uzb-app-pass=(.+?)(;|$)/);
    const constants = locale === "ru-RU" ? constantsRus : constantsEng

    const notAuthorizedRoutes = (
      <Routes>
        <Route index element={<IndexPage />} />
        <Route path="signIn" element={<SignIn />} />
        <Route path="securityCode" element={<SecurityCode />} />
        <Route path="registration" element={<Register />} />
        <Route path="identification" element={<Identification />} />
        <Route path="forgotAccessCode" element={<ForgotAccessCode />} />

        <Route path="credits" element={<CreditList config={constants.notAutorized.creditList} />} />
        <Route path="credits/:id" element={<Credits config={constants.notAutorized.creditItem} />} />
        <Route path="credits/:id/:link" element={<CreditDetail config={constants.notAutorized.creditDetail}/>} />
        <Route path="credits/:id/:link/order" element={<OrderCredit />} />

        <Route path="cards" element={<CreditList config={constants.notAutorized.cardList}/>} />
        <Route path="cards/:id" element={<Credits config={constants.notAutorized.cardItem} />} />
        <Route path="cards/:id/:link" element={<CreditDetail config={constants.notAutorized.cardDetail} />} />
        <Route path="cards/:id/:link/order" element={<OrderCard config={constants.notAutorized.cardOrder} />} />

        <Route path="transfers" element={<CreditList config={constants.notAutorized.transfersList} />} />
        <Route path="transfers/:id" element={<TransferForm/>} />

        <Route path="payments" element={<Payments config={constants.notAutorized.paymentsList} />} />

        <Route path="testStorage" element={<TestStorage/>}/>

        <Route
          path="*"
          element={
            <Navigate index to="/" />
          }
        />
      </Routes>
    );

    const authorizedRoutes = (
      <Routes>
        <Route index element={<Home />} />
        <Route path="profile" element={<Profile />} />
        <Route path="notification" element={<Notification config={constants.autorized.notification} />} />} />

        <Route path="myBank" element={<MyBank config={constants.autorized.myBank} />} />
        <Route path="myBank/card/:id" element={<CardInfo config={constants.autorized.myBank} />} />
        <Route path="myBank/card/:id/requisites" element={<CardRequisites />} />
        <Route path="myBank/card/:id/reissue" element={<CardReissue config={constants.autorized.myBank}/>} />
        <Route path="myBank/card/:id/block" element={<CardBlock config={constants.autorized.myBank} />} />
        <Route path="myBank/card/:id/history" element={<History config={constants.notAutorized.cardHistory} />} />

        <Route path="myBank/credit/:id" element={<CreditInfo config={constants.autorized.creditInfo} />} />
        <Route path="myBank/credit/:id/calendar" element={<Calendar />} />
        <Route path="myBank/credit/:id/repayment" element={<CreditRepayment/>} />
        <Route path="myBank/credit/:id/repaymentPartial" element={<CreditRepayment partial/>} />
        <Route path="myBank/credit/:id/history" element={<History config={constants.notAutorized.creditHistory} />} />

        <Route path="myBank/account/:id" element={<AccountInfo config={constants.autorized.creditInfo}/>} />
        <Route path="myBank/account/:id/requisites" element={<AccountRequisites />} />
        <Route path="myBank/account/:id/history" element={<History config={constants.notAutorized.accountHistory} />} />

        <Route path="credits" element={<CreditList config={constants.notAutorized.creditList} />} />
        <Route path="credits/:id" element={<Credits config={constants.notAutorized.creditItem} />} />
        <Route path="credits/:id/:link" element={<CreditDetail config={constants.notAutorized.creditDetail}/>} />
        <Route path="credits/:id/:link/order" element={<OrderCredit />} />

        <Route path="cards" element={<CreditList config={constants.notAutorized.cardList}/>} />
        <Route path="cards/:id" element={<Credits config={constants.notAutorized.cardItem} />} />
        <Route path="cards/:id/:link" element={<CreditDetail config={constants.notAutorized.cardDetail} />} />
        <Route path="cards/:id/:link/order" element={<OrderCard config={constants.notAutorized.cardOrder} />} />

        <Route path="transfers" element={<CreditList config={constants.autorized.transfersList} />} />
        <Route path="transfers/:id" element={<TransferForm/>} />

        <Route path="transfers/:id/myQRCode" element={<QRCodeRequisites/>} />
        <Route path="myQRCode" element={<QRCodeRequisites/>} />

        <Route path="incompleteOperations" element={<CreditRepayment/>} />
        <Route path="payments" element={<Payments config={constants.autorized.paymentsList} />} />
        <Route path="payments/:categoryId/:id" element={<PaymentItem config={constants.autorized.paymentsList} />} />
        <Route path="payments/:categoryId" element={<Providers config={constants.autorized.paymentsList}/>} />
        <Route
          path="*"
          element={
            <Navigate index to="/" />
          }
        />
      </Routes>
    )

    const getRoutes = () => {
      if (signIn) {
        return authorizedRoutes
      } else return notAuthorizedRoutes
    }


    return (
      <IntlProvider messages={messages[locale]} locale={locale} defaultLocale={LOCALES.RUSSIAN}>
        <div className={classes.app}>
          {/* <span>{backLink}</span> */}
          <TgNav backLink={backLink} />
          {getRoutes()}
          {<BottomMenu/>}
          {/* <button onClick={onToggleButton}>toggle</button> */}
        </div>
      </IntlProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    signIn: state.authorization.signIn,
    backLink: state.authorization.backLink,
    locale: state.authorization.locale,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: () => dispatch(signIn()),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
