import React, {Component} from 'react';
import {connect} from 'react-redux';
import classes from "./transferForm.module.scss"
import withRouter from "../../../../../hoc/withRouter";
import Header from "../../../../Header/Header";
import Wrapper from "../../../../ui/wrapper";
import Services from "../../../../../services/Services";
import {Button, Col, Divider, Form, Input, notification, notification as noti, Row, Tabs} from "antd";
import InputNumber from "../../../../ui/inputs/InputNumber";
import PaymentSelect from "../../../../ui/selects/PaymentSelect";
import PrimaryButton from "../../../../ui/buttons/PrimaryButton";
import TextInput from "../../../../ui/inputs/TextInput";
import PhoneInput from "../../../../ui/inputs/PhoneInput";
import Icon from "../../../../ui/Icon";
import ScannerQr from "../../../../ui/ScannerQR";
import {setBackLink} from "../../../../../store/actions/authorization";
import img1 from "../../../../../images/demoImage/kapital-bank-logo.svg";
import img2 from "../../../../../images/demoImage/tenge-bank-logo.svg";
import img3 from "../../../../../images/demoImage/asaka-bank-logo.svg";
import {changeInput} from "../../../../../store/actions/transfer";
import uuid from "../../../../../services/uuid";
import {FormattedMessage} from "react-intl";

class TransferForm extends Component {

  transferForm = React.createRef()
  testForm = React.createRef()

  state = {
    step:1,
    scanData: false,
    transferAmount: "",
  }

  componentDidMount() {
    const {setBackLink, location: {pathname}, params: {id}} = this.props
    setBackLink(`/${pathname.split("/")[1]}`)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {params: {id}} = this.props

    if(id === "qrTransfer" && this.transferForm.current !== null) {
      this.transferForm.current.setFieldValue("transferAmount", 10000)
    }
  }

  render() {

    const {params: {id}, navigate, setBackLink, changeInput, locale} = this.props
    const {step, scanData, transferAmount} = this.state
    const getRules = Services.formValidateRules
    const mapTransferForm = (form) => Services.mapFormItem(form, this.transferForm)

    const notificationConfig = {
      className: "successNotification",
      closeIcon: false,
      message: <span><FormattedMessage id='messageTranslationCompleted' /></span>,
      description: <>
        <FormattedMessage id='messageTransferSentSuccessfully' />
      </>,
      icon: <Icon name="icon-verify" color={"#95D319"} size={32}/>,
      placement: "top",
    }

    const onStepChange = (currentStep, nextStep, maxStep) => {
      this.transferForm.current.validateFields().then(() =>{
        this.setState({step: nextStep})
      })
      if(currentStep === maxStep) {
        this.transferForm.current.validateFields().then(() =>{
          notification.success(notificationConfig)
          navigate(`/`)
        })
      }
    }

    const getScanData = (data) => {
      this.setState({scanData: data})
    }

    const changeTransferAmount = (val) => {
      this.setState({transferAmount: val})
    }

    const transferType = () => {
      switch (id) {
        case "qrTransfer":
          return {
            form:qrTransferForm,
            name: <FormattedMessage id='titleQRTranslation' />,
            limit: "60 000 000",
          }
        case "betweenAccounts":
          return {
            form:betweenAccountsForm,
            name: <FormattedMessage id='titleBetweenYourAccounts' />,
            limit: "60 000 000",
          }
        case "phoneNumber":
          return {
            form:phoneNumberTransferForm,
            name: <FormattedMessage id='titleByPhoneNumber' />,
            limit: "60 000 000",
          }
        case "card2card":
          return {
            form:card2cardTransferForm,
            name: <FormattedMessage id='titleToAnyBankCard' />,
            limit: "60 000 000",
            commission: "300",
          }
        case "swift":
          return {
            form:card2cardTransferForm,
            name: <FormattedMessage id='titleInternationalTransfers' />,
            limit: "60 000 000",
            commission: "300",
          }
        case "conversion":
          return {
            form:conversionTransferForm,
            name: <FormattedMessage id='titleOnlineConversion' />,
            limit: "60 000 000",
            commission: "300",
            exchange: "12 590"
          }
        case "account":
          return {
            form:accountTransferForm,
            name: <FormattedMessage id='titleByDetails' />,
            limit: "60 000 000",
            commission: "300",
          }
      }
    }

    const bankItems = [
      {
        bank:{
          icon: img1,
          title: "Kapital Bank",
          description: "Дильшод Акрамович Рахимов",
          id:1,
        },
        value: '1',
      },
      {

        bank:{
          icon: img2,
          title: "Tenge Bank",
          description: "Дильшод Акрамович Рахимов",
          id:2,
        },
        value: '2',
      },
      {

        bank:{
          icon: img3,
          title: "Asaka Bank",
          description: "Дильшод Акрамович Рахимов",
          id:3,
        },
        value: '3',
      },
    ]

    const paymentItems = [
      {
        blocked: false,
        card: {
          type: 1,
          number: '• 7811',
        },
        balance: '182.87 UZS',
        label: <FormattedMessage id='labelMyVISACard' />,
        description: 'RUB, UZS',
        value: '182.87 UZS',
      },
      {
        blocked: false,
        card: {
          type: 4,
          number: '• 7811',
        },
        balance: '247 145.87 UZS',
        label: <FormattedMessage id='labelLinkedCard' />,
        description: 'USD, RUB, UZS',
        value: '190 182.22 UZS',
      },
      {
        blocked: false,
        card: {
          type: 3,
          number: '• 7846',
        },
        balance: '46 718.22 UZS',
        label: <FormattedMessage id='labelLinkedCard' />,
        description: 'USD, RUB',
        value: '194.22 UZS',
      },
      {
        blocked: false,
        card: {
          type: 2,
          number: '• 7846',
        },
        balance: '46 718.22 UZS',
        label: <FormattedMessage id='labelLinkedCard' />,
        description: 'USD, RUB',
        value: '194.22 UZS',
      },
    ]

    const qrTransferItems = [
      {
        name: "paymentFrom",
        label: <FormattedMessage id='labelFrom' />,
        rules: getRules("required"),
        children: <PaymentSelect data={paymentItems} locale="placeholderSelectFromList"/>,
        key: "qrTransferPaymentFrom",
      },
      {
        name: "transferAmount",
        label: <FormattedMessage id='labelTransferAmount' />,
        children: <InputNumber placeholder="Введите сумму" addonAfter={"сум"} locale="placeholderEnterAmount" value={10000} disabled />,
        col: 24,
        rules: getRules("required"),
        key: "transferAmount",
      },
    ]

    const betweenAccountsItems = [
      {
        name: "paymentFrom",
        label: <FormattedMessage id='labelFrom' />,
        rules: getRules("required"),
        children: <PaymentSelect data={paymentItems} locale="placeholderSelectFromList"/>,
      },
      {
        name: "paymentTo",
        label: <FormattedMessage id='labelWhere' />,
        rules: getRules("required"),
        children: <PaymentSelect data={paymentItems} locale="placeholderSelectFromList"/>,
      },
      {
        name: "transferAmount",
        label: <FormattedMessage id='Amount' />,
        children: <InputNumber placeholder="Введите сумму" addonAfter={"сум"} locale="placeholderEnterAmount" onChange={e => changeTransferAmount(e)}/>,
        col: 24,
        rules: getRules("required"),
        key: "transferAmount",
      },
    ]

    const phoneNumberTransfer = [
      {
        name: "paymentFrom",
        label: <FormattedMessage id='labelFrom' />,
        rules: getRules("required"),
        children: <PaymentSelect data={paymentItems} locale="placeholderSelectFromList"/>,
        key: "phoneNumberTransferPaymentFrom",
      },
      {
        name: "recipientName",
        label: <FormattedMessage id='labelRecipientFullName' />,
        children: <TextInput placeholder="Введите ФИО" locale="placeholderEnterFullName"/>,
        col: 24,
        rules: getRules("required"),
        key: "phoneNumberTransferRecipientName",
      },
      {
        name: "recipientPhone",
        label: <FormattedMessage id='labelWhere' />,
        children: <PhoneInput placeholder="Введите номер" search={() => {}} locale="placeholderEnterNumber" />,
        col: 24,
        rules: getRules("required"),
        key: "phoneNumberTransferRecipientPhone",
      },
      {
        name: "recipientBank",
        label: <FormattedMessage id='labelRecipientBank' />,
        rules: getRules("required"),
        children: <PaymentSelect data={bankItems} locale="placeholderSelectFromList"/>,
        key: "phoneNumberTransferRecipientBank",
      },
      {
        name: "transferAmount",
        label: <FormattedMessage id='labelTransferAmount' />,
        children: <InputNumber placeholder="Введите сумму" locale="placeholderEnterAmount" addonAfter={"сум"}/>,
        col: 24,
        rules: getRules("required"),
        key: "phoneNumberTransferTransferAmount",
      },
    ]

    const card2cardTransfer = [
      {
        name: "paymentFrom",
        label: <FormattedMessage id='labelFrom' />,
        rules: getRules("required"),
        children: <PaymentSelect data={paymentItems} locale="placeholderSelectFromList"/>
      },
      {
        name: "paymentTo",
        label: <FormattedMessage id='labelWhere' />,
        children: <InputNumber placeholder="Введите номер карты" locale="placeholderEnterCardNumber"/>,
        col: 24,
        rules: getRules("required"),
      },
      {
        name: "transferAmount",
        label: <FormattedMessage id='labelTransferAmount' />,
        children: <InputNumber placeholder="Введите сумму" addonAfter={"сум"} locale="placeholderEnterAmount" onChange={e => changeTransferAmount(e)}/>,
        col: 24,
        rules: getRules("required"),
        key: "transferAmount",
      },
    ]

    const conversionTransfer = [
      {
        name: "paymentFrom",
        label: <FormattedMessage id='labelFrom' />,
        rules: getRules("required"),
        children: <PaymentSelect data={paymentItems} locale="placeholderSelectFromList"/>
      },
      {
        name: "transferAmount",
        label: <FormattedMessage id='labelChargeAmount' />,
        children: <InputNumber placeholder="Введите сумму" addonAfter={"UZS"} locale="placeholderEnterAmount" onChange={e => changeTransferAmount(e)}/>,
        col: 24,
        rules: getRules("required"),
        key: "transferAmount",
      },
      {
        name: "depositAmount",
        label: <FormattedMessage id='labelAmountToBeCredited' />,
        children: <InputNumber placeholder="Введите сумму" locale="placeholderEnterAmount" addonAfter={"USD"}/>,
        col: 24,
        rules: getRules("required"),
      },
    ]

    const accountTransfer = [
      {
        name: "paymentFrom",
        label: <FormattedMessage id='labelFrom' />,
        rules: getRules("required"),
        children: <PaymentSelect data={paymentItems} locale="placeholderSelectFromList"/>
      },
      {
        name: "recipientNumber",
        label: <FormattedMessage id='labelRecipientAccountNumber' />,
        children: <InputNumber placeholder="00000 000 0 0000 0000000"/>,
        col: 24,
        rules: getRules("required"),
      },
      {
        name: "recipientIIN",
        label: <FormattedMessage id='labelRecipientIIN' />,
        children: <InputNumber placeholder="000 000 000"/>,
        col: 24,
        rules: getRules("required"),
      },
      {
        name: "recipient",
        label: <FormattedMessage id='labelRecipient' />,
        children: <TextInput />,
        col: 24,
        rules: getRules("required"),
      },
      {
        name: "transferAmount",
        label: <FormattedMessage id='labelTransferAmount' />,
        children: <InputNumber placeholder="Введите сумму" addonAfter={"сум"} locale="placeholderEnterAmount" onChange={e => changeTransferAmount(e)}/>,
        col: 24,
        rules: getRules("required"),
        key: "transferAmount",
      },
    ]

    const formConfirmItem = [
      {
        name: "smsCode",
        label: <FormattedMessage id='labelCodeFromSMS' />,
        children: <TextInput placeholder="Введите код" locale="placeholderEnterCode"/>,
        col: 24,
        rules: getRules("required"),
      },
    ]

    const formConfirm = () => {
      return (
        <>
          <h2 style={{marginBottom: 40}}>
            <FormattedMessage id='titleSentSMSWithCode' />
          </h2>
          {
            mapTransferForm(formConfirmItem)
          }
        </>
      )
    }

    //Forms
    const qrTransferForm = [
      {
        key: 1,
        children: mapTransferForm(qrTransferItems)
      },
      {
        key: 2,
        children: formConfirm()
      },
    ]

    const betweenAccountsForm = [
      {
        key: 1,
        children: mapTransferForm(betweenAccountsItems)
      },
      {
        key: 2,
        children: formConfirm()
      },
    ]

    const phoneNumberTransferForm = [
      {
        key: 1,
        children: mapTransferForm(phoneNumberTransfer)
      },
      {
        key: 2,
        children: formConfirm()
      },
    ]

    const card2cardTransferForm = [
      {
        key: 1,
        children: mapTransferForm(card2cardTransfer)
      },
      {
        key: 2,
        children: formConfirm()
      },
    ]

    const conversionTransferForm = [
      {
        key: 1,
        children: mapTransferForm(conversionTransfer)
      },
      {
        key: 2,
        children: formConfirm()
      },
    ]

    const accountTransferForm = [
      {
        key: 1,
        children: mapTransferForm(accountTransfer)
      },
      {
        key: 2,
        children: formConfirm()
      },
    ]

    return (
      <div className={classes.wrapper}>
        <Header
          back={"/transfers"}
          title={transferType().name}
          notification={2}
        />
        {
          id === "qrTransfer" && !scanData ?
            <ScannerQr
              callback={getScanData}
              showQR={()=>{
                setBackLink("/transfers/qrTransfer")
                navigate("/myQRCode")
              }}
            /> :
            <Wrapper>
              <Form
                ref={this.transferForm}
              >
                <Row  gutter={[16,0]}>
                  <Col span={24}>
                    <Tabs
                      activeKey={step}
                      animated
                      items={transferType().form}
                      //onChange={id => onStepChange(step, id, tabsItem.length)}
                      renderTabBar={()=>{}}
                    />
                  </Col>
                  {
                    step === 1 &&
                    <Col span={24}>
                      {
                        transferType().limit &&
                        <p className={classes.supText}>
                          <span><FormattedMessage id='textMaximumAmount' />: {transferType().limit} сум</span>
                        </p>
                      }
                      {
                        transferType().exchange &&
                        <p className={classes.supText}>
                          <span><FormattedMessage id='textTransferCourse' />: 1.00 USD = {transferType().exchange} UZS</span>
                        </p>
                      }
                      {
                        transferType().commission &&
                        <p className={classes.supText}>
                          <span><FormattedMessage id='textCommission' />: {transferType().commission} сум</span>
                        </p>
                      }
                      <Divider/>
                    </Col>
                  }
                  <Col span={24}>
                    <PrimaryButton
                      title={step === 2 ? <FormattedMessage id='buttonApply' /> : <FormattedMessage id='buttonTransfer' />`${transferAmount !== "" ? " " + transferAmount + " сум" : ""}`}
                      onClick={() => onStepChange(step, step + 1, 2)}
                    />
                  </Col>
                  {
                    step === 2 &&
                    <Col span={24}>
                      <PrimaryButton
                        bordered={true}
                        title={<FormattedMessage id='buttonSendNewSMS' />}
                        onClick={()=> {}}
                        timer={step === 2 ? 10 : false}
                      />
                    </Col>
                  }
                </Row>
              </Form>
            </Wrapper>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    transferAmount: state.transfer.form?.transferAmount,
    locale: state.authorization.locale,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setBackLink: (backLink) => dispatch(setBackLink({backLink})),
    changeInput: (name, value, filter) => dispatch(changeInput({name, value, filter}))
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(TransferForm));