import React from 'react';
import {Form, Input, Select} from "antd";
import classes from './phoneInput.module.scss'
import {MaskedInput} from "antd-mask-input";
import TextInput from "../TextInput";
import mapIconItem from "../../../../services/mapIconItem";
import Icon from "../../Icon";
import {useIntl} from "react-intl";

const {Option} = Select;

const prefixSelector = (
  <Form.Item name="prefix" noStyle initialValue={"+998"}>
    <Select
      style={{
        width: 70,
      }}
      suffixIcon={false}
    >
      <Option value="998">+998</Option>
    </Select>
  </Form.Item>
)

const PhoneInput = (props) => {
  const intl = useIntl()
  return (
    <TextInput
      {...props}
      addonBefore={prefixSelector}
      style={{
        width: '100%',
      }}
      type="text"
      inputMode="tel"
      size={"large"}
      className={classes.input}
      addonAfter={props?.search ? <span onClick={props?.search}><Icon size={24} name="icon-user-search"/></span> : false}
      placeholder={props.locale ? intl.formatMessage({id: props.locale}): props.placeholder ? props.placeholder : ""}
    />

    /*<MaskedInput
      {...props}
      addonBefore={prefixSelector}
      style={{
        width: '100%',
      }}
      className={classes.input}
      size={"large"}
      mask={'(000) 000-00-00'}
      inputMode="tel"
    />*/
  );
};

export default PhoneInput;