import React, {Component} from 'react';
import {connect} from 'react-redux';
import classes from "./payments.module.scss"
import Header from "../../../../Header";
import BottomMenu from "../../../../ui/menu/BottomMenu";
import TextInput from "../../../../ui/inputs/TextInput";
import Icon from "../../../../ui/Icon";
import Wrapper from "../../../../ui/wrapper";
import Search from "../../../../ui/inputs/Search";
import {Col, Row} from "antd";
import ServiceButton from "../../../../ui/buttons/ServiceButton";
import ProviderList from "../../../../ui/infoBlocks/ProviderList";
import img1 from "../../../../../images/demoImage/logo1.png"
import img2 from "../../../../../images/demoImage/logo2.png"
import img3 from "../../../../../images/demoImage/logo3.png"
import img4 from "../../../../../images/demoImage/logo4.png"
import {setBackLink} from "../../../../../store/actions/authorization";
import {FormattedMessage} from "react-intl";



class Payments extends Component {

  componentDidMount() {
    const {setBackLink} =this.props
    setBackLink(`/`)
  }

  render() {
    const {signIn, config} = this.props
    /*const service = [
      {
        title: "Интернет",
        icon: "icon-wifi",
        link:  signIn ? "internet" : "/signIn"
      },
      {
        title: "Моб. связь",
        icon: "icon-call",
        link: signIn ? "cellular" : "/signIn"
      },
      {
        title: "ТВ",
        icon: "icon-video-horizontal",
        link: signIn ? "tv" : "/signIn"
      },
      {
        title: "Комм.услуги",
        icon: "icon-home-hashtag",
        link: signIn ? "publicFacilities" : "/signIn"
      },
      {
        title: "Бюджетные платежи",
        icon: "icon-bank",
        link: signIn ? "budgetPayments" : "/signIn"
      },
      {
        title: "Все платежи",
        icon: "icon-archive",
        link: signIn ? "allPayments" : "/signIn"
      },
    ]*/

    const favoriteData = [
      {
        icon: img1,
        title: "RentaNet",
        description: "Моб. связь",
        link: signIn ? "internet/RentaNet" : "/signIn"
      },
    ]

    const providersData = [
      {
        icon: img1,
        title: "RentaNet",
        description: "оплата за интернет",
        link: signIn ? "internet/RentaNet" : "/signIn"
      },
      {
        icon: img2,
        title: "RentaNet",
        description: "оплата за интернет",
        link: signIn ? "internet/RentaNet" : "/signIn"
      },
      {
        icon: img3,
        title: "RentaNet",
        description: "оплата за интернет",
        link: signIn ? "internet/RentaNet" : "/signIn"
      },
      {
        icon: img4,
        title: "RentaNet",
        description: "оплата за интернет",
        link: signIn ? "internet/RentaNet" : "/signIn"
      },
    ]

    return (
      <div className={classes.wrapper}>
        <Header
          back={"/"}
          title={config.title}
          notification={signIn ? 2 : false}
          //profile={"Айнура Кайдарова"}
          empty={!signIn}
        />
        <Wrapper nopadding transparent style={{marginTop:24, marginBottom:24}}>
          <Search placeholder="Поиск платежа" locale="placeholderSearchPayment"/>
        </Wrapper>
        <Wrapper>
          <Row>
            {
              config?.service.map((el, key) => {
                return (
                  <Col className="gutter-row" key={key} span={8}>
                    <ServiceButton title={el.title} icon={el.icon} link={signIn ? el.link : "/signIn" } vertical bordered small />
                  </Col>
                )
              })
            }
          </Row>
        </Wrapper>
        <Wrapper nomargin transparent style={{paddingTop: 12, paddingBottom: 12}}>
          <h4><FormattedMessage id='titleFavorites' /></h4>
          <Wrapper nomargin  style={{paddingTop: 8, paddingBottom: 8}}>
            <ProviderList data={config.favoriteData.map((el, ind) => {return{...el, link: signIn ? el.link : "/signIn"}})}/>
          </Wrapper>
        </Wrapper>
        <Wrapper nomargin transparent style={{paddingTop: 12, paddingBottom: 12}}>
          <h4><FormattedMessage id='titleAllSuppliers' /></h4>
          <Wrapper nomargin  style={{paddingTop: 8, paddingBottom: 8}}>
            <ProviderList data={config.providersData.map((el, ind) => {return{...el, link: signIn ? el.link : "/signIn"}})}/>
          </Wrapper>
        </Wrapper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    signIn: state.authorization.signIn,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setBackLink: (backLink) => dispatch(setBackLink({backLink})),
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(Payments);