import React from 'react';
import classes from "./contentModal.module.scss"
import {Modal} from "antd";

const ContentModal = (props) => {
  const {open, showModal, data, name} = props
  return (
    <Modal
      open={open}
      footer={null}
      centered
      closable={false}
      onCancel={() => showModal(name)}
      className={classes.modal}
    >
      {data}
    </Modal>
  );
};

export default ContentModal;