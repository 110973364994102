import React, {Component} from 'react';
import {connect} from 'react-redux';
import classes from './head.module.scss';
import avatar from '../../images/icons/profile-circle.svg';
import LangSelect from "../ui/selects/LangSelect";
import {Link} from "react-router-dom";
import {Badge} from "antd";
import mapIconItem from "../../services/mapIconItem";
import shortName from "../../services/shortName";
import Services from "../../services/Services";
import Icon from "../ui/Icon";
import mapClassName from "../../services/mapClassName";
import sources from "../../source/sources";
import withRouter from "../../hoc/withRouter";
import {setAutorised, setBackLink, setLanguage, signIn} from "../../store/actions/authorization";
import LanguageModal from "../ui/modal/LanguageModal";
import {FormattedMessage} from "react-intl";

class Header extends Component {

  state = {
    showModal: false
  }

  render() {
    const {
      languageSelect,
      setBackLink,
      setAutorised,
      notification,
      loginLink,
      profile,
      back,
      sendQRCode,
      transparent,
      danger,
      title,
      showInfo,
      empty,
      navigate,
      location,
      setLanguage,
      locale,
    } = this.props

    const onSelectLanguage = () => {
      const prevProps = this.state.showModal
      this.setState({showModal: !prevProps})
    }

    const isAutorised = () => {
      sources.isAutorised()
        .then(({data}) => {
          console.log("DATA", data)
          this.props.setAutorised(data)
          if(data.isAutorised){
            navigate("/securityCode")
          } else navigate("/signIn")

        }).catch(error => {
        Services.redirectService(error)
      })
      /*sources.isAutorised()
        .then(({data}) => {
          console.log("isAutorised Responce", data)
          notification
        })
        .catch(error => {
          Services.redirectService(error)
        })*/
    }

    const classNames = () => {
      const style = ["transparent"]
      const property = Object.keys(this.props)
      return mapClassName(property.filter(i => style.includes(i)), classes)
    }

    return (
      <>
        <header className={classes.head + ` ${classNames()}  ${danger ? classes.danger : ' '}` }>
          {/*{
          back &&
          <Link to={back} className={classes.loginBtn}>
            <div>
              {mapIconItem("arrowLeft")}
            </div>
          </Link>
        }*/}
          {
            back &&
            <div className={classes.empty}/>
          }
          {
            title &&
            <span className={classes.title}>{title}</span>
          }
          {
            loginLink &&
            <div className={classes.loginBtn} onClick={() => isAutorised()}>
              <div className={classes.avatar}>
                <img alt="" src={avatar}/>
              </div>
              <div><FormattedMessage id='headerLogin' /><br/><FormattedMessage id='headerRegistration' /></div>
            </div>
          }
          {
            profile &&
            <div
              className={classes.loginBtn}
              onClick={()=>{
                navigate("/profile")
                setBackLink(location)
              }}
            >
              <div className={classes.avatar}>
                {
                  Services.shortName(profile, "initials")
                }
              </div>
              <div>
                {
                  Services.shortName(profile, "lastName")
                }<br/><span className={classes.settings}><FormattedMessage id='headerSettings' /></span>
              </div>
            </div>
          }
          {
            notification &&
            <a
              className={classes.notification}
              onClick={() => {
                navigate("/notification")
                setBackLink(location)
              }}
            >
              <Badge count={notification}>
                <Icon name="icon-notification"/>
              </Badge>
            </a>
          }
          {
            languageSelect &&
            <LangSelect
              lang={locale}
              onSelect={onSelectLanguage}
            />
          }
          {
            sendQRCode &&
            <span className={classes.infoIcon} onClick={() => sendQRCode(true)}><Icon name="icon-export4"/></span>
          }
          {
            showInfo &&
            <span className={classes.infoIcon} onClick={() => showInfo(true)}><Icon name="icon-info-circle"/></span>
          }
          {
            empty && <span style={{width: typeof empty === "boolean" ? 24 : empty}}/>
          }
        </header>
        <LanguageModal
          open={this.state.showModal}
          showModal={onSelectLanguage}
          languageSelect={setLanguage}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    userSignIn: state.authorization.signIn,
    isAutorised: state.authorization.isAutorised,
    locale: state.authorization.locale,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signIn: (loading, isLogin) => dispatch(signIn({loading, isLogin})),
    setAutorised: (loading, isAutorised) => dispatch(setAutorised({loading, isAutorised})),
    setBackLink: (backLink) => dispatch(setBackLink({backLink})),
    setLanguage: (locale) => dispatch(setLanguage({locale}))
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));