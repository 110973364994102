import classes from "./serviceList.module.scss";
import {Col, Row} from "antd";
import Wrapper from "../../wrapper";
import ServiceButton from "../../buttons/ServiceButton";
import React from 'react';

const ServiceList = (props) => {
  const {data} = props
  return (
    <div className={classes.wrapper}>
      <Row gutter={[16,16]}>
        {
          data.map((el, key) => {
            return (
              <Col key={key} span={12}>
                <Wrapper {...props}>
                  <ServiceButton
                    green={props.green}
                    icon={el.icon}
                    title={el.title}
                    bordered={props.bordered}
                    description={el.description}
                    link={el.link}
                    size={props.size}
                    vertical
                    left={props.left}

                  />
                </Wrapper>
              </Col>
            )
          })
        }
      </Row>
    </div>
  );
};

export default ServiceList;