import React, {Component} from 'react';
import {connect} from 'react-redux';
import withRouter from "../../../hoc/withRouter";
import Services from "../../../services/Services";
import TextInput from "../../ui/inputs/TextInput";
import PhoneInput from "../../ui/inputs/PhoneInput";
import Checkbox from "../../ui/selects/Checkbox";
import Select from "../../ui/selects/Select";
import Header from "../../Header";
import classes from "./identification.module.scss";
import {Card, Col, Form, Row, Tabs} from "antd";
import PrimaryButton from "../../ui/buttons/PrimaryButton";
import Loader from "../../Loader/Loader";
import sources from "../../../source/sources";
import PasswordInput from "../../ui/inputs/PasswordInput";
import {FormattedMessage} from "react-intl";
import Wrapper from "../../ui/wrapper";
import Icon from "../../ui/Icon";



class Identification extends Component {
  identificationForm = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      loading: false,
      accessToken: {},
    }
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  config = {
    autoDocType: false,
    docType: 1,
    recognitionMode: 0,
    translitCheck: false,
    glareCheck: false,
    photocopyCheck: false,
    lang: 'ru',
    hints: {},
    render: {
      placeholder: true,
      startButton: true,
      containerBorderThickness: 1,
      containerBorderRadius: 3,
      containerBorderColor: "#000000",
      frame: true,
      frameBorderThickness: 3,
      frameBorderRadius: 20,
      frameColor: {
        default: "rgba(255, 255, 255, 1.0)",
        detected: "rgba(30, 255, 88, 1.0)"
      },
      overlay: true,
      overlayPermanent: true,
      overlayColor: {
        default: "#ffffff"
      },
      upperBarColor: {
        default: "rgba(255, 255, 255, 1.0)"
      },
      lowerBarColor: {
        default: "#a2d2ff",
        error: "#ffccd5"
      },
      buttonColor: {
        default: "#a2d2ff"
      },
      buttonTextColor: {
        default: "#353535"
      },
      overlayTransparency: {
        default: 0.7
      },
      icons: true,
      hint: true,
      hintTextColor: {
        default: "#353535"
      },
      hintFontType: "Arial",
      mirrorPreview: false
    }
  }

  configLive = {
    'recordVideo': false,
    'videoBitrate': 2500000,
    'rotated': false,
    'lang': "custom",

    'render': {
      'oval': true,
      'faceContourInsteadOfOval': true,
      'ovalRingColor': {
        'default': '#f5f542',
        'actionSuccess': '#00ba00',
        'actionFailure': '#d00000',
        'sessionSuccess': '#00ba00',
        'sessionFailure': '#d00000',
      },
      'ovalWidth': 1.0,

      'overlay': true,
      'overlayColor': {
        'default' : '#2f4f4f',
      },
      'overlayTransparency': {
        'default': 0.55,
      },

      'arrow': true,
      'arrowColor': {
        'default': '#f0f0f0',
      },
      'arrowProgressColor': {
        'default': '#404040',
      },

      'hint': true,
      'hintTextColor': {
        "default": "#eee",
      },
      'hintFontType': "Arial",
      'hintUseProgressiveFontSize': true,
      'hintProgressiveFontSizeMultiplier': 1.0,
      'hintFontSize': 25,
      "hintCloudColor": {
        "default": "#2d312f",
      },

      'videoUploadProgressBar': true,
      'videoUploadProgressBarColor1': "#FFEA82",
      'videoUploadProgressBarColor2': "#eee",
    },

    'hints': {
      'noHint': '',
      'noFace': 'Вас Не Видно',
      'badLight': "Выравните Освещение",
      'closer': 'Ближе',
      'away': 'Отдалитесь',
      'closerToCenter': 'Ближе к Центру Экрана',
      'сameraNotReadableError' : "Камера не найдена",
      'CameraNotReadableError' : "Камера не найдена",

      'targetLeft': 'Медленно Поворачивайте Голову Влево',
      'targetRight': 'Медленно Поворачивайте Голову Вправо',
      'targetCenter': 'Посмотрите Прямо',

      'sessionSuccess': 'Вы Прошли!',
      'sessionFailure': 'Вы Не Прошли!',
      'sessionError': 'Произошла какая-то ошибка. Попробуйте перезапустить',
    },
  };

  successCallback = (data) =>{
    console.log('Session token is: ' + veridoc.getSessionToken());
    console.log('success', data);

    this.setState({
      step: 3,
      veridocData: data
    },function (){
      notification.success({message: "Отлично", description: "Удостоверение личности верифицировано" , duration: 3 })
    })
    //alert('success', data);
    //showResults(data);
    //checkRecognitionWarnings();
  }

  failCallback(data) {
    console.log('fail', data);
    //alert('fail', data);
    //showResults(data);
  }

  errorCallback(data) {
    console.log('error', data);
    alert('error', data);
    //showResults(data);
  }

  updateCallback(data) {
    console.log('update', data);
    //alert('update', data);
    //showResults(data);
  }


  render() {
    const getRules = Services.formValidateRules
    const {navigate} = this.props

    const onStepChange = (currentStep, nextStep, maxStep) => {
      this.identificationForm.current.validateFields().then(() =>{
        if(currentStep === maxStep){
          this.props.signIn()
          navigate("/")
        } else {
          this.setState({step: nextStep})
        }
      })
    }
    const buttonText = () => {
      if (step === 1) {
        return <FormattedMessage id="buttonApply" />
      } else {
        return <FormattedMessage id="buttonContinue" />
      }
    }

    const {changeInput} = this.props

    const formVeriDoc =  [
      {
        name: "VeriDoc",
        //label: "Биометрия документа Test",
        //rules: getRules("phone", 10),
        children: <div id="id_veridoc"/>
      }
    ]

    const formSmsCode = [
      {
        name: "smsCode",
        label: "Код из SMS",
        rules: getRules("required"),
        children: <TextInput placeholder="Введите код"/>
      }
    ]

    const formCitizen = [
      {
        name: "citizen",
        label: "Гражданство",
        rules: getRules("required"),
        children: <Select data={[]} placeholder="Выберите из списка"/>
      }
    ]

    const formSecurityCode = [
      {
        name: "authCode",
        rules: getRules("passwordConfirm", 8, "authCodeRepeat", this.identificationForm.current?.getFieldValue("authCodeRepeat")),
        children: <PasswordInput placeholder="Введите код" locale="placeholderEnterCode"/>
      },
      {
        name: "authCodeRepeat",
        rules: getRules("passwordConfirm", 8, "authCode", this.identificationForm.current?.getFieldValue("authCode") ),
        children: <PasswordInput placeholder="Подтвердите код" locale="placeholderConfirmCode"/>
      },
    ]

    const identStartForm = (form) => {
      return (
        <>
          <h2>
            Идентификация
          </h2>
          <p className={classes.subTitle}>
            Для продолжения, нам потребуется идентифицировать Вашу личность.
          </p>
          <p className={classes.small}>
            Требования к прохождению идентификации иностранными гражданами:
          </p>
          <ol style={{marginBottom: 16}}>
            <li>
              Первичная идентификация осуществляется только для лиц находящиеся и имеющие действующую регистрацию на территории Республики Узбекистан.
            </li>
            <li>
              Предварительная проверка проходит только по оригиналу действующего загранпаспорта.
              При предоставлении для проверки внутреннего паспорта гражданина, копий, сканированных изображений,
              скриншотов и иных вариаций, кроме как оригинала загранпаспорта, ваша заявка будет отклонена.
            </li>
            <li>
              Для завершения процедуры идентификации иностранному гражданину,
              если он является клиентом банка, необходимо лично предоставить сотруднику Банка оригинал загранпаспорта и документ
              удостоверяющий действующую регистрацию на территории Республики Узбекистан.
            </li>
          </ol>
        </>
      )
    }

    const veriDocForm = () => {
      return (
        <>
          <h2 style={{marginBottom: 40}}>
            Идентификация
          </h2>
          <p className={classes.subTitle}>
            Проверьте снимок документа:
          </p>
          {Services.mapFormItem(formVeriDoc, this.identificationForm)}
        </>
      )
    }

    const smsCodeForm = () => {
      return (
        <>
          <h2 style={{marginBottom: 40}}>
            Мы отправили Вам&nbsp;СМС
            с&nbsp;кодом. Введите&nbsp;его<br/>
            в&nbsp;поле ниже.
          </h2>
          {Services.mapFormItem(formSmsCode, this.identificationForm)}
        </>
      )
    }

    const citizenForm = () => {
      return (
        <>
          <h2 style={{marginBottom: 40}}>
            Выберите<br/>
            гражданство
          </h2>
          {Services.mapFormItem(formCitizen, this.identificationForm)}
        </>
      )
    }

    const securityCodeForm = () => {
      return (
        <Row gutter={[16,40]}>
          <Col span={24}>
            <h2>
              <FormattedMessage id="titleComeUpAccessCode" />
            </h2>
          </Col>
          <Col span={24}>
            <Row gutter={[16,1]}>
              <Col span={24}>
                {Services.mapFormItem(formSecurityCode, this.identificationForm)}
              </Col>
              <Col span={24}>
                <p><FormattedMessage id="textPasswordMustHave" values={{val:8}} /></p>
              </Col>
            </Row>
          </Col>
        </Row>
      )
    }

    const successForm = () => {
      return (
        <Row gutter={[16,24]}>
          <Col span={24}  className={classes.subTitle}>
            <Icon name="icon-shield-tick" color={"#95D319"} size={40} />
          </Col>
          <Col span={24}>
            <Row gutter={[16,16]}>
              <Col span={24}>
                <h2><FormattedMessage id="titleAccessCodeSaveSuccessfully" /></h2>
              </Col>
              <Col span={24}>
                <div className={classes.subTitle}>
                  <FormattedMessage id="textIdentificationHasBeenCompleted" />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )
    }

    const tabsItem = [
      /*{
        key: 1,
        label: `Первый шаг`,
        children: identStartForm()
      },
      {
        key: 2,
        label: `Верификация документа`,
        children: veriDocForm()
      },
      {
        key: 3,
        label: `Биометрия лица`,
        children: citizenForm()
      },*/
      {
        key: 2,
        label: `Код безопасности`,
        children: securityCodeForm()
      },
      {
        key: 1,
        label: `Подтверждение сохранения кода безопасности`,
        children: successForm()
      },
    ]

    const {step} = this.state;

    return (
      <div className={classes.wrapper}>
        <Header
          back={"/"}
          title={<FormattedMessage id="titleIdentification" />}
          empty={24}
        />
        <Wrapper
          //style={{padding: "24px 16px 32px 16px",}}
        >
          <Row gutter={[16,32]}>
            <Col span={24}>
              <Form
                ref={this.identificationForm}
                size="large"
                layout="vertical"
                className={classes.form}
              >
                <Tabs
                  activeKey={step}
                  animated
                  className={classes.tabs}
                  items={tabsItem}
                  onChange={id => onStepChange(step, id, tabsItem.length)}
                  renderTabBar={()=>{}}
                />
              </Form>
            </Col>
            <Col span={24}>
              <Row gutter={[16,16]}>
                {
                  <Col span={24}>
                    <PrimaryButton
                      size="small"
                      onClick={() => onStepChange(step, step + 1, tabsItem.length)}
                      title={ buttonText()}
                    />
                  </Col>
                }
                {/*{
              (step === 1 || step === 2) &&
              <Col span={24}>
                <PrimaryButton
                  bordered={true}
                  size="small"
                  title={"Назад"}
                  //timer={step === 2 ? 10 : false}
                />
              </Col>
            }*/}
              </Row>
            </Col>
          </Row>
        </Wrapper>
        {
          this.state.loading && <Loader/>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Identification));