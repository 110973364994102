import React, {Component} from 'react'
import {connect} from 'react-redux'
import {AutoComplete, Card, Form, Select, Input, Button, Space, Col, Row, Tabs} from 'antd'
import classes from "./signin.module.scss"

import PasswordInput from "../../ui/inputs/PasswordInput"
import PrimaryButton from "../../ui/buttons/PrimaryButton"
import {Link} from "react-router-dom"
import {signIn, changeInput, setBackLink} from "../../../store/actions/authorization"
import PhoneInput from "../../ui/inputs/PhoneInput"
import Loader from "../../Loader/Loader"
import withRouter from "../../../hoc/withRouter"
import Services from "../../../services/Services"
import MaskInput from "../../ui/inputs/MaskInput"
import TextInput from "../../ui/inputs/TextInput"
import Header from "../../Header";
import InputNumber from "../../ui/inputs/InputNumber";
import Wrapper from "../../ui/wrapper";
import {FormattedMessage} from "react-intl";



class SignIn extends Component {

  state = {
    loading: false,
    step: 1,
  }

  signInForm = React.createRef()
  componentDidMount() {
    this.props.setBackLink("/")
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //this.signInForm.current.setFieldsValue({...this.props.form})
  }

  render() {
    const getRules = Services.formValidateRules
    const {step} = this.state
    const {changeInput, navigate} = this.props

    const buttonText = () => {
      if (step === 1) {
        return <FormattedMessage id="buttonNotRegistered" />
      } else {
        return <FormattedMessage id="buttonSendNewSMS" />
      }
    }

    const onStepChange = (currentStep, nextStep, maxStep) => {

      this.signInForm.current.validateFields().then(() =>{
        this.setState({step: nextStep})
        if(currentStep === maxStep) {
          this.props.signIn()
          navigate("/")
        }
      }).catch(() => {})
    }

    const formSignIn = [
      {
        name: "iin",
        label: <FormattedMessage id="labelYourPINFL" />,
        rules: getRules("length", 14),
        children: <InputNumber noDigit maxLength={14} placeholder="Введите ПИНФЛ" locale="placeholderEnterPINFL"/>
      },
    ]

    const formSmsCode = [
      {
        name: "smsCode",
        label: <FormattedMessage id="labelCodeFromSMS" />,
        rules: getRules("length", 6),
        children: <InputNumber noDigit maxLength={6} placeholder="Введите код" locale="placeholderEnterCode"/>
      }
    ]

    const formAuthCode = [
      {
        name: "authCode",
        rules: getRules("passwordConfirm", 8, "authCodeRepeat", this.signInForm.current?.getFieldValue("authCodeRepeat")),
        children: <PasswordInput placeholder="Введите код" locale="placeholderEnterCode"/>
      },
      {
        name: "authCodeRepeat",
        rules: getRules("passwordConfirm", 8, "authCode", this.signInForm.current?.getFieldValue("authCode") ),
        children: <PasswordInput placeholder="Подтвердите код" locale="placeholderConfirmCode"/>
      },
    ]

    const formSecurityCode = [
      {
        name: "securityCode",
        label: <FormattedMessage id="labelSecurityCode"/>,
        rules: getRules("password", 8),
        children: <PasswordInput placeholder="Введите код доступа" locale="placeholderEnterSecurityCode"/>
      },
    ]

    const signInForm = () => {
      return (
        <>
          <h2 style={{marginBottom: 40}}>
            <FormattedMessage id="titleAuthorization"/>
          </h2>
          {Services.mapFormItem(formSignIn, this.signInForm)}
        </>
      )
    }

    const smsCodeForm = () => {
      return (
        <>
          <h2 style={{marginBottom: 40}}>
            <FormattedMessage id="titleSentSMSWithCode"/>
          </h2>
          {Services.mapFormItem(formSmsCode, this.signInForm)}
        </>
      )
    }

    const authCodeForm = () => {
      return (
        <>
          <h2 style={{marginBottom: 40}}>
            <FormattedMessage id="titleComeUpAccessCode"/>
          </h2>
          {Services.mapFormItem(formAuthCode, this.signInForm)}
          <p>Пароль должен содержать не менее 8 цифр</p>
        </>
      )
    }

    const securityCodeForm = () => {
      return (
        <>
          <h2 style={{marginBottom: 40}}>
            <FormattedMessage id="titleWelcome" />,<br/>
            Айнура
          </h2>
          {Services.mapFormItem(formSecurityCode, this.signInForm)}
        </>
      )
    }

    const tabsItem = [
      {
        key: 1,
        label: `ПИНФЛ`,
        children: signInForm()
      },
      {
        key: 2,
        label: `Ввод смс кода`,
        children: smsCodeForm()
      },
      {
        key: 3,
        label: `Код доступа`,
        children: authCodeForm()
      },
      {
        key: 4,
        label: `Код доступа`,
        children: securityCodeForm()
      },
    ]

    return (
      <div className={`${classes.wrapper} ${this.state.loading ? classes.loading : ""}`}>
        <Header
          back="/"
          title={<FormattedMessage id="titlePersonalAccount" />}
          empty={24}
        />
        <Wrapper>
          {
            <>
              <Form
                ref={this.signInForm}
                size="large"
                layout="vertical"
                className={classes.form}
              >
                <Tabs
                  activeKey={step}
                  animated
                  className={classes.tabs}
                  items={tabsItem}
                  onChange={id => onStepChange(step, id, tabsItem.length)}
                  renderTabBar={()=>{}}
                />
              </Form>
              <Row gutter={[16,16]} style={{marginTop:24}}>
                {
                  <Col span={24}>
                    <PrimaryButton
                      size="small"
                      onClick={() => onStepChange(step, step + 1, tabsItem.length)}
                      title={step === tabsItem.length ?
                        <FormattedMessage id="buttonApply" /> :
                        step === 1 ?
                          <FormattedMessage id="buttonContinue" /> :
                          <FormattedMessage id="buttonNextStep" />}
                    />
                  </Col>
                }
                {
                  (step === 2) &&
                  <Col span={24}>
                    <PrimaryButton
                      bordered="true"
                      size="small"
                      title={buttonText()}
                      onClick={()=> navigate("/registration")}
                      timer={step === 2 ? 10 : false}
                    />
                  </Col>
                }
                {
                  step === tabsItem.length &&
                    <Col span={24}>
                      <a className={classes.link}><FormattedMessage id="buttonForgotAccessCode"/></a>
                    </Col>
                }
              </Row>
            </>
          }
        </Wrapper>
        {this.state.loading && <Loader/>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    form: state.authorization.form,
    validate : state.authorization.validate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeInput: (name, value, filter) => dispatch(changeInput({name, value, filter})),
    signIn: () => dispatch(signIn()),
    setBackLink: (backLink) => dispatch(setBackLink({backLink})),

  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn));