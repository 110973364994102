const shortName = (name, type) => {
  switch (type) {
    case "initials":
      return name.split(' ').map(el => el.charAt(0)).join('')
    case "lastName":
      return name.split(' ').map((el, ind) => ind === 1 ? el.charAt(0) + "." : el).join(' ')
    case "firstName":
      return name.split(' ').map((el, ind) => ind === 0 ? el.charAt(0) + "." : el).join(' ')
    case "phone":
      return name.split("").map((el, ind) => 8 > ind && ind > 2 ? "*" : el)
    case "pinfl":
      return name.split("").map((el, ind) => 10 > ind  ? "*" : el)

    default:
      return name
  }
}

export default shortName