import React, {Component} from 'react';
import {connect} from 'react-redux';
import Header from "../../../../Header";
import BottomMenu from "../../../../ui/menu/BottomMenu";
import classes from "./paymentItem.module.scss"
import Wrapper from "../../../../ui/wrapper";
import {Divider, Form} from "antd";
import Services from "../../../../../services/Services";
import PhoneInput from "../../../../ui/inputs/PhoneInput";
import InputNumber from "../../../../ui/inputs/InputNumber";
import PaymentSelect from "../../../../ui/selects/PaymentSelect";
import {setBackLink} from "../../../../../store/actions/authorization";
import withRouter from "../../../../../hoc/withRouter";
import PrimaryButton from "../../../../ui/buttons/PrimaryButton";
import {FormattedMessage} from "react-intl";


class PaymentItem extends Component {

  paymentForm = React.createRef();

  state = {
    providerName: "RentaNet",
    commission: 300,
    paidValue: null,
  }

  componentDidMount() {
    const {setBackLink, location: {pathname}, params:{categoryId}} =this.props
    setBackLink(`/${pathname.split("/")[1]}`)
  }

  render() {
    const {signIn, config} = this.props
    const {paidValue} = this.state
    const getRules = Services.formValidateRules
    const changePaidVal = (paidValue) => {
      this.setState({paidValue})
    }
    const paymentItems = [
      {
        blocked: false,
        card: {
          type: 1,
          number: '• 7811',
        },
        balance: '182.87 UZS',
        label: 'Моя карта VISA',
        description: 'RUB, UZS',
        value: '182.87 UZS',
      },
      {
        blocked: false,
        card: {
          type: 2,
          number: '• 7811',
        },
        balance: '247 145.87 UZS',
        label: 'Связанная карта',
        description: 'USD, RUB, UZS',
        value: '190 182.22 UZS',
      },
      {
        blocked: false,
        card: {
          type: 3,
          number: '• 7846',
        },
        balance: '46 718.22 UZS',
        label: 'Связанная карта',
        description: 'USD, RUB',
        value: '194.22 UZS',
      },
    ]

    const fomItems = [
      {
        key: "paymentFrom",
        name: "paymentFrom",
        label: <FormattedMessage id='labelFrom' />,
        rules: getRules("required"),
        children: <PaymentSelect data={config.paymentItems} locale="placeholderSelectFromList"/>
      },
      {
        key: "accountNumber",
        name: "accountNumber",
        label: <FormattedMessage id='labelNumberAccountOrPhone' />,
        rules: getRules("required"),
        children: <InputNumber placeholder="Введите номер счета/телефона" locale="placeholderEnterNumberAccountOrPhone"/>
      },

      {
        key: "paidToAmount",
        name: "paidToAmount",
        label: <FormattedMessage id='labelAmountToBePaid' />,
        rules: getRules("required"),
        children: <InputNumber placeholder="Введите сумму" onChange={e => changePaidVal(e)} locale="placeholderEnterAmount" addonAfter={"сум"}/>
      },
    ]

    return (
      <div className={classes.wrapper}>
        <Header
          back="/"
          title={this.state.providerName}
          showInfo={()=> {}}
        />
        <Wrapper>
          <Form
            ref={this.paymentForm}
            size="large"
            layout="vertical"
            className={classes.form}
          >
            {Services.mapFormItem(fomItems, this.paymentForm)}
          </Form>
            {
              this.state.commission &&
                <p><FormattedMessage id='textCommission' />: 300 сум</p>
            }
            {
              this.state.commission &&
              <p>
                <FormattedMessage id='textStrictlyEqualPaymentAmount' />
               </p>
            }
            <Divider/>
            <PrimaryButton
              title={
                <>
                  <FormattedMessage id='buttonPay' />
                  {
                    `${paidValue !== null && paidValue !== "" ? " " + paidValue + " сум" : ""}`
                  }
                </>
              }
            />
        </Wrapper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    signIn: state.authorization.signIn,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setBackLink: (backLink) => dispatch(setBackLink({backLink})),
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(PaymentItem));