import React, {Component} from 'react';
import {connect} from 'react-redux';
import classes from "./history.module.scss"
import withRouter from "../../../../hoc/withRouter";
import Header from "../../../Header";
import {Col, Form, List, Row} from "antd";
import DateRangeSelect from "../../../ui/selects/DateRangeSelect";
import Wrapper from "../../../ui/wrapper";
import CardListItem from "../../../ui/infoBlocks/CardListItem";
import uuid from "../../../../services/uuid";
import ListMenuModal from "../../../ui/modal/ListMenuModal/ListMenuModal";
import ContentModal from "../../../ui/modal/ContentModal/ContentModal";
import Icon from "../../../ui/Icon";
import mapIconItem from "../../../../services/mapIconItem";
import dayjs from "dayjs";
import {setBackLink} from "../../../../store/actions/authorization";
import {FormattedMessage} from "react-intl";



class History extends Component {

  state = {
    termFormIsOpen : false
  }

  componentDidMount() {
    const {setBackLink, location: {pathname}, params:{id}} =this.props
    setBackLink(`/${pathname.split("/")[1]}/${pathname.split("/")[2]}/${id}`)
  }

  render() {
    const {termFormIsOpen} = this.state
    const {params: {id}, config: {title, back, historyList}} = this.props

    const data = [
      {
        date: "7 января",
        operations: [
          {
            icon: "icon-bag",
            label: "Dana Shop",
            description: "Бакалейные магазины, супермаркеты",
            value: "-20 295.9 UZS",
            status: 0,
            score: "+0б.",
          },
          {
            icon: "icon-wallet-money",
            label: "Анастасия Т.",
            description: "Пополнения",
            value: "+200 000.0 UZS",
            status: 1,
            score: "+100б.",
          },
          {
            icon: "icon-percentage-square",
            label: "Выплата кредита",
            description: "Займы, Кредиты, Ипотеки",
            value: "-20 295.9 UZS",
            status: 2,
            score: "+0б.",
          },
          {
            icon: "icon-shop",
            label: "Wolt",
            description: "Кафе и рестораны",
            value: "-20 295.9 UZS",
            status: 0,
            score: "+0б.",
          },
        ],
      },
      {
        date: "6 января",
        operations: [
          {
            icon: "icon-bag",
            label: "Dana Shop",
            description: "Бакалейные магазины, супермаркеты",
            value: "-20 295.9 UZS",
            status: 0,
            score: "+0б.",
          },
          {
            icon: "icon-bus",
            label: "Яндекс такси",
            description: "Такси, Общественный транспорт",
            value: "-20 295.9 UZS",
            status: 0,
            score: "0б.",
          },
          {
            icon: "icon-gas-station",
            label: "Helios",
            description: "Заправочные станции",
            value: "-20 295.9 UZS",
            status: 0,
            score: "+0б.",
          },
        ],
      },
    ]

    const openTermForm = (termFormIsOpen) => {
      this.setState({termFormIsOpen})
    }

    const termFormItems = [
      {
        text: <FormattedMessage id="labelPerMonth" />,
        icon: "icon-calendar",
      },
      {
        text: <FormattedMessage id="labelForHalfYear" />,
        icon: "icon-calendar2",
      },
      {
        text: <FormattedMessage id="labelForYear" />,
        icon: "icon-calendar3",
      },
      {
        text: <FormattedMessage id="titleSelectPeriod" />,
        icon: "icon-calendar-edit",
      },
    ]

    const termForm = (data) => {
      return (
        <List
          dataSource={data}
          renderItem={(item) => (
            <List.Item
              onClick={() => onShowPeriodModal(true)}
              size="small"
            >
              <div className={classes.item}>
                <span className={classes.icon}><Icon name={item.icon}/></span> <span className={classes.text}>{item.text}</span> <span className={classes.arrow}>{mapIconItem("arrowRight")}</span>
              </div>
            </List.Item>
          )}
        />
      )
    }

    return (
      <div className={classes.wrapper}>
        <Header back={`${back}/${id}`} title={title} notification={2} />
        <Wrapper nopadding>
          <DateRangeSelect termSelect/>

        </Wrapper>
        <Wrapper transparent nopadding>
          <Row gutter={[16,16]}>
            {
              historyList.map((el, ind) => {
                return (
                  <Col key={ind} span={24}>
                    <label>{el.date}</label>
                    <Wrapper nomargin>
                      <List>
                        {el.operations.map((i, key)=>{
                          return <List.Item key={key} style={{padding: "12px 0"}}>
                            <CardListItem data={i} navigate={this.props.navigate}/>
                          </List.Item>
                        })}
                      </List>
                    </Wrapper>
                  </Col>
                )
              })
            }
          </Row>
        </Wrapper>
        <ContentModal
          name="termRange"
          open={termFormIsOpen}
          showModal={() => openTermForm(!termFormIsOpen)}
          data={termForm(termFormItems)}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setBackLink: (backLink) => dispatch(setBackLink({backLink})),
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(History));