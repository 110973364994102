import React, {useState} from 'react';
import ListMenu from "../../menu/ListMenu";
import {Col, List, Row} from "antd";
import classes from "./dateRange.module.scss";
import Icon from "../../Icon";
import mapIconItem from "../../../../services/mapIconItem";
import Wrapper from "../../wrapper";
import DateSelect from "../DateSelect";
import PrimaryButton from "../../buttons/PrimaryButton";
import ContentModal from "../../modal/ContentModal/ContentModal";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import dayjs from "dayjs";
import {useIntl} from 'react-intl'

const DateRangeSelect = (props) => {

  const [showPeriodModal, onShowPeriodModal] = useState(false)
  const [showTermModal, onShowTermModal] = useState(false)
  const [value, onChange] = useState(false);
  const intl = useIntl()

  console.log("VAL", value)

  const data = [
    {
      text: value ? `${dayjs(value[0]).format("DD MMMM")} - ${dayjs(value[1]).format("DD MMMM")}`: intl.formatMessage({id: "titleSpecifyPeriod"}),
      icon: "icon-calendar2",
    },
  ]

  const termList = [
    {
      id:1,
      text: intl.formatMessage({id: "labelPerMonth"}),
      icon: "icon-calendar",
    },
    {
      id:2,
      text: intl.formatMessage({id: "labelForHalfYear"}),
      icon: "icon-calendar2",
    },
    {
      id:3,
      text: intl.formatMessage({id: "labelForYear"}),
      icon: "icon-calendar2",
    },
    {
      id:4,
      text: intl.formatMessage({id: "titleSelectPeriod"}),
      icon: "icon-calendar-edit",
    },
  ]

  const applyDate = () => {

    onShowPeriodModal(false)
  }

  const periodForm = () => {
    return (
      <Wrapper transparent={true} nomargin={true}>
        <Row gutter={[16,24]}>
          <Col span={24}>
            <h2 className={classes.title}>{intl.formatMessage({id: "titleSelectPeriod"})}</h2>
            <DateRangePicker
              onChange={onChange}
              value={value}
              isOpen={true}
              className={classes.picker}
              format={"dd.MM.yyyy"}
              required={true}
              rangeDivider={"по"}
              calendarIcon={false}
              clearIcon={false}
              yearPlaceholder={"____"}
              monthPlaceholder={"__"}
              dayPlaceholder={"__"}
              locale={props.locale}
            />
          </Col>
          <Col span={24}>
            <PrimaryButton
              title={intl.formatMessage({id: "buttonApply"})}
              onClick={value ? () => applyDate() : ()=>{}}
              {
                ... !value && {"disabled": true}
              }
            />
          </Col>
        </Row>
      </Wrapper>
    )
  }

  const termForm = () => {
    return (
      <List
        dataSource={termList}
        renderItem={(item) => (
          <List.Item
            onClick={() => {
              onShowTermModal(false)
              switch (item.id) {
                case 1:
                  return onChange([dayjs(new Date()).subtract(1, 'month'), dayjs(new Date())])
                case 2:
                  return onChange([dayjs(new Date()).subtract(6, 'month'), dayjs(new Date())])
                case 3:
                  return onChange([dayjs(new Date()).subtract(1, 'year'), dayjs(new Date())])
                case 4:
                  return onShowPeriodModal(true)
              }
            }}
            size="small"
          >
            <div className={classes.item}>
              <span className={classes.icon}><Icon name={item.icon}/></span> <span className={classes.text}>{item.text}</span> <span className={classes.arrow}>{mapIconItem("arrowRight")}</span>
            </div>
          </List.Item>
        )}
      />
    )
  }

  return (
    <>
      <List
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            onClick={
              props?.termSelect ?
                () => onShowTermModal(true) :
                () => onShowPeriodModal(true)
            }
            size="small"
          >
            <div className={classes.item}>
              <span className={classes.icon}><Icon name={item.icon}/></span> <span className={classes.text}>{item.text}</span> <span className={classes.arrow}>{mapIconItem("arrowRight")}</span>
            </div>
          </List.Item>
        )}
      />
      <ContentModal
        open={showPeriodModal}
        name="showPeriodModal"
        showModal={() => onShowPeriodModal(false)}
        data={periodForm()}
      />
      <ContentModal
        open={showTermModal}
        name="showTermModal"
        showModal={() => onShowTermModal(false)}
        data={termForm()}
      />
    </>
  );
};

export default DateRangeSelect;