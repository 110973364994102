import { Server } from "miragejs";
import  creditCard40_preview from "../images/services/creditCard40_preview.png"
import  installmentCard_preview from "../images/services/installmentCard_preview.png"
import  uzCard_preview from "../images/services/uzCard_preview.png"
import  mastercardWorld_preview from "../images/services/mastercardWorld_preview.png"
import  humo_preview from "../images/services/humo_preview.png"
import  cashLoan_preview from "../images/services/cashLoan_preview.png"
import  nationalGreen_preview from "../images/services/nationalGreen_preview.png"
import  educationalLoan_preview from "../images/services/educationalLoan_preview.png"
import  onlineMicroloan_preview from "../images/services/onlineMicroloan_preview.png"
import  microloanSEPersons_preview from "../images/services/microloanSEPersons_preview.png"
import  withoutCollateral_preview from "../images/services/withoutCollateral_preview.png"
import  ecoGreenCar_preview from "../images/services/ecoGreenCar_preview.png"
import  national_preview from "../images/services/national_preview.png"
import  newHousing_preview from "../images/services/newHousing_preview.png"
import  secondaryHousing_preview from "../images/services/secondaryHousing_preview.png"

import  creditCard40_full from "../images/services/creditCard40_full.png"
import  installmentCard_full from "../images/services/installmentCard_full.png"
import  uzCard_full from "../images/services/uzCard_full.png"
import  mastercardWorld_full from "../images/services/mastercardWorld_full.png"
import  humo_full from "../images/services/humo_full.png"
import  cashLoan_full from "../images/services/cashLoan_full.png"
import  nationalGreen_full from "../images/services/nationalGreen_full.png"
import  educationalLoan_full from "../images/services/educationalLoan_full.png"
import  onlineMicroloan_full from "../images/services/onlineMicroloan_full.png"
import  microloanSEPersons_full from "../images/services/microloanSEPersons_full.png"
import  withoutCollateral_full from "../images/services/withoutCollateral_full.png"
import  ecoGreenCar_full from "../images/services/ecoGreenCar_full.png"
import  national_full from "../images/services/national_full.png"
import  newHousing_full from "../images/services/newHousing_full.png"
import  secondaryHousing_full from "../images/services/secondaryHousing_full.png"

const dataRus = {
  cardsList: [
    {
      title: "Кредитная карта До 40 дней без %",
      description: "При грамотном подходе к тратам кредитная карта становится полезным финансовым инструментом. С ней можно учиться планировать свой бюджет так, чтобы всегда укладываться в беспроцентный период.",
      image: creditCard40_preview,
      link: "creditCard40",
    },
    {
      title: "Карта рассрочки",
      description: "Настоящая рассрочка без процентов и переплат.",
      image: installmentCard_preview ,
      link: "installmentCard",
    },
  ],
  debitCardsList: [
    {
      title: "UzCard",
      description: "Uzcard – это пластиковая карта, которая очень удобна для осуществления безналичных платежей" +
        "за различные покупки и услуги, P2P-переводов с карты на карту в национальной валюте.",
      image: uzCard_preview,
      link: "uzCard",
    },
    {
      title: "Mastercard World",
      description: "Начните свой опыт с простой и удобной карты Mastercard World!",
      image: mastercardWorld_preview,
      link: "mastercardWorld",
    },
    {
      title: "Humo",
      description: "Оплата в одно касание",
      image: humo_preview,
      link: "humo",
    },
  ],
  creditOffersList: [
    {
      title: "Кредит наличными",
      description: "Кредиты предоставляются для потребительских нужд населения следующих видов товаров и услуг, произведенных в Республике Узбекистан.",
      image: cashLoan_preview,
      link: "cashLoan",
    },
    {
      title: "National Green",
      description: "Приобретение и установка устройств возобновляемых источников и энергосберегающего оборудования",
      image: nationalGreen_preview,
      link: "nationalGreen",
    },
    {
      title: "Образовательный кредит",
      description: "Кредит для очной, вечерней, заочной форм обучения в высших, средних специальных и профессиональных учебных заведениях для студентов, принятых на бакалавриат и магистратуру.",
      image: educationalLoan_preview,
      link: "educationalLoan",
    },
  ],
  microloansList: [
    {
      title: "Онлайн Микрозайм",
      description: "Онлайн микрозайм выдается онлайн и денежные средства переводятся на пластиковую карту заёмщика",
      image: onlineMicroloan_preview,
      link: "onlineMicroloan",
    },
    {
      title: "Микрозайм для самозанятых лиц",
      description: "Микрозаймы предоставляются САМОЗАНЯТЫМ ЛИЦАМ в национальной валюте на любые цели, путем перечисления банком денежных средств на пластиковые карты заемщика в нашем банке",
      image: microloanSEPersons_preview,
      link: "microloanSEPersons"
    },
  ],
  creditCardsList: [
    {
      title: "Кредитная карта До 40 дней без %",
      description: "При грамотном подходе к тратам кредитная карта становится полезным финансовым инструментом. С ней можно учиться планировать свой бюджет так, чтобы всегда укладываться в беспроцентный период.",
      image: creditCard40_preview,
      link: "creditCard40",
    },
    {
      title: "Карта рассрочки",
      description: "Настоящая рассрочка без процентов и переплат.",
      image: installmentCard_preview,
      link: "installmentCard",
    },
  ],
  autoCreditList: [
    {
      title: "Автокредит без залога",
      description: "Автокредит на приобретение автотранспортных средств с первичного и вторичного рынка",
      image: ecoGreenCar_preview,
      link: "withoutCollateral",
    },
    {
      title: 'Автокредит "EcoGreenCar"',
      description: "Автокредит на приобретение электромобилей и гибридных автотранспортных средств",
      image: withoutCollateral_preview,
      link: "EcoGreenCar",
    },
    {
      title: 'Национальный',
      description: "Для приобретения на первичном и вторичном рынках автомобилей производства UzAuto Motors",
      image: national_preview,
      link: "national",
    },
  ],
  mortgageList: [
    {
      title: "Ипотека на новое жильё",
      description: "Кредит на приобретение квартир в строящихся жилых комплексах.",
      image: newHousing_preview,
      link: "newHousing",
    },
    {
      title: "Ипотека на вторичное жилье",
      description: "Приобретение готового жилого дома или квартиры в жилом многоквартирном доме.",
      image: secondaryHousing_preview,
      link: "secondaryHousing",
    },
  ],

  cardsCreditCreditCard40: {
    listData: {
      title: "Кредитная карта До 40 дней без %",
      description: "При грамотном подходе к тратам кредитная карта становится полезным финансовым инструментом. С ней можно учиться планировать свой бюджет так, чтобы всегда укладываться в беспроцентный период.",
      image: creditCard40_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Сумма кредита:",
          description: "от 2 000 000 до 50 000 000 сум",
        },
        {
          icon : "icon-calendar-2",
          title: "Срок кредита:",
          description: "от 1 до 48 мес.",
        },
        {
          icon : "icon-discount-shape",
          title: "Процентная ставка:",
          description: "26.9",
        },
        {
          icon : "icon-card-tick",
          title: "Выпуск и обслуживание:",
          description: "бесплатно",
        },
      ]
    },
    modalData: [
      {title: "Сумма кредита:", description: "до 75% от суммы, указанной в договоре купли-продажи"},
      {title: "Авансовый платеж:", description: "от 25%"},
      {title: "Кредитная гарантия:", description: "Пример 1: Прежде чем автомобиль, купленный в кредит, будет принят в залог, предоставляется страховой полис от риска невозврата кредита."},
      {title: "Требования к заемщикам:", description: "Физическое лицо, являющееся резидентом Республики Узбекистан и достигшее 18 лет."},
      {title: "Требования к автомобилю:", description: "Пример 1: Срок эксплуатации авто не превышает 3 лет/ до 4 лет<br/>Пример 2: Потребительский кредит на приобретение автомобилей производства АО «УзАвто Моторс» и «GM Узбекистан»."},
    ]
  },
  cardsCreditInstallmentCard: {
    listData: {
      title: "Карта рассрочки",
      description: "Настоящая рассрочка без процентов и переплат.",
      image: installmentCard_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Сумма кредита:",
          description: "от 2 000 000 до 50 000 000 сум",
        },
        {
          icon : "icon-calendar-2",
          title: "Срок кредита:",
          description: "от 1 до 24 мес.",
        },
        {
          icon : "icon-discount-shape",
          title: "Процентная ставка:",
          description: "0",
        },
        {
          icon : "icon-card-tick",
          title: "Выпуск и обслуживание:",
          description: "бесплатно",
        },
      ]
    },
    modalData: [
      {title: "Сумма кредита:", description: "до 75% от суммы, указанной в договоре купли-продажи"},
      {title: "Авансовый платеж:", description: "от 25%"},
      {title: "Кредитная гарантия:", description: "Пример 1: Прежде чем автомобиль, купленный в кредит, будет принят в залог, предоставляется страховой полис от риска невозврата кредита."},
      {title: "Требования к заемщикам:", description: "Физическое лицо, являющееся резидентом Республики Узбекистан и достигшее 18 лет."},
      {title: "Требования к автомобилю:", description: "Пример 1: Срок эксплуатации авто не превышает 3 лет/ до 4 лет<br/>Пример 2: Потребительский кредит на приобретение автомобилей производства АО «УзАвто Моторс» и «GM Узбекистан»."},
    ]
  },
  cardsDebitUzCard: {
    listData: {
      title: "UzCard",
      description: "Uzcard – это пластиковая карта, которая очень удобна" +
        "для осуществления безналичных платежей за различные покупки и услуги, P2P-переводов с карты на карту в национальной валюте.",
      image: uzCard_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Стоимость выпуска",
          description: "бесплатно",
        },
        {
          icon : "icon-card-receive",
          title: "Кэшбэк:",
          description: "до 20%",
        },
        {
          icon : "icon-card-tick",
          title: "Стоимость обслуживания:",
          description: "бесплатно",
        },
      ]
    },
    modalData: [
      {title: "Сумма кредита:", description: "до 75% от суммы, указанной в договоре купли-продажи"},
      {title: "Авансовый платеж:", description: "от 25%"},
      {title: "Кредитная гарантия:", description: "Пример 1: Прежде чем автомобиль, купленный в кредит, будет принят в залог, предоставляется страховой полис от риска невозврата кредита."},
      {title: "Требования к заемщикам:", description: "Физическое лицо, являющееся резидентом Республики Узбекистан и достигшее 18 лет."},
      {title: "Требования к автомобилю:", description: "Пример 1: Срок эксплуатации авто не превышает 3 лет/ до 4 лет<br/>Пример 2: Потребительский кредит на приобретение автомобилей производства АО «УзАвто Моторс» и «GM Узбекистан»."},
    ]
  },
  cardsDebitMastercardWorld: {
    listData: {
      title: "Mastercard World",
      description: "Начните свой опыт с простой и удобной карты Mastercard World!",
      image: mastercardWorld_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Стоимость выпуска",
          description: "35 000 сум",
        },
        {
          icon : "icon-card-receive",
          title: "Кэшбэк:",
          description: "1% на всё",
        },
        {
          icon : "icon-card-tick",
          title: "Стоимость обслуживания:",
          description: "35 000 сум",
        },
      ]
    },
    modalData: [
      {title: "Сумма кредита:", description: "до 75% от суммы, указанной в договоре купли-продажи"},
      {title: "Авансовый платеж:", description: "от 25%"},
      {title: "Кредитная гарантия:", description: "Пример 1: Прежде чем автомобиль, купленный в кредит, будет принят в залог, предоставляется страховой полис от риска невозврата кредита."},
      {title: "Требования к заемщикам:", description: "Физическое лицо, являющееся резидентом Республики Узбекистан и достигшее 18 лет."},
      {title: "Требования к автомобилю:", description: "Пример 1: Срок эксплуатации авто не превышает 3 лет/ до 4 лет<br/>Пример 2: Потребительский кредит на приобретение автомобилей производства АО «УзАвто Моторс» и «GM Узбекистан»."},
    ]
  },
  cardsDebitHumo: {
    listData: {
      title: "Humo",
      description: "Оплата в одно касание",
      image: humo_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Стоимость выпуска",
          description: "35 000 сум",
        },
        {
          icon : "icon-card-receive",
          title: "Кэшбэк:",
          description: "1% на всё",
        },
        {
          icon : "icon-card-tick",
          title: "Стоимость обслуживания:",
          description: "бесплатно",
        },
      ]
    },
    modalData: [
      {title: "Сумма кредита:", description: "до 75% от суммы, указанной в договоре купли-продажи"},
      {title: "Авансовый платеж:", description: "от 25%"},
      {title: "Кредитная гарантия:", description: "Пример 1: Прежде чем автомобиль, купленный в кредит, будет принят в залог, предоставляется страховой полис от риска невозврата кредита."},
      {title: "Требования к заемщикам:", description: "Физическое лицо, являющееся резидентом Республики Узбекистан и достигшее 18 лет."},
      {title: "Требования к автомобилю:", description: "Пример 1: Срок эксплуатации авто не превышает 3 лет/ до 4 лет<br/>Пример 2: Потребительский кредит на приобретение автомобилей производства АО «УзАвто Моторс» и «GM Узбекистан»."},
    ]
  },
  creditsCreditOffersCashLoan: {
    listData: {
      title: "Кредит наличными",
      description: "Настоящая рассрочка без процентов и переплат.",
      image: cashLoan_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Сумма кредита:",
          description: "от 1 000 000 до 50 000 000 сум",
        },
        {
          icon : "icon-calendar-2",
          title: "Срок кредита:",
          description: "от 1 до 36 мес.",
        },
        {
          icon : "icon-discount-shape",
          title: "Процентная ставка:",
          description: "31.9",
        },
      ]
    },
    modalData: [
      {title: "Сумма кредита:", description: "до 75% от суммы, указанной в договоре купли-продажи"},
      {title: "Авансовый платеж:", description: "от 25%"},
      {title: "Кредитная гарантия:", description: "Пример 1: Прежде чем автомобиль, купленный в кредит, будет принят в залог, предоставляется страховой полис от риска невозврата кредита."},
      {title: "Требования к заемщикам:", description: "Физическое лицо, являющееся резидентом Республики Узбекистан и достигшее 18 лет."},
      {title: "Требования к автомобилю:", description: "Пример 1: Срок эксплуатации авто не превышает 3 лет/ до 4 лет<br/>Пример 2: Потребительский кредит на приобретение автомобилей производства АО «УзАвто Моторс» и «GM Узбекистан»."},
    ]
  },
  creditsCreditNationalGreen: {
    listData: {
      title: "National Green",
      description: "Приобретение и установка устройств возобновляемых источников и энергосберегающего оборудования",
      image: nationalGreen_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Сумма кредита:",
          description: "от 1 000 000 до 60 000 000 сум",
        },
        {
          icon : "icon-calendar-2",
          title: "Срок кредита:",
          description: "от 1 до 60 мес.",
        },
        {
          icon : "icon-discount-shape",
          title: "Процентная ставка:",
          description: "22",
        },
      ]
    },
    modalData: [
      {title: "Сумма кредита:", description: "до 75% от суммы, указанной в договоре купли-продажи"},
      {title: "Авансовый платеж:", description: "от 25%"},
      {title: "Кредитная гарантия:", description: "Пример 1: Прежде чем автомобиль, купленный в кредит, будет принят в залог, предоставляется страховой полис от риска невозврата кредита."},
      {title: "Требования к заемщикам:", description: "Физическое лицо, являющееся резидентом Республики Узбекистан и достигшее 18 лет."},
      {title: "Требования к автомобилю:", description: "Пример 1: Срок эксплуатации авто не превышает 3 лет/ до 4 лет<br/>Пример 2: Потребительский кредит на приобретение автомобилей производства АО «УзАвто Моторс» и «GM Узбекистан»."},
    ]
  },
  creditsCreditEducationalLoan: {
    listData: {
      title: "Образовательный кредит",
      description: "Кредит для очной, вечерней, заочной форм обучения в высших, средних специальных и профессиональных учебных заведениях для студентов, принятых на бакалавриат и магистратуру.",
      image: educationalLoan_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Сумма кредита:",
          description: "от 1 000 000 до 60 000 000 сум",
        },
        {
          icon : "icon-calendar-2",
          title: "Срок кредита:",
          description: "от 1 до 60 мес.",
        },
        {
          icon : "icon-discount-shape",
          title: "Процентная ставка:",
          description: "24",
        },
      ]
    },
    modalData: [
      {title: "Сумма кредита:", description: "до 75% от суммы, указанной в договоре купли-продажи"},
      {title: "Авансовый платеж:", description: "от 25%"},
      {title: "Кредитная гарантия:", description: "Пример 1: Прежде чем автомобиль, купленный в кредит, будет принят в залог, предоставляется страховой полис от риска невозврата кредита."},
      {title: "Требования к заемщикам:", description: "Физическое лицо, являющееся резидентом Республики Узбекистан и достигшее 18 лет."},
      {title: "Требования к автомобилю:", description: "Пример 1: Срок эксплуатации авто не превышает 3 лет/ до 4 лет<br/>Пример 2: Потребительский кредит на приобретение автомобилей производства АО «УзАвто Моторс» и «GM Узбекистан»."},
    ]
  },
  creditsMicroloansOnlineMicroloan: {
    listData: {
      title: "Онлайн Микрозайм",
      description: "Онлайн микрозайм выдается онлайн и денежные средства переводятся на пластиковую карту заёмщика.",
      image: onlineMicroloan_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Сумма кредита:",
          description: "от 1 000 000 до 30 000 000 сум",
        },
        {
          icon : "icon-calendar-2",
          title: "Срок кредита:",
          description: "от 1 до 36 мес.",
        },
        {
          icon : "icon-discount-shape",
          title: "Процентная ставка:",
          description: "28",
        },
      ]
    },
    modalData: [
      {title: "Сумма кредита:", description: "до 75% от суммы, указанной в договоре купли-продажи"},
      {title: "Авансовый платеж:", description: "от 25%"},
      {title: "Кредитная гарантия:", description: "Пример 1: Прежде чем автомобиль, купленный в кредит, будет принят в залог, предоставляется страховой полис от риска невозврата кредита."},
      {title: "Требования к заемщикам:", description: "Физическое лицо, являющееся резидентом Республики Узбекистан и достигшее 18 лет."},
      {title: "Требования к автомобилю:", description: "Пример 1: Срок эксплуатации авто не превышает 3 лет/ до 4 лет<br/>Пример 2: Потребительский кредит на приобретение автомобилей производства АО «УзАвто Моторс» и «GM Узбекистан»."},
    ]
  },
  creditsMicroloansMicroloanSEPersons: {
    listData: {
      title: "Микрозайм для самозанятых лиц",
      description: "Микрозаймы предоставляются САМОЗАНЯТЫМ ЛИЦАМ в национальной валюте на любые цели, путем перечисления банком денежных средств на пластиковые карты заемщика в нашем банке.",
      image: microloanSEPersons_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Сумма кредита:",
          description: "от 1 000 000 до 100 000 000 сум",
        },
        {
          icon : "icon-calendar-2",
          title: "Срок кредита:",
          description: "от 9 до 36 мес.",
        },
        {
          icon : "icon-discount-shape",
          title: "Процентная ставка:",
          description: "29.99",
        },
      ]
    },
    modalData: [
      {title: "Сумма кредита:", description: "до 75% от суммы, указанной в договоре купли-продажи"},
      {title: "Авансовый платеж:", description: "от 25%"},
      {title: "Кредитная гарантия:", description: "Пример 1: Прежде чем автомобиль, купленный в кредит, будет принят в залог, предоставляется страховой полис от риска невозврата кредита."},
      {title: "Требования к заемщикам:", description: "Физическое лицо, являющееся резидентом Республики Узбекистан и достигшее 18 лет."},
      {title: "Требования к автомобилю:", description: "Пример 1: Срок эксплуатации авто не превышает 3 лет/ до 4 лет<br/>Пример 2: Потребительский кредит на приобретение автомобилей производства АО «УзАвто Моторс» и «GM Узбекистан»."},
    ]
  },
  creditsAutoCreditWithoutCollateral: {
    listData: {
      title: "Автокредит без залога",
      description: "Автокредит на приобретение автотранспортных средств с первичного и вторичного рынка",
      image: withoutCollateral_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Сумма кредита:",
          description: "от 1 000 000 до 500 000 000 сум",
        },
        {
          icon : "icon-calendar-2",
          title: "Срок кредита:",
          description: "от 12 до 60 мес.",
        },
        {
          icon : "icon-discount-shape",
          title: "Процентная ставка:",
          description: "26.9",
        },
      ]
    },
    modalData: [
      {title: "Сумма кредита:", description: "до 75% от суммы, указанной в договоре купли-продажи"},
      {title: "Авансовый платеж:", description: "от 25%"},
      {title: "Кредитная гарантия:", description: "Пример 1: Прежде чем автомобиль, купленный в кредит, будет принят в залог, предоставляется страховой полис от риска невозврата кредита."},
      {title: "Требования к заемщикам:", description: "Физическое лицо, являющееся резидентом Республики Узбекистан и достигшее 18 лет."},
      {title: "Требования к автомобилю:", description: "Пример 1: Срок эксплуатации авто не превышает 3 лет/ до 4 лет<br/>Пример 2: Потребительский кредит на приобретение автомобилей производства АО «УзАвто Моторс» и «GM Узбекистан»."},
    ]
  },
  creditsAutoCreditEcoGreenCar: {
    listData: {
      title: 'Автокредит "EcoGreenCar"',
      description: "Автокредит на приобретение электромобилей и гибридных автотранспортных средств",
      image: ecoGreenCar_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Сумма кредита:",
          description: "от 1 000 000 до 500 000 000 сум",
        },
        {
          icon : "icon-calendar-2",
          title: "Срок кредита:",
          description: "от 12 до 48 мес.",
        },
        {
          icon : "icon-discount-shape",
          title: "Процентная ставка:",
          description: "28",
        },
      ]
    },
    modalData: [
      {title: "Сумма кредита:", description: "до 75% от суммы, указанной в договоре купли-продажи"},
      {title: "Авансовый платеж:", description: "от 25%"},
      {title: "Кредитная гарантия:", description: "Пример 1: Прежде чем автомобиль, купленный в кредит, будет принят в залог, предоставляется страховой полис от риска невозврата кредита."},
      {title: "Требования к заемщикам:", description: "Физическое лицо, являющееся резидентом Республики Узбекистан и достигшее 18 лет."},
      {title: "Требования к автомобилю:", description: "Пример 1: Срок эксплуатации авто не превышает 3 лет/ до 4 лет<br/>Пример 2: Потребительский кредит на приобретение автомобилей производства АО «УзАвто Моторс» и «GM Узбекистан»."},
    ]
  },
  creditsAutoCreditNational: {
    listData: {
      title: 'Национальный',
      description: "Для приобретения на первичном и вторичном рынках автомобилей производства UzAuto Motors",
      image: national_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Сумма кредита:",
          description: "от 1 000 000 до 500 000 000 сум",
        },
        {
          icon : "icon-calendar-2",
          title: "Срок кредита:",
          description: "от 12 до 60 мес.",
        },
        {
          icon : "icon-discount-shape",
          title: "Процентная ставка:",
          description: "23.5",
        },
      ]
    },
    modalData: [
      {title: "Сумма кредита:", description: "до 75% от суммы, указанной в договоре купли-продажи"},
      {title: "Авансовый платеж:", description: "от 25%"},
      {title: "Кредитная гарантия:", description: "Пример 1: Прежде чем автомобиль, купленный в кредит, будет принят в залог, предоставляется страховой полис от риска невозврата кредита."},
      {title: "Требования к заемщикам:", description: "Физическое лицо, являющееся резидентом Республики Узбекистан и достигшее 18 лет."},
      {title: "Требования к автомобилю:", description: "Пример 1: Срок эксплуатации авто не превышает 3 лет/ до 4 лет<br/>Пример 2: Потребительский кредит на приобретение автомобилей производства АО «УзАвто Моторс» и «GM Узбекистан»."},
    ]
  },
  creditsMortgageNewHousing: {
    listData: {
      title: 'Ипотека на новое жильё',
      description: "Кредит на приобретение квартир в строящихся жилых комплексах.",
      image: newHousing_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Сумма кредита:",
          description: "от 1 000 000 до 820 000 000 сум",
        },
        {
          icon : "icon-calendar-2",
          title: "Срок кредита:",
          description: "от 1 до 15 лет",
        },
        {
          icon : "icon-receipt-discount",
          title: "Первоначальный взнос:",
          description: "25%",
        },
        {
          icon : "icon-discount-shape",
          title: "Процентная ставка:",
          description: "24.9%",
        },
      ]
    },
    modalData: [
      {title: "Сумма кредита:", description: "до 75% от суммы, указанной в договоре купли-продажи"},
      {title: "Авансовый платеж:", description: "от 25%"},
      {title: "Кредитная гарантия:", description: "Пример 1: Прежде чем автомобиль, купленный в кредит, будет принят в залог, предоставляется страховой полис от риска невозврата кредита."},
      {title: "Требования к заемщикам:", description: "Физическое лицо, являющееся резидентом Республики Узбекистан и достигшее 18 лет."},
      {title: "Требования к автомобилю:", description: "Пример 1: Срок эксплуатации авто не превышает 3 лет/ до 4 лет<br/>Пример 2: Потребительский кредит на приобретение автомобилей производства АО «УзАвто Моторс» и «GM Узбекистан»."},
    ]
  },
  creditsMortgageSecondaryHousing: {
    listData: {
      title: 'Ипотека на вторичное жилье',
      description: "Приобретение готового жилого дома или квартиры в жилом многоквартирном доме.",
      image: secondaryHousing_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Сумма кредита:",
          description: "от 1 000 000 до 820 000 000 сум",
        },
        {
          icon : "icon-calendar-2",
          title: "Срок кредита:",
          description: "от 1 до 10 лет",
        },
        {
          icon : "icon-receipt-discount",
          title: "Первоначальный взнос:",
          description: "25%",
        },
        {
          icon : "icon-discount-shape",
          title: "Процентная ставка:",
          description: "23,9%",
        },
      ]
    },
    modalData: [
      {title: "Сумма кредита:", description: "до 75% от суммы, указанной в договоре купли-продажи"},
      {title: "Авансовый платеж:", description: "от 25%"},
      {title: "Кредитная гарантия:", description: "Пример 1: Прежде чем автомобиль, купленный в кредит, будет принят в залог, предоставляется страховой полис от риска невозврата кредита."},
      {title: "Требования к заемщикам:", description: "Физическое лицо, являющееся резидентом Республики Узбекистан и достигшее 18 лет."},
      {title: "Требования к автомобилю:", description: "Пример 1: Срок эксплуатации авто не превышает 3 лет/ до 4 лет<br/>Пример 2: Потребительский кредит на приобретение автомобилей производства АО «УзАвто Моторс» и «GM Узбекистан»."},
    ]
  },
}

const dataEng = {
  cardsList: [
    {
      title: "Credit card Up to 40 days without %",
      description: "With a competent approach to spending, a credit card becomes a useful financial tool. With it, you can learn to plan your budget so as to always meet the interest-free period.",
      image: creditCard40_preview,
      link: "creditCard40",
    },
    {
      title: "Installment card",
      description: "True installment plan without interest or overpayments.",
      image: installmentCard_preview ,
      link: "installationCard",
    },
  ],
  debitCardsList: [
    {
      title: "UzCard",
      description: "Uzcard is a plastic card that is very convenient for making non-cash payments" +
        "for various purchases and services, P2P transfers from card to card in national currency.",
      image: uzCard_preview,
      link: "uzCard",
    },
    {
      title: "Mastercard World",
      description: "Start your experience with a simple and convenient Mastercard World card!",
      image: mastercardWorld_preview,
      link: "mastercardWorld",
    },
    {
      title: "Humo",
      description: "One-touch payment",
      image: humo_preview,
      link: "humo",
    },
  ],
  creditOffersList: [
    {
      title: "Cash loan",
      description: "Loans are provided for the consumer needs of the population of the following types of goods and services produced in the Republic of Uzbekistan.",
      image: cashLoan_preview,
      link: "cashLoan",
    },
    {
      title: "National Green",
      description: "Purchase and installation of renewable energy devices and energy-saving equipment",
      image: nationalGreen_preview,
      link: "nationalGreen",
    },
    {
      title: "Education loan",
      description: "Credit for full-time, evening, and correspondence courses in higher, secondary specialized and vocational educational institutions for students admitted to bachelor's and master's degrees.",
      image: educationalLoan_preview,
      link: "educationalLoan",
    },
  ],
  microloansList: [
    {
      title: "Online Microloan",
      description: "Online microloan is issued online and funds are transferred to the borrower's plastic card",
      image: onlineMicroloan_preview,
      link: "onlineMicroloan",
    },
    {
      title: "Microloan for self-employed persons",
      description: "Microloans are provided to SELF-EMPLOYED PERSONS in national currency for any purpose, by transferring funds by the bank to the borrower's plastic cards in our bank",
      image: microloanSEPersons_preview,
      link: "microloanSEPersons"
    },
  ],
  creditCardsList: [
    {
      title: "Credit card Up to 40 days without %",
      description: "With a competent approach to spending, a credit card becomes a useful financial tool. With it, you can learn to plan your budget so as to always meet the interest-free period.",
      image: creditCard40_preview,
      link: "creditCard40",
    },
    {
      title: "Installment card",
      description: "True installment plan without interest or overpayments.",
      image: installmentCard_preview,
      link: "installationCard",
    },
  ],
  autoCreditList: [
    {
      title: "Car loan without collateral",
      description: "Car loan for the purchase of vehicles from the primary and secondary market",
      image: ecoGreenCar_preview,
      link: "withoutCollateral",
    },
    {
      title: 'Car loan "EcoGreenCar"',
      description: "Car loan for the purchase of electric and hybrid vehicles",
      image: withoutCollateral_preview,
      link: "EcoGreenCar",
    },
    {
      title: 'National',
      description: "For the purchase of cars produced by UzAuto Motors on the primary and secondary markets",
      image: national_preview,
      link: "national",
    },
  ],
  mortgageList: [
    {
      title: "Mortgage for new housing",
      description: "Loan for the purchase of apartments in residential complexes under construction.",
      image: newHousing_preview,
      link: "newHousing",
    },
    {
      title: "Mortgage for secondary housing",
      description: "Purchase of a finished residential building or apartment in a residential apartment building.",
      image: secondaryHousing_preview,
      link: "secondaryHousing",
    },
  ],

  cardsCreditCreditCard40: {
    listData: {
      title: "Credit card Up to 40 days without %",
      description: "With a competent approach to spending, a credit card becomes a useful financial tool. With it, you can learn to plan your budget so as to always meet the interest-free period.",
      image: creditCard40_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Loan amount:",
          description: "from 2,000,000 to 50,000,000 soum",
        },
        {
          icon : "icon-calendar-2",
          title: "Loan term:",
          description: "from 1 to 48 months.",
        },
        {
          icon : "icon-discount-shape",
          title: "Interest rate:",
          description: "26.9",
        },
        {
          icon : "icon-card-tick",
          title: "Release and maintenance:",
          description: "free",
        },
      ]
    },
    modalData: [
      {title: "Сумма кредита:", description: "до 75% от суммы, указанной в договоре купли-продажи"},
      {title: "Авансовый платеж:", description: "от 25%"},
      {title: "Кредитная гарантия:", description: "Пример 1: Прежде чем автомобиль, купленный в кредит, будет принят в залог, предоставляется страховой полис от риска невозврата кредита."},
      {title: "Требования к заемщикам:", description: "Физическое лицо, являющееся резидентом Республики Узбекистан и достигшее 18 лет."},
      {title: "Требования к автомобилю:", description: "Пример 1: Срок эксплуатации авто не превышает 3 лет/ до 4 лет<br/>Пример 2: Потребительский кредит на приобретение автомобилей производства АО «УзАвто Моторс» и «GM Узбекистан»."},
    ]
  },
  cardsCreditInstallmentCard: {
    listData: {
      title: "Installment card",
      description: "True installment plan without interest or overpayments.",
      image: installmentCard_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Loan amount:",
          description: "from 2,000,000 to 50,000,000 soum",
        },
        {
          icon : "icon-calendar-2",
          title: "Loan term:",
          description: "from 1 to 24 months.",
        },
        {
          icon : "icon-discount-shape",
          title: "Interest rate:",
          description: "0",
        },
        {
          icon : "icon-card-tick",
          title: "Release and maintenance:",
          description: "free",
        },
      ]
    },
    modalData: [
      {title: "Loan amount:", description: "up to 75% of the amount specified in the purchase and sale agreement"},
      {title: "Advance payment:", description: "from 25%"},
      {title: "Credit Guarantee:", description: "Example 1: Before a car purchased on credit is accepted as collateral, an insurance policy against the risk of loan default is provided."},
      {title: "Requirements for borrowers:", description: "An individual who is a resident of the Republic of Uzbekistan and has reached 18 years of age."},
      {title: "Requirements for the car:", description: "Example 1: The service life of the car does not exceed 3 years / up to 4 years<br/>Example 2: Consumer loan for the purchase of cars produced by UzAuto Motors JSC and GM Uzbekistan" },
    ]
  },
  cardsDebitUzCard: {
    listData: {
      title: "UzCard",
      description: "Uzcard is a plastic card that is very convenient" +
        "for making non-cash payments for various purchases and services, P2P transfers from card to card in national currency.",
      image: uzCard_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Issue Cost",
          description: "free",
        },
        {
          icon : "icon-card-receive",
          title: "Cashback:",
          description: "up to 20%",
        },
        {
          icon : "icon-card-tick",
          title: "Service cost:",
          description: "free",
        },
      ]
    },
    modalData: [
      {title: "Loan amount:", description: "up to 75% of the amount specified in the purchase and sale agreement"},
      {title: "Advance payment:", description: "from 25%"},
      {title: "Credit Guarantee:", description: "Example 1: Before a car purchased on credit is accepted as collateral, an insurance policy against the risk of loan default is provided."},
      {title: "Requirements for borrowers:", description: "An individual who is a resident of the Republic of Uzbekistan and has reached 18 years of age."},
      {title: "Requirements for the car:", description: "Example 1: The service life of the car does not exceed 3 years / up to 4 years<br/>Example 2: Consumer loan for the purchase of cars produced by UzAuto Motors JSC and GM Uzbekistan"},
    ]
  },
  cardsDebitMastercardWorld: {
    listData: {
      title: "Mastercard World",
      description: "Start your experience with a simple and convenient Mastercard World card!",
      image: mastercardWorld_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Issue Cost",
          description: "35,000 sum",
        },
        {
          icon : "icon-card-receive",
          title: "Cashback:",
          description: "1% on everything",
        },
        {
          icon : "icon-card-tick",
          title: "Service cost:",
          description: "35,000 sum",
        },
      ]
    },
    modalData: [
      {title: "Loan amount:", description: "up to 75% of the amount specified in the purchase and sale agreement"},
      {title: "Advance payment:", description: "from 25%"},
      {title: "Credit Guarantee:", description: "Example 1: Before a car purchased on credit is accepted as collateral, an insurance policy against the risk of loan default is provided."},
      {title: "Requirements for borrowers:", description: "An individual who is a resident of the Republic of Uzbekistan and has reached 18 years of age."},
      {title: "Requirements for the car:", description: "Example 1: The service life of the car does not exceed 3 years / up to 4 years<br/>Example 2: Consumer loan for the purchase of cars produced by UzAuto Motors JSC and GM Uzbekistan" },
    ]
  },
  cardsDebitHumo: {
    listData: {
      title: "Humo",
      description: "One-touch payment",
      image: humo_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Issue Cost",
          description: "35,000 sum",
        },
        {
          icon : "icon-card-receive",
          title: "Cashback:",
          description: "1% on everything",
        },
        {
          icon : "icon-card-tick",
          title: "Service cost:",
          description: "free",
        },
      ]
    },
    modalData: [
      {title: "Loan amount:", description: "up to 75% of the amount specified in the purchase and sale agreement"},
      {title: "Advance payment:", description: "from 25%"},
      {title: "Credit Guarantee:", description: "Example 1: Before a car purchased on credit is accepted as collateral, an insurance policy against the risk of loan default is provided."},
      {title: "Requirements for borrowers:", description: "An individual who is a resident of the Republic of Uzbekistan and has reached 18 years of age."},
      {title: "Requirements for the car:", description: "Example 1: The service life of the car does not exceed 3 years / up to 4 years<br/>Example 2: Consumer loan for the purchase of cars produced by UzAuto Motors JSC and GM Uzbekistan"},
    ]
  },
  creditsCreditOffersCashLoan: {
    listData: {
      title: "Cash loan",
      description: "True installment plan without interest or overpayments.",
      image: cashLoan_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Loan amount:",
          description: "from 1,000,000 to 50,000,000 soum",
        },
        {
          icon : "icon-calendar-2",
          title: "Loan term:",
          description: "from 1 to 36 months.",
        },
        {
          icon : "icon-discount-shape",
          title: "Interest rate:",
          description: "31.9",
        },
      ]
    },
    modalData: [
      {title: "Loan amount:", description: "up to 75% of the amount specified in the purchase and sale agreement"},
      {title: "Advance payment:", description: "from 25%"},
      {title: "Credit Guarantee:", description: "Example 1: Before a car purchased on credit is accepted as collateral, an insurance policy against the risk of loan default is provided."},
      {title: "Requirements for borrowers:", description: "An individual who is a resident of the Republic of Uzbekistan and has reached 18 years of age."},
      {title: "Requirements for the car:", description: "Example 1: The service life of the car does not exceed 3 years / up to 4 years<br/>Example 2: Consumer loan for the purchase of cars produced by UzAuto Motors JSC and GM Uzbekistan"},
    ]
  },
  creditsCreditNationalGreen: {
    listData: {
      title: "National Green",
      description: "Purchase and installation of renewable energy devices and energy-saving equipment",
      image: nationalGreen_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Loan amount:",
          description: "from 1,000,000 to 60,000,000 soum",
        },
        {
          icon : "icon-calendar-2",
          title: "Loan term:",
          description: "from 1 to 60 months.",
        },
        {
          icon : "icon-discount-shape",
          title: "Interest rate:",
          description: "22",
        },
      ]
    },
    modalData: [
      {title: "Loan amount:", description: "up to 75% of the amount specified in the purchase and sale agreement"},
      {title: "Advance payment:", description: "from 25%"},
      {title: "Credit Guarantee:", description: "Example 1: Before a car purchased on credit is accepted as collateral, an insurance policy against the risk of loan default is provided."},
      {title: "Requirements for borrowers:", description: "An individual who is a resident of the Republic of Uzbekistan and has reached 18 years of age."},
      {title: "Requirements for the car:", description: "Example 1: The service life of the car does not exceed 3 years / up to 4 years<br/>Example 2: Consumer loan for the purchase of cars produced by UzAuto Motors JSC and GM Uzbekistan"},
    ]
  },
  creditsCreditEducationalLoan: {
    listData: {
      title: "Education loan",
      description: "Credit for full-time, evening, and correspondence courses in higher, secondary specialized and vocational educational institutions for students admitted to bachelor's and master's degrees.",
      image: educationalLoan_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Loan amount:",
          description: "from 1,000,000 to 60,000,000 soum",
        },
        {
          icon : "icon-calendar-2",
          title: "Loan term:",
          description: "from 1 to 60 months.",
        },
        {
          icon : "icon-discount-shape",
          title: "Interest rate:",
          description: "24",
        },
      ]
    },
    modalData: [
      {title: "Loan amount:", description: "up to 75% of the amount specified in the purchase and sale agreement"},
      {title: "Advance payment:", description: "from 25%"},
      {title: "Credit Guarantee:", description: "Example 1: Before a car purchased on credit is accepted as collateral, an insurance policy against the risk of loan default is provided."},
      {title: "Requirements for borrowers:", description: "An individual who is a resident of the Republic of Uzbekistan and has reached 18 years of age."},
      {title: "Requirements for the car:", description: "Example 1: The service life of the car does not exceed 3 years / up to 4 years<br/>Example 2: Consumer loan for the purchase of cars produced by UzAuto Motors JSC and GM Uzbekistan" },
    ]
  },
  creditsMicroloansOnlineMicroloan: {
    listData: {
      title: "Online Microloan",
      description: "Online microloan is issued online and funds are transferred to the borrower's plastic card.",
      image: onlineMicroloan_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Loan amount:",
          description: "from 1,000,000 to 30,000,000 soum",
        },
        {
          icon : "icon-calendar-2",
          title: "Loan term:",
          description: "from 1 to 36 months.",
        },
        {
          icon : "icon-discount-shape",
          title: "Interest rate:",
          description: "28",
        },
      ]
    },
    modalData: [
      {title: "Loan amount:", description: "up to 75% of the amount specified in the purchase and sale agreement"},
      {title: "Advance payment:", description: "from 25%"},
      {title: "Credit Guarantee:", description: "Example 1: Before a car purchased on credit is accepted as collateral, an insurance policy against the risk of loan default is provided."},
      {title: "Requirements for borrowers:", description: "An individual who is a resident of the Republic of Uzbekistan and has reached 18 years of age."},
      {title: "Requirements for the car:", description: "Example 1: The service life of the car does not exceed 3 years / up to 4 years<br/>Example 2: Consumer loan for the purchase of cars produced by UzAuto Motors JSC and GM Uzbekistan"},
    ]
  },
  creditsMicroloansMicroloanSEPersons: {
    listData: {
      title: "Microloan for self-employed persons",
      description: "Microloans are provided to SELF-EMPLOYED PERSONS in national currency for any purpose, by transferring funds by the bank to the borrower's plastic cards in our bank.",
      image: microloanSEPersons_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Loan amount:",
          description: "from 1,000,000 to 100,000,000 sum",
        },
        {
          icon : "icon-calendar-2",
          title: "Loan term:",
          description: "from 9 to 36 months.",
        },
        {
          icon : "icon-discount-shape",
          title: "Interest rate:",
          description: "29.99",
        },
      ]
    },
    modalData: [
      {title: "Loan amount:", description: "up to 75% of the amount specified in the purchase and sale agreement"},
      {title: "Advance payment:", description: "from 25%"},
      {title: "Credit Guarantee:", description: "Example 1: Before a car purchased on credit is accepted as collateral, an insurance policy against the risk of loan default is provided."},
      {title: "Requirements for borrowers:", description: "An individual who is a resident of the Republic of Uzbekistan and has reached 18 years of age."},
      {title: "Requirements for the car:", description: "Example 1: The service life of the car does not exceed 3 years / up to 4 years<br/>Example 2: Consumer loan for the purchase of cars produced by UzAuto Motors JSC and GM Uzbekistan"},
    ]
  },
  creditsAutoCreditWithoutCollateral: {
    listData: {
      title: "Car loan without collateral",
      description: "Car loan for the purchase of vehicles from the primary and secondary market",
      image: withoutCollateral_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Loan amount:",
          description: "from 1,000,000 to 500,000,000 soum",
        },
        {
          icon : "icon-calendar-2",
          title: "Loan term:",
          description: "from 12 to 60 months.",
        },
        {
          icon : "icon-discount-shape",
          title: "Interest rate:",
          description: "26.9",
        },
      ]
    },
    modalData: [
      {title: "Loan amount:", description: "up to 75% of the amount specified in the purchase and sale agreement"},
      {title: "Advance payment:", description: "from 25%"},
      {title: "Credit Guarantee:", description: "Example 1: Before a car purchased on credit is accepted as collateral, an insurance policy against the risk of loan default is provided."},
      {title: "Requirements for borrowers:", description: "An individual who is a resident of the Republic of Uzbekistan and has reached 18 years of age."},
      {title: "Requirements for the car:", description: "Example 1: The service life of the car does not exceed 3 years / up to 4 years<br/>Example 2: Consumer loan for the purchase of cars produced by UzAuto Motors JSC and GM Uzbekistan"},
    ]
  },
  creditsAutoCreditEcoGreenCar: {
    listData: {
      title: 'Car loan "EcoGreenCar"',
      description: "Car loan for the purchase of electric and hybrid vehicles",
      image: ecoGreenCar_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Loan amount:",
          description: "from 1,000,000 to 500,000,000 soum",
        },
        {
          icon : "icon-calendar-2",
          title: "Loan term:",
          description: "from 12 to 48 months.",
        },
        {
          icon : "icon-discount-shape",
          title: "Interest rate:",
          description: "28",
        },
      ]
    },
    modalData: [
      {title: "Loan amount:", description: "up to 75% of the amount specified in the purchase and sale agreement"},
      {title: "Advance payment:", description: "from 25%"},
      {title: "Credit Guarantee:", description: "Example 1: Before a car purchased on credit is accepted as collateral, an insurance policy against the risk of loan default is provided."},
      {title: "Requirements for borrowers:", description: "An individual who is a resident of the Republic of Uzbekistan and has reached 18 years of age."},
      {title: "Requirements for the car:", description: "Example 1: The service life of the car does not exceed 3 years / up to 4 years<br/>Example 2: Consumer loan for the purchase of cars produced by UzAuto Motors JSC and GM Uzbekistan"},
    ]
  },
  creditsAutoCreditNational: {
    listData: {
      title: 'National',
      description: "For the purchase of cars produced by UzAuto Motors on the primary and secondary markets",
      image: national_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Loan amount:",
          description: "from 1,000,000 to 500,000,000 soum",
        },
        {
          icon : "icon-calendar-2",
          title: "Loan term:",
          description: "from 12 to 60 months.",
        },
        {
          icon : "icon-discount-shape",
          title: "Interest rate:",
          description: "23.5",
        },
      ]
    },
    modalData: [
      {title: "Loan amount:", description: "up to 75% of the amount specified in the purchase and sale agreement"},
      {title: "Advance payment:", description: "from 25%"},
      {title: "Credit Guarantee:", description: "Example 1: Before a car purchased on credit is accepted as collateral, an insurance policy against the risk of loan default is provided."},
      {title: "Requirements for borrowers:", description: "An individual who is a resident of the Republic of Uzbekistan and has reached 18 years of age."},
      {title: "Requirements for the car:", description: "Example 1: The service life of the car does not exceed 3 years / up to 4 years<br/>Example 2: Consumer loan for the purchase of cars produced by UzAuto Motors JSC and GM Uzbekistan"},
    ]
  },
  creditsMortgageNewHousing: {
    listData: {
      title: 'Mortgage for new housing',
      description: "Loan for the purchase of apartments in residential complexes under construction.",
      image: newHousing_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Loan amount:",
          description: "from 1,000,000 to 820,000,000 soum",
        },
        {
          icon : "icon-calendar-2",
          title: "Loan term:",
          description: "from 1 to 15 years",
        },
        {
          icon : "icon-receipt-discount",
          title: "Down payment:",
          description: "25%",
        },
        {
          icon : "icon-discount-shape",
          title: "Interest rate:",
          description: "24.9%",
        },
      ]
    },
    modalData: [
      {title: "Loan amount:", description: "up to 75% of the amount specified in the purchase and sale agreement"},
      {title: "Advance payment:", description: "from 25%"},
      {title: "Credit Guarantee:", description: "Example 1: Before a car purchased on credit is accepted as collateral, an insurance policy against the risk of loan default is provided."},
      {title: "Requirements for borrowers:", description: "An individual who is a resident of the Republic of Uzbekistan and has reached 18 years of age."},
      {title: "Requirements for the car:", description: "Example 1: The service life of the car does not exceed 3 years / up to 4 years<br/>Example 2: Consumer loan for the purchase of cars produced by UzAuto Motors JSC and GM Uzbekistan"},
    ]
  },
  creditsMortgageSecondaryHousing: {
    listData: {
      title: 'Mortgage for secondary housing',
      description: "Purchase of a finished residential building or apartment in a residential apartment building.",
      image: secondaryHousing_full,
      advantages: [
        {
          icon : "icon-moneys",
          title: "Loan amount:",
          description: "from 1,000,000 to 820,000,000 soum",
        },
        {
          icon : "icon-calendar-2",
          title: "Loan term:",
          description: "from 1 to 10 years",
        },
        {
          icon : "icon-receipt-discount",
          title: "Down payment:",
          description: "25%",
        },
        {
          icon : "icon-discount-shape",
          title: "Interest rate:",
          description: "23.9%",
        },
      ]
    },
    modalData: [
      {title: "Loan amount:", description: "up to 75% of the amount specified in the purchase and sale agreement"},
      {title: "Advance payment:", description: "from 25%"},
      {title: "Credit Guarantee:", description: "Example 1: Before a car purchased on credit is accepted as collateral, an insurance policy against the risk of loan default is provided."},
      {title: "Requirements for borrowers:", description: "An individual who is a resident of the Republic of Uzbekistan and has reached 18 years of age."},
      {title: "Requirements for the car:", description: "Example 1: The service life of the car does not exceed 3 years / up to 4 years<br/>Example 2: Consumer loan for the purchase of cars produced by UzAuto Motors JSC and GM Uzbekistan"},
    ]
  },
}

const demo = () => {
  new Server({
    routes() {
      this.namespace = "api";

      this.get("/isAutorised", () => {
        return {isAutorised : false};
      });



      this.get("/getCreditData", (schema, request) => {

        const data = request?.queryParams?.lang === "ru-RU" ? dataRus : dataEng

        switch (request?.queryParams?.type) {
          case "cards_credit":
            return data.cardsList
          case "cards_debit":
            return data.debitCardsList
          case "credits_creditOffers":
            return data.creditOffersList
          case "credits_microloans":
            return data.microloansList
          case "credits_cards":
            return data.creditCardsList
          case "credits_autoCredit":
            return data.autoCreditList
          case "credits_mortgage":
            return data.mortgageList
          default: return data.creditCardsList
        }
      });

      this.get("/getCreditDetails", (schema, request) => {

        const data = request?.queryParams?.lang === "ru-RU" ? dataRus : dataEng

        switch (request?.queryParams?.type) {
          case "cards_credit_creditCard40":
          case "credits_cards_creditCard40":
            return data.cardsCreditCreditCard40
          case "cards_credit_installmentCard":
          case "credits_cards_installmentCard":
            return data.cardsCreditInstallmentCard
          case "cards_debit_uzCard":
            return data.cardsDebitUzCard
          case "cards_debit_mastercardWorld":
            return data.cardsDebitMastercardWorld
          case "cards_debit_humo":
            return data.cardsDebitHumo
          case "credits_creditOffers_cashLoan":
            return data.creditsCreditOffersCashLoan
          case "credits_creditOffers_nationalGreen":
            return data.creditsCreditNationalGreen
          case "credits_creditOffers_educationalLoan":
            return data.creditsCreditEducationalLoan
          case "credits_microloans_onlineMicroloan":
            return data.creditsMicroloansOnlineMicroloan
          case "credits_microloans_microloanSEPersons":
            return data.creditsMicroloansMicroloanSEPersons
          case "credits_autoCredit_withoutCollateral":
            return data.creditsAutoCreditWithoutCollateral
          case "credits_autoCredit_EcoGreenCar":
            return data.creditsAutoCreditEcoGreenCar
          case "credits_autoCredit_national":
            return data.creditsAutoCreditNational
          case "credits_mortgage_newHousing":
            return data.creditsMortgageNewHousing
          case "credits_mortgage_secondaryHousing":
            return data.creditsMortgageSecondaryHousing
          default: return data.cardsCreditCreditCard40
        }
      });
    }
  });

}

export default demo