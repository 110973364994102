import {FormattedMessage} from "react-intl";
import img1 from "../images/demoImage/logo1.png";
import img2 from "../images/demoImage/logo2.png";
import img3 from "../images/demoImage/logo3.png";
import img4 from "../images/demoImage/logo4.png";
import uuid from "../services/uuid";

const constants = {
  notAutorized: {
    creditList: {
      title:<FormattedMessage id="titleCreditType" />,
      back:"/",
      notification: 2,
      menuList: [
        {
          title: <FormattedMessage id="titleCredit" />,
          description: "предложения по кредитам",
          icon: "icon-moneys",
          link: "creditOffers"
        },
        {
          title: "Микрозайм",
          description: "предложения по микрозаймам",
          icon: "icon-coin2",
          link: "microloans"
        },
        {
          title: "Кредитная карта",
          description: "предложения по кредитным картам",
          icon: "icon-card",
          link: "cards"
        },
        {
          title: "Автокредит",
          description: "предложения по автокредитованию",
          icon: "icon-bus",
          link: "autoCredit"
        },
        {
          title: "Ипотека",
          description: "предложения по ипотеке",
          icon: "icon-home-hashtag",
          link: "mortgage"
        },
      ]
    },
    cardList: {
      title:<FormattedMessage id="titleCardType" />,
      back:"/",
      notification: 2,
      menuList: [
        {
          title: "Кредитные карты",
          description: "предложения по кредитным картам",
          icon: "icon-card",
          link: "credit"
        },
        {
          title: "Дебетовые карты",
          description: "предложения по кредитным картам",
          icon: "icon-card",
          link: "debit"
        },
      ],
    },
    cardOrder: {
      title: <FormattedMessage id="titleOrderCard" />,
      back:"/cards",
      notification: 2,
      menuList: [
        {
          title: "Кредитные карты",
          description: "предложения по кредитным картам",
          icon: "icon-card",
          link: "credit"
        },
        {
          title: "Дебетовые карты",
          description: "предложения по кредитным картам",
          icon: "icon-card",
          link: "debit"
        },
      ]
    },
    transfersList: {
      title:<FormattedMessage id="titleTransferType" />,
      back:"/",
      notification: 2,
      menuList: [
        {
          title: "QR  перевод",
          description: "сканер QR-кода",
          icon: "icon-scan",
          link: "/signIn"
        },
        {
          title: "Между своими счетами",
          description: "картами, депозитами",
          icon: "icon-convert-card",
          link: "/signIn"
        },
        {
          title: "По номеру телефона",
          description: "клиенту банков",
          icon: "icon-call",
          link: "/signIn"
        },
        {
          title: "На карту любого банка",
          description: "весь мир",
          icon: "icon-card-pos",
          link: "/signIn"
        },
        {
          title: "Международные переводы",
          description: "между странами",
          icon: "icon-bank",
          link: "/signIn"
        },
        {
          title: "Онлайн конверсия",
          description: "между счетами",
          icon: "icon-bitcoin-convert",
          link: "/signIn"
        },
        {
          title: "По реквизитам",
          description: "на номер счета",
          icon: "icon-strongbox",
          link: "/signIn"
        },
      ]
    },
    creditItem: {
      title:<FormattedMessage id="titleCreditType" />,
      back:"/credits",
      notification: 2,
      menuList: [
        {
          title: "Автокредит без залога",
          description: "Получите кредит от 10 до 200 миллионов сум с гибкими условиями от 6 до 60 месяцев по ставке 18% годовых.",
          image: "iconCards",
        },
        {
          title: "Развитие Бизнеса",
          description: "Получите кредит от 10 до 200 миллионов сум с гибкими условиями от 6 до 60 месяцев по ставке 18% годовых.",
          image: "iconPayment",
        },
      ]
    },
    creditDetail: {
      back:"credits",
      link:"order",
      notification: 2,
      menuList: [
        {
          title: "Автокредит без залога",
          description: "Получите кредит от 10 до 200 миллионов сум с гибкими условиями от 6 до 60 месяцев по ставке 18% годовых.",
          image: "iconCards",
        },
        {
          title: "Развитие Бизнеса",
          description: "Получите кредит от 10 до 200 миллионов сум с гибкими условиями от 6 до 60 месяцев по ставке 18% годовых.",
          image: "iconPayment",
        },
      ]
    },
    cardDetail: {
      back:"cards",
      link:"order",
      notification: 2,
      menuList: [
        {
          title: "Автокредит без залога",
          description: "Получите кредит от 10 до 200 миллионов сум с гибкими условиями от 6 до 60 месяцев по ставке 18% годовых.",
          image: "iconCards",
        },
        {
          title: "Развитие Бизнеса",
          description: "Получите кредит от 10 до 200 миллионов сум с гибкими условиями от 6 до 60 месяцев по ставке 18% годовых.",
          image: "iconPayment",
        },
      ]
    },
    cardItem: {
      title:<FormattedMessage id="titleCardType" />,
      back:"/cards",
      notification: 2,
      menuList: [
        {
          title: "Автокредит без залога",
          description: "Получите кредит от 10 до 200 миллионов сум с гибкими условиями от 6 до 60 месяцев по ставке 18% годовых.",
          image: "iconCards",
        },
        {
          title: "Развитие Бизнеса",
          description: "Получите кредит от 10 до 200 миллионов сум с гибкими условиями от 6 до 60 месяцев по ставке 18% годовых.",
          image: "iconPayment",
        },
      ]
    },
    cardHistory: {
      title:<FormattedMessage id="titleHistoryOperations" />,
      back:"/myBank/card",
      notification: 2,
      historyList: [
        {
          date: "7 января",
          operations: [
            {
              icon: "icon-bag",
              label: "Dana Shop",
              description: "Бакалейные магазины, супермаркеты",
              value: "-20 295.9 UZS",
              status: 0,
              score: "+0б.",
            },
            {
              icon: "icon-wallet-money",
              label: "Анастасия Т.",
              description: "Пополнения",
              value: "+200 000.0 UZS",
              status: 1,
              score: "+100б.",
            },
            {
              icon: "icon-percentage-square",
              label: "Выплата кредита",
              description: "Займы, Кредиты, Ипотеки",
              value: "-20 295.9 UZS",
              status: 2,
              score: "+0б.",
            },
            {
              icon: "icon-shop",
              label: "Wolt",
              description: "Кафе и рестораны",
              value: "-20 295.9 UZS",
              status: 0,
              score: "+0б.",
            },
          ],
        },
        {
          date: "6 января",
          operations: [
            {
              icon: "icon-bag",
              label: "Dana Shop",
              description: "Бакалейные магазины, супермаркеты",
              value: "-20 295.9 UZS",
              status: 0,
              score: "+0б.",
            },
            {
              icon: "icon-bus",
              label: "Яндекс такси",
              description: "Такси, Общественный транспорт",
              value: "-20 295.9 UZS",
              status: 0,
              score: "0б.",
            },
            {
              icon: "icon-gas-station",
              label: "Helios",
              description: "Заправочные станции",
              value: "-20 295.9 UZS",
              status: 0,
              score: "+0б.",
            },
          ],
        },
      ],
    },
    creditHistory: {
      title:<FormattedMessage id="titleHistoryOperations" />,
      back:"/myBank/credit",
      notification: 2,
      historyList: [
        {
          date: "7 января",
          operations: [
            {
              icon: "icon-bag",
              label: "Dana Shop",
              description: "Бакалейные магазины, супермаркеты",
              value: "-20 295.9 UZS",
              status: 0,
              score: "+0б.",
            },
            {
              icon: "icon-wallet-money",
              label: "Анастасия Т.",
              description: "Пополнения",
              value: "+200 000.0 UZS",
              status: 1,
              score: "+100б.",
            },
            {
              icon: "icon-percentage-square",
              label: "Выплата кредита",
              description: "Займы, Кредиты, Ипотеки",
              value: "-20 295.9 UZS",
              status: 2,
              score: "+0б.",
            },
            {
              icon: "icon-shop",
              label: "Wolt",
              description: "Кафе и рестораны",
              value: "-20 295.9 UZS",
              status: 0,
              score: "+0б.",
            },
          ],
        },
        {
          date: "6 января",
          operations: [
            {
              icon: "icon-bag",
              label: "Dana Shop",
              description: "Бакалейные магазины, супермаркеты",
              value: "-20 295.9 UZS",
              status: 0,
              score: "+0б.",
            },
            {
              icon: "icon-bus",
              label: "Яндекс такси",
              description: "Такси, Общественный транспорт",
              value: "-20 295.9 UZS",
              status: 0,
              score: "0б.",
            },
            {
              icon: "icon-gas-station",
              label: "Helios",
              description: "Заправочные станции",
              value: "-20 295.9 UZS",
              status: 0,
              score: "+0б.",
            },
          ],
        },
      ],
    },
    accountHistory: {
      title:<FormattedMessage id="titleHistoryOperations" />,
      back:"/myBank/account",
      notification: 2,
      historyList: [
        {
          date: "7 января",
          operations: [
            {
              icon: "icon-bag",
              label: "Dana Shop",
              description: "Бакалейные магазины, супермаркеты",
              value: "-20 295.9 UZS",
              status: 0,
              score: "+0б.",
            },
            {
              icon: "icon-wallet-money",
              label: "Анастасия Т.",
              description: "Пополнения",
              value: "+200 000.0 UZS",
              status: 1,
              score: "+100б.",
            },
            {
              icon: "icon-percentage-square",
              label: "Выплата кредита",
              description: "Займы, Кредиты, Ипотеки",
              value: "-20 295.9 UZS",
              status: 2,
              score: "+0б.",
            },
            {
              icon: "icon-shop",
              label: "Wolt",
              description: "Кафе и рестораны",
              value: "-20 295.9 UZS",
              status: 0,
              score: "+0б.",
            },
          ],
        },
        {
          date: "6 января",
          operations: [
            {
              icon: "icon-bag",
              label: "Dana Shop",
              description: "Бакалейные магазины, супермаркеты",
              value: "-20 295.9 UZS",
              status: 0,
              score: "+0б.",
            },
            {
              icon: "icon-bus",
              label: "Яндекс такси",
              description: "Такси, Общественный транспорт",
              value: "-20 295.9 UZS",
              status: 0,
              score: "0б.",
            },
            {
              icon: "icon-gas-station",
              label: "Helios",
              description: "Заправочные станции",
              value: "-20 295.9 UZS",
              status: 0,
              score: "+0б.",
            },
          ],
        },
      ],
    },
    paymentsList: {
      title: <FormattedMessage id="settingsListPayments" />,
      service: [
        {
          title: "Интернет",
          icon: "icon-wifi",
          link: "/signIn",
        },
        {
          title: "Моб. связь",
          icon: "icon-call",
          link: "/signIn",
        },
        {
          title: "ТВ",
          icon: "icon-video-horizontal",
          link: "/signIn",
        },
        {
          title: "Комм.услуги",
          icon: "icon-home-hashtag",
          link: "/signIn",
        },
        {
          title: "Бюджетные платежи",
          icon: "icon-bank",
          link: "/signIn",
        },
        {
          title: "Все платежи",
          icon: "icon-archive",
          link: "/signIn",
        },
      ],
      favoriteData: [
        {
          title: "RentaNet",
          description: "Моб. связь",
          link: "/signIn",
          icon: img1,
        },
      ],
      providersData: [
        {
          title: "RentaNet",
          description: "оплата за интернет",
          link: "/signIn",
          icon: img1,
        },
        {
          title: "RentaNet",
          description: "оплата за интернет",
          link: "/signIn",
          icon: img2,
        },
        {
          title: "RentaNet",
          description: "оплата за интернет",
          link: "/signIn",
          icon: img3,
        },
        {
          title: "RentaNet",
          description: "оплата за интернет",
          link: "/signIn",
          icon: img4,
        },
      ],
      paymentItems: [
        {
          blocked: false,
          card: {
            type: 1,
            number: '• 7811',
          },
          balance: '182.87 UZS',
          label: 'Карта VISA',
          description: 'RUB, UZS',
          value: '182.87 UZS',
        },
        {
          blocked: false,
          card: {
            type: 2,
            number: '• 7811',
          },
          balance: '247 145.87 UZS',
          label: 'Связанная карта',
          description: 'USD, RUB, UZS',
          value: '190 182.22 UZS',
        },
        {
          blocked: false,
          card: {
            type: 3,
            number: '• 7846',
          },
          balance: '46 718.22 UZS',
          label: 'Связанная карта',
          description: 'USD, RUB',
          value: '194.22 UZS',
        },
      ]
    },
  },
  autorized: {
    transfersList: {
      title:<FormattedMessage id="titleTransferType" />,
      back:"/",
      notification: 2,
      menuList: [
        {
          title: "QR перевод",
          description: "сканер QR-кода",
          icon: "icon-scan",
          link: "qrTransfer"
        },
        {
          title: "Между своими счетами",
          description: "картами, депозитами",
          icon: "icon-convert-card",
          link: "betweenAccounts"
        },
        {
          title: "По номеру телефона",
          description: "клиенту банков",
          icon: "icon-call",
          link: "phoneNumber"
        },
        {
          title: "На карту любого банка",
          description: "весь мир",
          icon: "icon-card-pos",
          link: "card2card"
        },
        {
          title: "Международные переводы",
          description: "между странами",
          icon: "icon-bank",
          link: "swift"
        },
        {
          title: "Онлайн конверсия",
          description: "между счетами",
          icon: "icon-bitcoin-convert",
          link: "conversion"
        },
        {
          title: "По реквизитам",
          description: "на номер счета",
          icon: "icon-strongbox",
          link: "account"
        },
      ]
    },
    paymentsList: {
      title: <FormattedMessage id="settingsListPayments" />,
      service: [
        {
          title: "Интернет",
          icon: "icon-wifi",
          link: "internet",
        },
        {
          title: "Моб. связь",
          icon: "icon-call",
          link: "cellular",
        },
        {
          title: "ТВ",
          icon: "icon-video-horizontal",
          link: "tv",
        },
        {
          title: "Комм.услуги",
          icon: "icon-home-hashtag",
          link: "publicFacilities",
        },
        {
          title: "Бюджетные платежи",
          icon: "icon-bank",
          link: "budgetPayments",
        },
        {
          title: "Все платежи",
          icon: "icon-archive",
          link: "allPayments",
        },
      ],
      favoriteData: [
        {
          title: "RentaNet",
          description: "Моб. связь",
          link: "internet/RentaNet",
          icon: img1,
        },
      ],
      providersData: [
        {
          title: "RentaNet",
          description: "оплата за интернет",
          link: "internet/RentaNet",
          icon: img1,
        },
        {
          title: "RentaNet",
          description: "оплата за интернет",
          link: "internet/RentaNet",
          icon: img2,
        },
        {
          title: "RentaNet",
          description: "оплата за интернет",
          link: "internet/RentaNet",
          icon: img3,
        },
        {
          title: "RentaNet",
          description: "оплата за интернет",
          link: "internet/RentaNet",
          icon: img4,
        },
      ],
      paymentItems: [
        {
          blocked: false,
          card: {
            type: 1,
            number: '• 7811',
          },
          balance: '182.87 UZS',
          label: 'Моя карта VISA',
          description: 'RUB, UZS',
          value: '182.87 UZS',
        },
        {
          blocked: false,
          card: {
            type: 2,
            number: '• 7811',
          },
          balance: '247 145.87 UZS',
          label: 'Связанная карта',
          description: 'USD, RUB, UZS',
          value: '190 182.22 UZS',
        },
        {
          blocked: false,
          card: {
            type: 3,
            number: '• 7846',
          },
          balance: '46 718.22 UZS',
          label: 'Связанная карта',
          description: 'USD, RUB',
          value: '194.22 UZS',
        },
      ]
    },
    myBank: {
      cardItems: [
        {
          label: <FormattedMessage id="settingsListCards" />,
          children: [
            {
              card: {
                type: 2,
                number: "• 5576"
              },
              label: "MasterCard",
              description: "USD, RUB, UZS",
              value: "190 182.22 UZS",
              cashback: "563.1 б.",
              link: `card/${uuid()}`,
            },
            {
              card: {
                type: 1,
                number: "• 4320"
              },
              label: "Моя карта Visa",
              description: "RUB, UZS",
              value: "182.22 UZS",
              link: `card/${uuid()}`,
            },
            {
              card: {
                type: 2,
                number: "• 0243"
              },
              label: "MasterCard",
              description: "UZS",
              value: "1 182.22 UZS",
              cashback: "563.1 б.",
              link: `card/${uuid()}`,
            },
          ],
        },
        {
          label: <FormattedMessage id="titleCreditCards" />,
          children: [
            {
              card: {
                type: 2,
                number: "• 7811"
              },
              label: "MasterCard",
              description: "USD, RUB, UZS",
              value: "-2 082.22 UZS",
              payment: "100 000 UZS",
              link: `card/${uuid()}`,
            },
          ],
        },
        {
          label: <FormattedMessage id="settingsListCredits" />,
          children: [
            {
              icon: "icon-percentage-square",
              label: <FormattedMessage id="labelCarPurchasing" />,
              description: <><FormattedMessage id="labelCarPurchasing" /> 22.02.2024</>,
              value: "-2 082.22 UZS",
              debt: "33 982 UZS",
              link: `credit/${uuid()}`,
            },

          ],
        },
        {
          label: <FormattedMessage id="titleAccounts" />,
          children: [
            {
              icon: "icon-strongbox",
              label: <FormattedMessage id="labelCurrentAccount" />,
              description: "UZ91 •••2948",
              value: "54 295.9 UZS",
              link: `account/${uuid()}`,
            },
            {
              icon: "icon-strongbox-2",
              label: <FormattedMessage id="labelDepositAccount" />,
              description: "UZ91 •••8473",
              value: "54 295.9 UZS",
              link: `account/${uuid()}`,
            },
          ],
        },
      ],
      reasonList: [
        {
          value:1,
          label: "Карта повреждена  не работает"
        }
      ],
      deliveryMethodList: [
        {label: "Заберу в отделении", value: 1},
        {label: "Доставка курьером", value: 2}
      ],
      departmentList: [
        {label: "Отделение 1", value: 1},
        {label: "Отделение 2", value: 2}
      ],
      cityList: [
        {label: "Москва", value: 1}
      ],
      streetList: [
        {label: "Малая Никитская", value: 1}
      ],
      periodTypeList: [
        {value: 1, label: "На время"},
        {value: 2, label: "Навсегда"},
        {value: 3, label: "Навсегда и заказать новую"},
      ],
    },
    creditInfo: {
      title: "Приобретение автомобиля",
      infoCreditFormData: {
        title: <FormattedMessage id="titleAboutLoan"/> ,
        infoList: [
          {
            title: "Название:",
            text: "Приобретение автомобиля"
          },
          {
            title: "Договор:",
            text: "#237468456"
          },
          {
            title: "Дата получения:",
            text: "20 февраля 2024"
          },
          {
            title: "Срок кредита:",
            text: "36 месяцев"
          },
          {
            title: "Сумма кредита:",
            text: "10 000 000 UZS"
          },
          {
            title: "Всего внесено:",
            text: "8 645 000 UZS"
          },
          {
            title: "Процентная ставка:",
            text: "20% годовых"
          },
          {
            title: "Связанный счет:",
            text: "UZ91 •••• •••• 8473"
          },
        ]
      }
    },
    notification: {
      notificationList: [
        {
          date: "20.05.2024",
          message: [
            {
              icon: "icon-send-2",
              title: "Ваше мнение - это важно!",
              text: [
                "Оцените работу нашего консультанта.",
                "<a href=''>Подробнее</a>"
              ]
            }
          ]
        },
        {
          date: "14.05.2024",
          message: [
            {
              icon: "icon-password-check",
              title: "Одноразовые пароли",
              text: [
                "Никому не сообщайте код: <b>11122&</b><br/>Списание 1000 UZS в Internet-shop."
              ]
            },
            {
              icon: "icon-strongbox-2",
              title: "Снятие с депозита",
              text: [
                "Снятие с депозита UZ•7473<br/> на сумму: <b>183 000 UZS</b>",
                "Доступно: <b>465 000 000 UZS</b>"
              ]
            },
          ]
        },
        {
          date: "10.05.2024",
          message: [
            {
              icon: "icon-flash2",
              title: "2$ за перевод на зарубежные карты!",
              text: [
                "Совершайте мгновенные переводы по самым низким тарифам на зарубежные карты."
              ]
            },
          ]
        },
      ]
    },
  }
}

export default constants