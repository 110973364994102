import {AUTHORIZATION, ISAUTHORIED, FIELDCHANGE, BACKLINK, CHANGELANGUAGE} from "../actions/actionTypes";
import {LOCALES} from "../../i18n/locales";
import dayjs from 'dayjs'

const initialState = {
  /*login: "",
  password: "",*/
  form: {
    password: "",
    phone: "",
    IIN:""
  },
  locale: LOCALES.RUSSIAN,
  loading: false,
  isAutorised: false,
  signIn: false,
  errors: null,
  backLink: "/"
}

export default function authorization(state = initialState, action) {
    switch (action.type) {
      case ISAUTHORIED:
        return {
          ...state,
          isAutorised: !state.isAutorised,
        }
      case AUTHORIZATION:
        return {
          ...state,
          signIn: !state.signIn,
        }
      case FIELDCHANGE:
        const {name, value, filter} = action.payload
        return {
          ...state,
          form: {
            ...state.form,
            [name]: filter ? value.replace(filter, "") : value
          }
        }
      case BACKLINK:
        const {backLink} = action.payload
        return {
          ...state,
          backLink
        }
      case CHANGELANGUAGE:
        const locales = {
          ru: () => import('dayjs/locale/ru'),
          en: () => import('dayjs/locale/en'),
        }

        function loadLocale (language) {
          locales[language]().then(() => dayjs.locale(language))
        }

        loadLocale(action.payload.locale.split("-")[0])

        return {
          ...state,
          locale: action.payload.locale
        }
      default:
        return state
    }
  }