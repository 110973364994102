import React, {Component} from 'react';
import {connect} from 'react-redux';
import Header from "../../../../Header";
import BottomMenu from "../../../../ui/menu/BottomMenu";
import ServiceList from "../../../../ui/infoBlocks/ServiceList";
import CategoryList from "../../../../ui/infoBlocks/CategoryList";
import Wrapper from "../../../../ui/wrapper";
import classes from "./credits.module.scss"
import withRouter from "../../../../../hoc/withRouter";
import Services from "../../../../../services/Services";
import sources from "../../../../../source/sources";
import {Spin} from "antd";
import {setBackLink} from "../../../../../store/actions/authorization";
import {FormattedMessage} from "react-intl";


class Credits extends Component {

  state = {
    loading: true,
    data: []
  }

  type = () => {
    const {location: {pathname}} = this.props
    const cat = pathname.split("/").filter(el => el !== "")
    return cat.join("_")
  }

  componentDidMount() {
    const {location: {pathname}, setBackLink, locale} = this.props
    sources.getCreditData(this.type(), locale)
      .then(({data}) => {
        console.log("DATA", data)
        this.setState({
          data,
          loading: false,
        })
      }).catch(error => {
      Services.redirectService(error)
    })

    setBackLink("/" + pathname.split("/")[1])
  }

  render() {
    const {
      location: {pathname},
      params:{id},
      config: {back, title, menuList, notification},
      signIn
    } = this.props

    const parseTitle = () => {

      switch (id) {
        case "credit":
          return <FormattedMessage id="titleCreditCards" />
        case "debit":
          return <FormattedMessage id="titleDebitCards" />
        case "microloans":
          return <FormattedMessage id="titleMicroloans" />
        case "creditOffers":
          return <FormattedMessage id="settingsListCredits" />
        case "cards":
          return <FormattedMessage id="titleCreditCards" />
        case "autoCredit":
          return <FormattedMessage id="titleAutoCredits" />
        case "mortgage":
          return <FormattedMessage id="titleMortgage" />
        default: return ""
      }
    }
    const parseCategory = () => {
      const cat = pathname.split("/")[2]
      switch (cat) {
        case "cards":
          return <FormattedMessage id="titleCards" />
        default: return false
      }
    }

    return (
      <div className={classes.wrapper}>
        <Header
          back={back}
          title={parseTitle()}
          notification={ signIn ? notification : false}
          empty={!signIn}
        />
        {
          this.state.loading ? <Spin fullscreen={true}/> :
            <Wrapper transparent nopadding>
              <CategoryList
                data={this.state.data.map(el => {
                  return {...el, link: pathname + '/' + el.link }
                })}
                nomargin style={{padding:8}}/>
            </Wrapper>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    signIn: state.authorization.signIn,
    locale: state.authorization.locale,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setBackLink: (backLink) => dispatch(setBackLink({backLink})),
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Credits));