import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Col, List, Row} from 'antd';
import classes from './accountInfo.module.scss';
import Header from '../../../../Header';
import Icon from '../../../../ui/Icon';
import Wrapper from '../../../../ui/wrapper';
import ServiceList from '../../../../ui/infoBlocks/ServiceList';
import ListMenu from '../../../../ui/menu/ListMenu';
import BottomMenu from '../../../../ui/menu/BottomMenu';
import CardListItem from '../../../../ui/infoBlocks/CardListItem';
import LanguageModal from "../../../../ui/modal/LanguageModal";
import ContentModal from "../../../../ui/modal/ContentModal/ContentModal";
import PrimaryButton from "../../../../ui/buttons/PrimaryButton";
import {setBackLink} from "../../../../../store/actions/authorization";
import withRouter from "../../../../../hoc/withRouter";
import {FormattedMessage} from "react-intl";

class AccountInfo extends Component {
  state = {
    closeAccount: false,
    accountInfo: false,
  }

  componentDidMount() {
    const {setBackLink, location: {pathname}, params:{id, link}, config} =this.props
    setBackLink(`/${pathname.split("/")[1]}`)
  }

  render() {

    const showModal = (state) => {

      const prevState = this.state[state]
      this.setState({
        [state]: !prevState
      });
    }

    const src = [
      {
        text:  <FormattedMessage id="titleAccountInformation" />,
        icon: 'icon-document',
        action: () => showModal("accountInfo")
      },
      {
        text: <FormattedMessage id="titleCloseAccount" />,
        icon: 'icon-wallet-remove',
        action: () => showModal("closeAccount"),
      },
    ];

    const menuList = [
      {
        title: <FormattedMessage id="titleRequisites" />,
        description: <FormattedMessage id="titleCardDetails" />,
        icon: 'icon-clipboard-text',
        link: 'requisites',
      },
      {
        title: <FormattedMessage id="titleExtract" />,
        description: <FormattedMessage id="titleHistoryOperations" />,
        icon: 'icon-receipt-search',
        link: 'history',
      },
    ];

    const accountData = {
      blocked: false,
      card: {
        type: 2,
        number: '• 7811',
      },
      balance: '247 145.87 UZS',
      label: <FormattedMessage id="titleLinkedAccount" />,
      description: 'USD, RUB, UZS',
      value: '190 182.22 UZS',
    };

    const closeAccountFormData = {
      title: <FormattedMessage id="titleClosingAccount" />,
      text: <FormattedMessage id="textContactSupportForClose" values={{val: 45}} />,
      action: {
        title: <FormattedMessage id="buttonGoToSupportChat" />,
        event: () => showModal("closeAccount")
      }
    }

    const infoAccountFormData = {
      title: <FormattedMessage id="titleAccountInformation" />,
      infoList: [
        {
          title: <><FormattedMessage id="labelCurrentAccount" />:</>,
          text: <FormattedMessage id="textAccountForBasicTransactions" />,
        },
        {
          title: <><FormattedMessage id="textCommission" />:</>,
          text:  <FormattedMessage id="textAccountWithoutCommission" />,
        },
        {
          title: <><FormattedMessage id="titleCashWithdrawal" />:</>,
          text: <FormattedMessage id="textBanksPartnersWithoutCommission" />,
        },
        {
          title: <><FormattedMessage id="bottomMenuTransfers" />:</>,
          text: <FormattedMessage id="textTransfersWithinLimits" />,
        },
      ],
      action: {
        title:  <FormattedMessage id="titleDetailedAccountTerms" />,
        event: () => showModal("accountInfo")
      }
    }

    const closeAccountForm = (data) => {
      return <div className={classes.modalPadding}>
        <Row gutter={[16,16]}>
          <Col span={24}>
            <h3>{data?.title}</h3>
            {
              data?.text &&
              <p>{data?.text}</p>
            }
            {
              data?.infoList && <List
                bordered={false}
                dataSource={data.infoList}
                renderItem={(item, index) => (
                  <List.Item>
                    <div className={classes.left}>
                      <h4>{item.title}</h4>
                      <p>{item.text}</p>
                    </div>
                  </List.Item>
                )}
              />
            }
          </Col>
          <Col span={24}>
            <Row gutter={[16,16]}>
              <Col span={24}>
                <PrimaryButton title={data.action.title} onClick={data.action?.event} small/>
              </Col>
              <Col span={24}>
                <PrimaryButton bordered onClick={() => data.action.event()} title={<FormattedMessage id="buttonBack" />} small/>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    }

    return (
      <div className={classes.wrapper}>
        <Header
          transparent
          back="/myBank"
          title={<FormattedMessage id="labelCurrentAccount" />}
          showInfo={() => {}}
        />
        <Wrapper transparent>
          <div className={classes.title}>
            <label>
              <FormattedMessage id="labelAccountBalance" />:
            </label>
            <div>{accountData.balance}</div>
          </div>
        </Wrapper>
        <Wrapper nopadding transparent style={{ marginBottom: 24 }}>
          <ServiceList data={menuList} nopadding nomargin size={22} borderIcon />
        </Wrapper>
        {
          accountData.blocked
          && (
          <span className={classes.block}>
            <Icon name="icon-lock" />
            {' '}
            <FormattedMessage id="titleCardBlocked" />
          </span>
          )
        }
        <Wrapper>
          <CardListItem data={accountData} />
        </Wrapper>

        <Wrapper nopadding>
          <ListMenu borderIcon data={src} />
        </Wrapper>
        <ContentModal
          name="closeAccount"
          open={this.state.closeAccount}
          showModal={showModal}
          data={closeAccountForm(closeAccountFormData)}
        />
        <ContentModal
          name="accountInfo"
          open={this.state.accountInfo}
          showModal={showModal}
          data={closeAccountForm(infoAccountFormData)}
        />
        {/*<BottomMenu />*/}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setBackLink: (backLink) => dispatch(setBackLink({backLink})),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountInfo));
