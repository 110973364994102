import React from 'react';
import {Input} from "antd";
import classes from "./search.module.scss";
import Icon from "../../Icon";
import {useIntl} from "react-intl";

const Search = (props) => {
  const intl = useIntl()

  return (
    <>
      <Input
        className={classes.input}
        prefix={<Icon name="icon-search-normal2"/>}
        {...props}
        placeholder={props.locale ? intl.formatMessage({id: props.locale}): props.placeholder ? props.placeholder : ""}
      />
    </>
  );
};

export default Search;