import React, {Component} from 'react';
import {connect} from 'react-redux';
import classes from "./myBank.module.scss"
import {Col, Collapse, List, Row} from "antd";
import Wrapper from "../../../ui/wrapper";
import Header from "../../../Header";
import BottomMenu from "../../../ui/menu/BottomMenu";
import CardListItem from "../../../ui/infoBlocks/CardListItem";
import uuid from "../../../../services/uuid";
import withRouter from "../../../../hoc/withRouter";
import {setBackLink} from "../../../../store/actions/authorization";



class MyBank extends Component {

  componentDidMount() {
    const {setBackLink, config} = this.props
    setBackLink(`/`)
  }

  render() {
    const items = [
      {
        label: 'Карты',
        children: [
          {
            card: {
              type: 2,
              number: "• 5576"
            },
            label: "MasterCard",
            description: "USD, RUB, UZS",
            value: "190 182.22 UZS",
            cashback: "563.1 б.",
            link: `card/${uuid()}`,
          },
          {
            card: {
              type: 1,
              number: "• 4320"
            },
            label: "Моя карта Visa",
            description: "RUB, UZS",
            value: "182.22 UZS",
            link: `card/${uuid()}`,
          },
          {
            card: {
              type: 2,
              number: "• 0243"
            },
            label: "MasterCard",
            description: "UZS",
            value: "1 182.22 UZS",
            cashback: "563.1 б.",
            link: `card/${uuid()}`,
          },
        ],
      },
      {
        label: 'Кредитные карты',
        children: [
          {
            card: {
              type: 2,
              number: "• 7811"
            },
            label: "MasterCard",
            description: "USD, RUB, UZS",
            value: "-2 082.22 UZS",
            payment: "100 000 UZS",
            link: `card/${uuid()}`,
          },
        ],
      },
      {
        label: 'Кредиты',
        children: [
          {
            icon: "icon-percentage-square",
            label: "Приобретение автомобиля",
            description: "Оплатить до 22.02.2024",
            value: "-2 082.22 UZS",
            debt: "33 982 UZS",
            link: `credit/${uuid()}`,
          },

        ],
      },
      {
        label: 'Счета',
        children: [
          {
            icon: "icon-strongbox",
            label: "Текущий счет",
            description: "UZ91 •••2948",
            value: "54 295.9 UZS",
            link: `account/${uuid()}`,
          },
          {
            icon: "icon-strongbox-2",
            label: "Депозитный счет",
            description: "UZ91 •••8473",
            value: "54 295.9 UZS",
            link: `account/${uuid()}`,
          },
        ],
      },
    ];
    const {config} = this.props

    const accordionItems = (data) => {
      return data.map((el, ind) => {
        return {
          key: ind,
          label: <>{el.label}{` (${el.children.length})`}</>,
          children: <Wrapper nomargin>
            <List>
              {el.children.map((i, key)=>{
                return <List.Item key={key}>
                  <CardListItem data={i} navigate={this.props.navigate}/>
                </List.Item>
              })}
            </List>
          </Wrapper>
        }
      })
    }

    return (
      <div className={classes.wrapper}>
        <Header
          notification={5}
          profile={"Айнура Кайдарова"}
        />
        <Row gutter={[0,16]}>
          <Col span={24}>

          </Col>
          <Col span={24}>
            <Collapse
              defaultActiveKey={['0']}
              ghost
              items={accordionItems(config?.cardItems)}
              expandIconPosition="end"
            />
          </Col>
          <BottomMenu/>
        </Row>
      </div>

    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setBackLink: (backLink) => dispatch(setBackLink({backLink})),
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(MyBank));