import {AUTHORIZATION, ISAUTHORIED, FIELDCHANGE, BACKLINK} from "../actions/actionTypes";

const initialState = {
  form: {
    password: "",
    phone: "",
    IIN:""
  },
}

export default function authorization(state = initialState, action) {
  switch (action.type) {

    case FIELDCHANGE:
      const {name, value, filter} = action.payload
      return {
        ...state,
        form: {
          ...state.form,
          [name]: filter ? value.replace(filter, "") : value
        }
      }
    default:
      return state
  }
}