import {connect, useStore} from "react-redux";
import {changeInputAuth, signIn} from "../store/actions/authorization";
import React, {Component} from "react";
import {Button, notification} from "antd";

const redirectService = (response, action) => {

  if (response?.code === "ERR_NETWORK") {
    notification.error({message: 'Требуется авторизоваться', duration: 4, placement: "bottomRight"})
    //action(false, false)
    localStorage.removeItem("user")
    localStorage.removeItem("role")
  }
  else if (response?.response?.data?.message != undefined) {
    const popUpDuration = response?.response?.data?.duration??4;
    const popUpUrl = response?.response?.data?.url??undefined;

    function download(url, key) {
      const a = document.createElement('a')
      a.href = url
      a.download = url.split('/').pop()
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      notification.destroy(key);
    }

    const keyId = `open${Date.now()}`;
    notification.error({
      key: keyId,
      message: response?.response?.data?.message,
      duration: popUpDuration,
      placement: "bottomRight",
      description: popUpUrl ? <Button type="primary" size="small" onClick={() => download(popUpUrl, keyId)}> Выгрузить </Button> : null,
    })
    //notification.error({message: response?.response?.data?.message, duration: popUpDuration, placement: "bottomRight", description: <a href="/home"> link </a>})
  }
  else  {
    notification.error({message: 'Неизвестная ошибка', duration: 4, placement: "bottomRight"})
  }
}

export default redirectService
