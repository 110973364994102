import {AUTHORIZATION, BACKLINK, FIELDCHANGE, ISAUTHORIED, CHANGELANGUAGE} from "./actionTypes";

export function changeInput(data){
  return {
    type: FIELDCHANGE,
    payload: data
  }
}

export function setAutorised(){
  return {
    type: ISAUTHORIED,
  }
}

export function signIn(){
  return {
    type: AUTHORIZATION,
  }
}

export function setBackLink(data){
  return {
    type: BACKLINK,
    payload: data
  }
}

export function setLanguage(data){
  return {
    type: CHANGELANGUAGE,
    payload: data
  }
}