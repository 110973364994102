import React from 'react';
import {Input} from 'antd';
import classes from './passwordInput.module.scss'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import {useIntl} from "react-intl";

const PasswordInput = (props) => {
  const {locale, placeholder} = props
  const intl = useIntl()

  return (
    <Input.Password
      {...props}
      type="tel"
      inputMode="tel"
      className={classes.input}
      /*onChange={e => changeInput(e)}
      value={!this.state.showPass ? this.state.value : this.state.hiddenValue}*/
      //suffix={<a onClick={()=> showPass(passwordShow)}>{passwordShow ? <Icon name="icon-eye" />: <Icon name="icon-eye-slash" />}</a> }
      iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
      placeholder={locale ? intl.formatMessage({id: locale}): placeholder ? placeholder : ""}
    />
  );
};

export default PasswordInput;