import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Card, Col, Form, notification, Row, Tabs} from 'antd';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import classes from './register.module.scss'
import MaskInput from "../../ui/inputs/MaskInput";
import PasswordInput from "../../ui/inputs/PasswordInput";
import {changeInput, clearForm} from "../../../store/actions/registration";
import Loader from "../../Loader/Loader";
import PhoneInput from "../../ui/inputs/PhoneInput";
import withRouter from "../../../hoc/withRouter";
import axios from "axios";
import Services from "../../../services/Services";
import TextInput from "../../ui/inputs/TextInput";
import Checkbox from "../../ui/selects/Checkbox";
import Select from "../../ui/selects/Select";
import Header from "../../Header";
import InputNumber from "../../ui/inputs/InputNumber";
import {FormattedMessage} from "react-intl";
import Wrapper from "../../ui/wrapper";


class Register extends Component {

  registrationForm = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      loading: false,
    }
  }

  config = {
    autoDocType: false,
    docType: 1,
    recognitionMode: 0,
    translitCheck: false,
    glareCheck: false,
    photocopyCheck: false,
    lang: 'ru',
    hints: {},
    render: {
      placeholder: true,
      startButton: true,
      containerBorderThickness: 1,
      containerBorderRadius: 3,
      containerBorderColor: "#000000",
      frame: true,
      frameBorderThickness: 3,
      frameBorderRadius: 20,
      frameColor: {
        default: "rgba(255, 255, 255, 1.0)",
        detected: "rgba(30, 255, 88, 1.0)"
      },
      overlay: true,
      overlayPermanent: true,
      overlayColor: {
        default: "#ffffff"
      },
      upperBarColor: {
        default: "rgba(255, 255, 255, 1.0)"
      },
      lowerBarColor: {
        default: "#a2d2ff",
        error: "#ffccd5"
      },
      buttonColor: {
        default: "#a2d2ff"
      },
      buttonTextColor: {
        default: "#353535"
      },
      overlayTransparency: {
        default: 0.7
      },
      icons: true,
      hint: true,
      hintTextColor: {
        default: "#353535"
      },
      hintFontType: "Arial",
      mirrorPreview: false
    }
  }

  configLive = {
    'recordVideo': false,
    'videoBitrate': 2500000,
    'rotated': false,
    'lang': "custom",

    'render': {
      'oval': true,
      'faceContourInsteadOfOval': true,
      'ovalRingColor': {
        'default': '#f5f542',
        'actionSuccess': '#00ba00',
        'actionFailure': '#d00000',
        'sessionSuccess': '#00ba00',
        'sessionFailure': '#d00000',
      },
      'ovalWidth': 1.0,

      'overlay': true,
      'overlayColor': {
        'default' : '#2f4f4f',
      },
      'overlayTransparency': {
        'default': 0.55,
      },

      'arrow': true,
      'arrowColor': {
        'default': '#f0f0f0',
      },
      'arrowProgressColor': {
        'default': '#404040',
      },

      'hint': true,
      'hintTextColor': {
        "default": "#eee",
      },
      'hintFontType': "Arial",
      'hintUseProgressiveFontSize': true,
      'hintProgressiveFontSizeMultiplier': 1.0,
      'hintFontSize': 25,
      "hintCloudColor": {
        "default": "#2d312f",
      },

      'videoUploadProgressBar': true,
      'videoUploadProgressBarColor1': "#FFEA82",
      'videoUploadProgressBarColor2': "#eee",
    },

    'hints': {
      'noHint': '',
      'noFace': 'Вас Не Видно',
      'badLight': "Выравните Освещение",
      'closer': 'Ближе',
      'away': 'Отдалитесь',
      'closerToCenter': 'Ближе к Центру Экрана',
      'сameraNotReadableError' : "Камера не найдена",
      'CameraNotReadableError' : "Камера не найдена",

      'targetLeft': 'Медленно Поворачивайте Голову Влево',
      'targetRight': 'Медленно Поворачивайте Голову Вправо',
      'targetCenter': 'Посмотрите Прямо',

      'sessionSuccess': 'Вы Прошли!',
      'sessionFailure': 'Вы Не Прошли!',
      'sessionError': 'Произошла какая-то ошибка. Попробуйте перезапустить',
    },
  };


  render() {
    const getRules = Services.formValidateRules
    const {changeInput, navigate} = this.props

    const onStepChange = (currentStep, nextStep, maxStep) => {
      if(currentStep > nextStep) {
        this.setState({step: nextStep})
      } else {
        this.registrationForm.current.validateFields().then(() =>{
          this.setState({step: nextStep})
        }).catch((error)=> {})
        if(currentStep === maxStep) {
          navigate("/signIn")
        }
      }
    }
    const buttonText = () => {
      if (step === 1 || step === 3) {
        return <FormattedMessage id="buttonBack" />
      } else {
        return <FormattedMessage id="buttonSendNewSMS" />
      }
    }

    const formRegStart = [
      /*{
        name: "iin",
        label: "Ваш ПИНФЛ",
        //rules: getRules("length", 14),
        children: <TextInput placeholder="Введите ПИНФЛ"/>
      },*/
      {
        name: "phoneNumber",
        label: <FormattedMessage id="labelPhoneNumber" />,
        rules: getRules("length", 7),
        children: <PhoneInput placeholder="Введите номер" locale="placeholderEnterNumber"/>
      },
      {
        name: "approval",
        rules: getRules("checked"),
        valuePropName: "checked",
        children: <Checkbox name={"approval"} title={<FormattedMessage id="labelAgreeProcessingPersonalData" />}/>
      },
      {
        name: "offer",
        rules: getRules("checked"),
        valuePropName: "checked",
        children: <Checkbox name={"offer"} title={<FormattedMessage id="labelAcceptTermsOffer" />}/>
      },
    ]

    const formSmsCode = [
      {
        name: "smsCode",
        label: <FormattedMessage id="labelCodeFromSMS" />,
        rules: getRules("length", 6),
        children: <InputNumber noDigit maxLength={6} placeholder="Введите код" locale="placeholderEnterCode"/>
      }
    ]

    const formCitizen = [
      {
        name: "citizen",
        label: <FormattedMessage id="labelCitizenship" />,
        rules: getRules("required"),
        children: <Select data={[{value: 1, label: "Узбекистан"}, {value: 2, label: "Россия"}]} locale="placeholderSelectFromList" placeholder="Выберите из списка"/>
      }
    ]

    const regStartForm = (form) => {
      return (
        <>
          <h2 style={{marginBottom: 40}}>
            <FormattedMessage id="headerRegistration"/>
          </h2>
          {Services.mapFormItem(formRegStart, this.registrationForm)}
        </>
      )
    }

    const smsCodeForm = () => {
      return (
        <>
          <h2 style={{marginBottom: 40}}>
            <FormattedMessage id="titleSentSMSWithCode"/>
          </h2>
          {Services.mapFormItem(formSmsCode, this.registrationForm)}
        </>
      )
    }

    const citizenForm = () => {
      return (
        <>
          <h2 style={{marginBottom: 40}}>
            <FormattedMessage id="titleSelectCitizenship" />
          </h2>
          {Services.mapFormItem(formCitizen, this.registrationForm)}
        </>
      )
    }

    const tabsItem = [
      {
        key: 1,
        label: `Первый шаг`,
        children: regStartForm()
      },
      {
        key: 2,
        label: `smsCode`,
        children: smsCodeForm()
      },
      {
        key: 3,
        label: `Беометрия лица`,
        children: citizenForm()
      },
    ]

    const {step} = this.state;

    return (
      <div className={classes.wrapper}>
          <Header
            back={"/"}
            title={<FormattedMessage id="headerRegistration" />}
            empty={24}
          />
          <Wrapper
            style={{padding: "24px 16px 32px 16px",}}
          >
            <Form
              ref={this.registrationForm}
              size="large"
              layout="vertical"
              className={classes.form}
            >

              <Tabs
                activeKey={step}
                animated
                className={classes.tabs}
                items={tabsItem}
                onChange={id => onStepChange(step, id, tabsItem.length)}
                renderTabBar={()=>{}}
              />

            </Form>
            <Row gutter={[16,16]}>
              {
                <Col span={24}>
                  <PrimaryButton
                    size="small"
                    onClick={() => onStepChange(step, step + 1, tabsItem.length)}
                    title={step === 1 ? <FormattedMessage id="buttonContinue" /> : <FormattedMessage id="buttonNextStep" />}
                  />
                </Col>
              }
              {
                (step === 2 || step === 3) &&
                <Col span={24}>
                  <PrimaryButton
                    bordered={true}
                    size="small"
                    title={buttonText()}
                    onClick={() => onStepChange(step, step - 1, tabsItem.length)}
                    timer={step === 2 ? 10 : false}
                  />
                </Col>
              }


            </Row>
          </Wrapper>

          {
            this.state.loading && <Loader/>
          }
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    form: state.gettingLoan
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeInput: (name, value, filter) => dispatch(changeInput({name, value, filter})),
    clearForm: () => dispatch(clearForm())
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Register));