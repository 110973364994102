
//Flags
import {ReactComponent as FlagRu} from "./language/flag-ru.svg"
import {ReactComponent as FlagEn} from "./language/flag-en.svg"
import {ReactComponent as FlagOz} from "./language/flag-oz.svg"

//Arrow
import {ReactComponent as ArrowUp} from "./icons/arrow-up.svg"
import {ReactComponent as ArrowRight} from "./icons/arrow-right.svg"
import {ReactComponent as ArrowLeft} from "./icons/arrow-left.svg"
import {ReactComponent as ArrowDown} from "./icons/arrow-down.svg"

//Logos
import {ReactComponent as LogoMC} from "./icons/logoMC.svg"

//Icons
import {ReactComponent as Telegram} from "./icons/telegram.svg"
import {ReactComponent as Facebook} from "./icons/facebook.svg"
import {ReactComponent as Whatsapp} from "./icons/whatsapp.svg"
import {ReactComponent as Mail} from "./icons/mail.svg"
import {ReactComponent as Wave} from "./icons/wave.svg"
import {ReactComponent as CardsIcon} from "./icons/services/cards.svg"
import {ReactComponent as PaymentIcon} from "./icons/services/wallet.svg"
import {ReactComponent as CreditsIcon} from "./icons/services/wallet-money.svg"
import {ReactComponent as TransferIcon} from "./icons/services/transfer.svg"
import {ReactComponent as SupportIcon} from "./icons/services/message-question.svg"
import {ReactComponent as TranslateIcon} from "./icons/services/translate.svg"
import {ReactComponent as LockIcon} from "./icons/services/lock.svg"
import {ReactComponent as SendIcon} from "./icons/services/send.svg"
import {ReactComponent as PasswordCheckIcon} from "./icons/services/password-check.svg"
import {ReactComponent as StrongboxIcon} from "./icons/services/strongbox.svg"
import {ReactComponent as FlashIcon} from "./icons/services/flash.svg"
import {ReactComponent as Notification} from "./icons/notification.svg"
import {ReactComponent as CalendarIcon} from "./icons/services/calendar.svg"
import {ReactComponent as MoneyIcon} from "./icons/services/money.svg"
import {ReactComponent as DiscountIcon} from "./icons/services/discount.svg"
import {ReactComponent as InfoIcon} from "./icons/services/info-circle.svg"

import {ReactComponent as Home} from "./icons/menu/home.svg"
import {ReactComponent as Bank} from "./icons/menu/bank.svg"
import {ReactComponent as QrCode} from "./icons/menu/qr-code.svg"
import {ReactComponent as Wallet} from "./icons/menu/wallet.svg"
import {ReactComponent as CrownIcon} from "./icons/crown.svg"

import {ReactComponent as VisaCardSmall} from "./icons/iconVisa.svg"
import {ReactComponent as MasterCardSmall} from "./icons/iconMC.svg"

const Images = [
  {id: "flagRu", comp: <FlagRu/>},
  {id: "flagOz", comp: <FlagOz/>},
  {id: "flagEn", comp: <FlagEn/>},
  {id: "arrowUp", comp: <ArrowUp/>},
  {id: "arrowDown", comp: <ArrowDown/>},
  {id: "arrowLeft", comp: <ArrowLeft/>},
  {id: "arrowRight", comp: <ArrowRight/>},
  {id: "iconCrown", comp: <CrownIcon/>},
  {id: "iconVisa", comp: <VisaCardSmall/>},
  {id: "iconMC", comp: <MasterCardSmall/>},
  {id: "logoMC", comp: <LogoMC/>},
  {id: "telegram", comp: <Telegram/>},
  {id: "facebook", comp: <Facebook/>},
  {id: "whatsapp", comp: <Whatsapp/>},
  {id: "mail", comp: <Mail/>},
  {id: "wave", comp: <Wave/>},
]
export default Images
