import React from 'react';
import classes  from "./categoryItem.module.scss"
import Wrapper from "../../wrapper";
import PrimaryButton from "../../buttons/PrimaryButton";
import ServiceButton from "../../buttons/ServiceButton";
import {Col, Divider, Flex, Row} from "antd";
import Services from "../../../../services/Services";
import Icon from "../../Icon";
import {FormattedMessage} from "react-intl";

const CategoryItem = (props) => {
  const {data: {title, description, advantages, image}, showModal, navigate, link } = props
  console.log("is link", link + "/order" )
  return (
    <div className={classes.wrapper}>
      <div className={classes.image} style={{backgroundImage: `url(${image})`}}/>
      <Wrapper>
        <div className={classes.title}>
          {title}
        </div>
        {
          description &&
          <div className={classes.description}>
            {description}
          </div>
        }
        <Divider />
        {<Flex vertical gap={16} className={classes.advantage}>
          {
            advantages?.map((el, i) => {
              return (
                <Flex gap={16}  key={i} align="center">
                  <div>
                    <div className={classes.icon}>
                      <Icon name={el.icon}/>
                    </div>
                  </div>
                  <Flex vertical gap={4}>
                    <span className={classes.advTitle}>{el.title}</span>
                    <span className={classes.advDescription}>{el.description}</span>
                  </Flex>
                </Flex>

              )
            })
          }
          {
            <div>
              <a className={classes.link} onClick={() =>showModal(true)}><FormattedMessage id="titleAdditionalTerms" /></a>
            </div>
          }

        </Flex>
        }
        <Divider />
        <div>
          <PrimaryButton className={classes.link} onClick={() => navigate(link)} title={<FormattedMessage id="buttonFillOutApplication" />}/>
        </div>
      </Wrapper>
    </div>
  );
};

export default CategoryItem;