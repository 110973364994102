import React, {Component} from 'react';
import {connect} from 'react-redux';
import classes from './index.module.scss';

import ServiceButton from '../../ui/buttons/ServiceButton';
import {Col, Row, Modal, Space, Card, List} from 'antd';
import withRouter from "../../../hoc/withRouter";
import Wrapper from "../../ui/wrapper";
import Header from "../../Header";
import LanguageModal from "../../ui/modal/LanguageModal";
import PrimaryButton from "../../ui/buttons/PrimaryButton";
import sources from "../../../source/sources";
import {useTelegram} from '../../../hooks/useTelegram'
import ListMenuModal from "../../ui/modal/ListMenuModal/ListMenuModal";
import Slider from "../../Slider/Slider";

import bannerImageOne from "../../../images/demoImage/banner-one.png"
import Icon from "../../ui/Icon";
import mapIconItem from "../../../services/mapIconItem";
import ContentModal from "../../ui/modal/ContentModal/ContentModal";
import {FormattedMessage} from "react-intl";



class IndexPage extends Component {

  state = {
    showModal: false,
    showSupportModal: false,
    error: false
  }

  render(props) {

    const showModal = (state) => {

      const prevState = this.state[state]
      this.setState({
        [state]: !prevState
      });
    }

    const {tg, user} = useTelegram()

    console.log("TG", tg, tg.initDataUnsafe, user)

    const service = [
      {
        icon: "icon-card",
        link: "cards",
        locale_id: "settingsListCards",
      },
      {
        icon: "icon-wallet-money",
        link: "payments",
        locale_id: "settingsListPayments",
      },
      {
        icon: "icon-wallet2",
        link: "credits",
        locale_id: "settingsListCredits",
      },
      {
        icon: "icon-convert-card",
        link: "transfers",
        locale_id: "settingsListTransfers",
      },
      {
        icon: "icon-message-question",
        action: () => showModal("showSupportModal"),
        locale_id: "settingsListSupport",
      },
    ]

    const token = '6347688101:AAGQkkT_rUW9kGNKHpW7-7_vz_uzBbB7dlY'

    const sendNotification = async (text, parse_mode) => {
      const endpoint = `https://api.telegram.org/bot${token}/sendMessage`;
      await makePostRequest(endpoint,
        {
          text,
          parse_mode,
          chat_id: user.id
        });
    };

    const makePostRequest = (url, details) => {
      return fetch(url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(details),
        })
        .then((response) => response.json());
    };

    const testReq = () => {
      const data = {getIdent: true}
      sendNotification("Test", "markdown").then(resp => {
        console.log("RESP", resp)
      })

      //tg.sendData(JSON.stringify(data))

      /*sources.getAccessToken()
      .then(({data}) => {
        console.log("Response", data)
      }).catch((error) => {
        console.log("Error", error)
        this.setState({error: error.message})
      })*/
    }

    const supportModalMenuItems = [
      {
        text: <FormattedMessage id="supportContactByPhone" />,
        img: "icon-call-calling",
      },
      {
        text: <FormattedMessage id="supportContactByEmail" />,
        img: "icon-sms-tracking",
      },
      {
        text: <FormattedMessage id="supportContactByTelegram" />,
        img: "icon-send-2",
      },
    ]

    const bannerItems = [
      {
        image: bannerImageOne,
        link: "testStorage"
      }
    ]


    return (
      <div className={classes.wrapper}>
        <Header
          loginLink={true}
          languageSelect={true}
        />
        <Wrapper transparent nopadding style={{marginRight: 0, marginLeft: 0}}>
          <Slider data={bannerItems}/>
        </Wrapper>
        <Wrapper>
          <Row>
            {
              service.map((el, key) => {
                return (
                  <Col className="gutter-row" key={key} span={8}>
                    <ServiceButton
                      title={<span><FormattedMessage id={el.locale_id} /></span>}
                      icon={el.icon}
                      link={el.link}
                      action={el.action}
                      vertical
                      bordered
                    />
                  </Col>
                )
              })
            }
          </Row>
          {/*{
            <Row gutter={[16,16]} style={{marginTop: 20}}>
              <Col span={24}>
                <a
                  type="media_type"
                  target={"_blank"}
                  style={{fontSize:20, background: "#007CE9", padding: 20, color: "#ffffff", textAlign: "center", display: "block", borderRadius:16}}
                  href="https://devmyid.uz/api/v1/oauth2/authorization?client_id=payhub_redirect-V5aeynDInnlj0jSd0Xtz6HjV6tbe3myiAY3kOZuw&response_type=code&redirect_uri=https://t.me/payhub_test_bot?start=success&scope=address,contacts,doc_data,common_data&method=strong&state=xyzABC123"
                >
                  Test MyId!
                </a>
              </Col>
            </Row>
          }*/}
        </Wrapper>
        <ContentModal
          open={this.state.showSupportModal}
          showModal={() => showModal("showSupportModal")}
          data={
            <Wrapper transparent nomargin>
              <Row gutter={[24,24]}>
                <Col span={24}>
                  <h2><FormattedMessage id="titleContactSupport" /></h2>
                </Col>
                <Col span={24}>
                  <List
                    dataSource={supportModalMenuItems}
                    renderItem={(item) => (
                      <List.Item
                        onClick={()=>{}}
                        size="small"
                      >
                        <div className={classes.item}>
                          {<Icon name={item.img} size={24}/>} <span className={classes.text}>{item.text}</span> <span className={classes.arrow}>{mapIconItem("arrowRight")}</span>
                        </div>
                      </List.Item>
                    )}
                  />
                </Col>
                <Col span={24}>
                  <PrimaryButton title={<FormattedMessage id="buttonBack"/>} bordered onClick={() => showModal("showSupportModal")}/>
                </Col>
              </Row>
            </Wrapper>
          }
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IndexPage));