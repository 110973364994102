import React, {Component} from 'react';
import {connect} from 'react-redux';
import classes from "./orderCredit.module.scss"
import Wrapper from "../../../../ui/wrapper";
import Header from "../../../../Header/Header";
import {Col, Form, notification, Row, Tabs} from "antd";
import withRouter from "../../../../../hoc/withRouter";
import Services from "../../../../../services/Services";
import TextInput from "../../../../ui/inputs/TextInput";
import InputNumber from "../../../../ui/inputs/InputNumber";
import TermSelect from "../../../../ui/selects/TermSelect/TermSelect";
import PrimaryButton from "../../../../ui/buttons/PrimaryButton";
import Checkbox from "../../../../ui/selects/Checkbox";
import {setBackLink} from "../../../../../store/actions/authorization";
import {FormattedMessage} from "react-intl";



class OrderCredit extends Component {
  orderForm = React.createRef()

  state = {
    step: 1,
    hasAdditionalIncome: false
  }

  componentDidMount() {
    const {setBackLink, location: {pathname}, params:{id, link}} =this.props
    setBackLink(`/${pathname.split("/")[1]}/${id}/${link}`)
  }

  render() {
    const {step, hasAdditionalIncome} = this.state
    const {params: {id, link}, navigate, signIn} = this.props
    const getRules = Services.formValidateRules
    const notificationConfig = {
      className: "successNotification",
      closeIcon: false,
      message: <span><FormattedMessage id="messageOrderSuccess" /></span>,
      description: <>
        <FormattedMessage id="messageOrderSuccessDescription" />
        <small>
          <FormattedMessage id="messageOrderSuccessDescriptionSubText" />
        </small>
      </>,
      icon: Services.mapIconItem("wave"),
      placement: "top",
    }

    const onStepChange = (currentStep, nextStep, maxStep) => {
      this.orderForm.current.validateFields().then(() =>{
        this.setState({step: nextStep})
      }).catch((error)=>{})
      if(currentStep === maxStep) {
        notification.success(notificationConfig)
        navigate(`/`)
      }
    }

    const hasAdditionalSource = [
      {
        name: "additionalWorkplace",
        label: <FormattedMessage id="labelWorkPlace" />,
        children: <TextInput placeholder="Ваше место работы" locale="Your place of work"/>,
        col: 24,
        rules: getRules("required"),
      },
      {
        name: "additionalIncomeAmount",
        label: <FormattedMessage id="labelAmountIncome" />,
        children: <InputNumber placeholder="Введите сумму дохода" locale="placeholderEnterAmountIncome"/>,
        col: 24,
        rules: getRules("required"),
      },
    ]

    const formRevenueSource = [
      {
        name: "workplace",
        label: <FormattedMessage id="labelWorkPlace" />,
        children: <TextInput placeholder="Ваше место работы" locale="Your place of work"/>,
        col: 24,
        rules: getRules("required"),
      },
      {
        name: "incomeAmount",
        label: <FormattedMessage id="labelAmountIncome" />,
        children: <InputNumber placeholder="Введите сумму дохода" locale="placeholderEnterAmountIncome"/>,
        col: 24,
        rules: getRules("required"),
      },
      {
        name: "hasAdditionalIncome",
        children: <Checkbox checked={hasAdditionalIncome} name={"hasAdditionalIncome"} title={<FormattedMessage id="labelExtraIncome" />} onChange={(name, val) => {this.setState({hasAdditionalIncome: val})}}/>,
        col: 24,
      },
    ]

    const mortgage = [
      {
        name: "mortgageAmount",
        label: <FormattedMessage id="labelPrice" />,
        children: <InputNumber placeholder="Введите стоимость жилья" locale="placeholderEnterHousingCost" addonAfter={"сум"} annotation={"От 10 000 000 до 60 000 000 сум"}/>,
        col: 24,
        rules: getRules("required"),
      },
      {
        name: "initialPayment",
        label: <FormattedMessage id="labelInitialPayment" />,
        children: <InputNumber placeholder="Введите сумму" locale="placeholderEnterAmount" addonAfter={"сум"}/>,
        col: 24,
        rules: getRules("required"),
      },
      {
        name: "creditTerm",
        label: <FormattedMessage id="labelCreditTerm" />,
        children: <TermSelect min={0} max={27} defaultValue={0}/>,
        rules: getRules("required"),
        col: 24,
      },
      {
        name: "needInsurance",
        children: <Checkbox title={<FormattedMessage id="labelInsurance" />}/>,
        col: 24,
      },
    ]

    const formAutoCreditInfo = [
      {
        name: "carBrand",
        label: <FormattedMessage id="labelCarBrand" />,
        children: <TextInput placeholder="Введите марку" locale="placeholderEnterBrand"/>,
        col: 24,
        rules: getRules("required"),
      },
      {
        name: "carModel",
        label: <FormattedMessage id="labelCarModel" />,
        children: <TextInput placeholder="Введите модель" locale="placeholderEnterModel" />,
        col: 24,
        rules: getRules("required"),
      },
      {
        name: "creditAmount",
        label: <FormattedMessage id="labelPrice" />,
        children: <InputNumber placeholder="Введите сумму кредита" locale="placeholderEnterLoanAmount" addonAfter={"сум"}/>,
        col: 24,
        rules: getRules("required"),
      },
      {
        name: "creditTerm",
        label: <FormattedMessage id="labelCreditTerm" />,
        children: <TermSelect min={0} max={27} defaultValue={0}/>,
        rules: getRules("required"),
        col: 24,
      },
    ]

    const formCreditInfo = [
      {
        name: "creditAmount",
        label: <FormattedMessage id="labelCreditAmount" />,
        children: <InputNumber placeholder="Введите сумму кредита" locale="placeholderEnterLoanAmount" addonAfter={"сум"} annotation={"От 10 000 000 до 60 000 000 сум"}/>,
        col: 24,
        rules: getRules("required"),
      },
      {
        name: "creditTerm",
        label: <FormattedMessage id="labelCreditTerm" />,
        children: <TermSelect min={0} max={27} defaultValue={0}/>,
        rules: getRules("required"),
        col: 24,
      },
    ]

    const creditInfoForm = (form) => Services.mapFormItem(form, this.orderForm)

    const creditType = (type) => {
      switch (type) {
        case "offers":
          return formCreditInfo
        case "autocredit":
          return formAutoCreditInfo
        case "mortgage":
          return mortgage
        default:
          return formCreditInfo
      }
    }

    const creditLabel = () => {
      switch (link) {
        case "cashLoan":
          return <FormattedMessage id="titleCredit" />
        case "nationalGreen":
          return <FormattedMessage id="titleCredit" />
        case "educationalLoan":
          return <FormattedMessage id="titleCredit" />
        case "onlineMicroloan":
          return <FormattedMessage id="titleMicroloan" />
        case "microloanSEPersons":
          return <FormattedMessage id="titleMicroloan" />
        case "creditCard40":
          return <FormattedMessage id="titleCreditCard40" />
        case "installmentCard":
          return <FormattedMessage id="titleInstallmentCard" />
        case "withoutCollateral":
          return <FormattedMessage id="titleMicroloan" />
        case "EcoGreenCar":
          return <FormattedMessage id="titleAutoCredit" />
        case "national":
          return <FormattedMessage id="titleAutoCredit" />
        case "newHousing":
          return <FormattedMessage id="titleMortgage" />
        case "secondaryHousing":
          return <FormattedMessage id="titleMortgage" />
        default: return <FormattedMessage id="titleCredit" />

      }
    }

    const tabsItem = hasAdditionalIncome ?
      [
      {
        key: 1,
        children: creditInfoForm(creditType(id))
      },
      {
        key: 2,
        children: creditInfoForm(formRevenueSource)
      },
       {
        key: 3,
        children: creditInfoForm(hasAdditionalSource)
      },
    ] : [
        {
          key: 1,
          children: creditInfoForm(creditType(id))
        },
        {
          key: 2,
          children: creditInfoForm(formRevenueSource)
        },
      ]


    return (
      <div className={classes.wrapper}>
        <Header
          back={`/credits/${id}/${link}`}
          title={creditLabel()}
          notification={signIn ? 2 : false}
          empty={!signIn}
        />
        <Wrapper>
          <Row gutter={[16,16]}>
            <Col span={24}>
              <Form
                ref={this.orderForm}
                size="large"
                layout="vertical"
              >
                <Tabs
                  activeKey={step}
                  animated
                  items={tabsItem.filter(el => el !== undefined)}
                  onChange={id => onStepChange(step, id, tabsItem.length)}
                  renderTabBar={()=>{}}
                />
              </Form>
            </Col>
            <Col span={24}>
              <PrimaryButton
                title={step === tabsItem.length ? <FormattedMessage id="buttonApply" /> : <FormattedMessage id="buttonContinue" />}
                onClick={() => onStepChange(step, step + 1, tabsItem.length)}
              />
            </Col>
          </Row>
        </Wrapper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    signIn: state.authorization.signIn,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setBackLink: (backLink) => dispatch(setBackLink({backLink})),
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(OrderCredit));