import React, {Component} from 'react';
import {connect} from 'react-redux';
import classes from "./home.module.scss"
import Header from "../../../Header";
import {Col, List, Row} from "antd";
import BottomMenu from "../../../ui/menu/BottomMenu";
import ServiceButton from "../../../ui/buttons/ServiceButton";
import Wrapper from "../../../ui/wrapper";
import IncompleteOperations from "../../../ui/infoBlocks/IncompleteOperations";
import ListMenuModal from "../../../ui/modal/ListMenuModal/ListMenuModal";
import withRouter from "../../../../hoc/withRouter";
import {setBackLink} from "../../../../store/actions/authorization";
import {Link} from "react-router-dom";
import Slider from "../../../Slider/Slider";

import bannerImageOne from "../../../../images/demoImage/banner-one.png"
import Icon from "../../../ui/Icon";
import mapIconItem from "../../../../services/mapIconItem";
import PrimaryButton from "../../../ui/buttons/PrimaryButton";
import ContentModal from "../../../ui/modal/ContentModal/ContentModal";
import {FormattedMessage} from "react-intl";



class Home extends Component {
  state = {
    showSupportModal: false
  }


  render() {

    const supportModalMenuItems = [
      {
        text: <FormattedMessage id="supportContactByPhone" />,
        img: "icon-call-calling",
      },
      {
        text: <FormattedMessage id="supportContactByEmail" />,
        img: "icon-sms-tracking",
      },
      {
        text: <FormattedMessage id="supportContactByTelegram" />,
        img: "icon-send-2",
      },
    ]

    const service = [
      {
        title: <span>Мой банк</span>,
        icon: "icon-bank",
        link: "myBank",
        key: "myBank",
        locale_id: "settingsListMyBank",
      },
      {
        title: <span>Карты</span>,
        icon: "icon-card",
        link: "cards",
        locale_id: "settingsListCards",
      },
      {
        title: <span>Кредиты</span>,
        icon: "icon-wallet-money",
        link: "credits",
        locale_id: "settingsListCredits",
      },
      {
        title: <span>Платежи</span>,
        icon: "icon-wallet2",
        link: "payments",
        locale_id: "settingsListPayments",
      },
      {
        title: <span>Переводы</span>,
        icon: "icon-convert-card",
        link: "transfers",
        locale_id: "settingsListTransfers",
      },
      {
        title: <span>Скан QR</span>,
        icon: "icon-scan",
        link: "transfers/qrTransfer",
        locale_id: "settingsListScanQR",
      },
      {
        title: <span>Поддержка</span>,
        icon: "icon-message-question",
        action: () => showModal("showSupportModal"),
        locale_id: "settingsListSupport",
      },
    ]

    const bannerItems = [
      {
        action: () => {},
        image: bannerImageOne,
      }
    ]

    const showModal = (state) => {
      const prevState = this.state[state]
      this.setState({
        [state]: !prevState
      });
    }

    const incompleteOperationsEvent = () => {
      this.props.navigate("/incompleteOperations")
      this.props.setBackLink(this.props.location)
    }

    return (
      <div className={classes.wrapper}>
        <Header
          notification={5}
          profile={"Айнура Кайдарова"}
        />
        <Row>
          <Col span={24}>
            <Wrapper transparent nopadding style={{marginRight: 0, marginLeft: 0}}>
              <Slider data={bannerItems}/>
            </Wrapper>
            <Link to={"/credits/autoCredit/withoutCollateral/order"}>
              <IncompleteOperations
                title={<FormattedMessage id="titleOrderCarLoan" />}
                action={incompleteOperationsEvent}
              />
            </Link>

            <Wrapper>
              <Row>
                {
                  service.map((el, key) => {
                    return (
                      <Col className="gutter-row" key={key} span={8}>
                        <ServiceButton
                          title={<span><FormattedMessage id={el.locale_id} /></span>}
                          icon={el.icon}
                          link={el.link}
                          action={el.action}
                          vertical
                          bordered
                          small
                        />
                      </Col>
                    )
                  })
                }
              </Row>
            </Wrapper>
          </Col>

        </Row>
        <ContentModal
          open={this.state.showSupportModal}
          showModal={() => showModal("showSupportModal")}
          data={
            <Wrapper transparent nomargin>
              <Row gutter={[24,24]}>
                <Col span={24}>
                  <h2><FormattedMessage id='titleContactSupport' /></h2>
                </Col>
                <Col span={24}>
                  <List
                    dataSource={supportModalMenuItems}
                    renderItem={(item) => (
                      <List.Item
                        onClick={()=>{}}
                        size="small"
                      >
                        <div className={classes.item}>
                          {<Icon name={item.img} size={24}/>} <span className={classes.text}>{item.text}</span> <span className={classes.arrow}>{mapIconItem("arrowRight")}</span>
                        </div>
                      </List.Item>
                    )}
                  />
                </Col>
                <Col span={24}>
                  <PrimaryButton title="Назад" locale="buttonBack" bordered onClick={() => showModal("showSupportModal")}/>
                </Col>
              </Row>
            </Wrapper>
          }
        />
      </div>

    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setBackLink: (backLink) => dispatch(setBackLink({backLink})),
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Home));