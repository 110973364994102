import React, {Component} from 'react';
import {connect} from 'react-redux';
import withRouter from "../../../../hoc/withRouter";
import {Col, ConfigProvider, List, Row} from "antd";
import Wrapper from "../../../ui/wrapper";
import classes from "./profile.module.scss";
import Services from "../../../../services/Services";
import mapIconItem from "../../../../services/mapIconItem";
import ListMenu from "../../../ui/menu/ListMenu";
import PrimaryButton from "../../../ui/buttons/PrimaryButton";
import {setLanguage, signIn} from "../../../../store/actions/authorization";
import LanguageModal from "../../../ui/modal/LanguageModal";
import ListMenuModal from "../../../ui/modal/ListMenuModal/ListMenuModal";
import Header from "../../../Header";
import ContentModal from "../../../ui/modal/ContentModal/ContentModal";
import Icon from "../../../ui/Icon";
import {FormattedMessage} from "react-intl";



class Profile extends Component {
  state = {
    profile: {
      name: "Айнура Кайдарова",
      phone: "998926257656",
      email: "a.kaidarova@mail.uz",
      pinfl: "64925697314632",
    },
    showModal: false,
    showSupportModal: false,
  }

  render() {
    const {profile: {name, phone, email, pinfl}} = this.state
    const {setLanguage} = this.props

    const showModal = (state) => {

      const prevState = this.state[state]
      this.setState({
        [state]: !prevState
      });
    }

    const src = [
      {
        text: <FormattedMessage id="settingsListApplicationLanguage" />,
        icon: "icon-translate",
        action: () => showModal("showModal"),
      },
      {
        text: <FormattedMessage id="settingsListChangeAccessCode" />,
        icon: "icon-lock2",
      },
      {
        text: <FormattedMessage id="settingsListSupport" />,
        icon: "icon-message-question",
        action: () => showModal("showSupportModal"),
      },
    ]

    const supportModalMenuItems = [
      {
        text: <FormattedMessage id="supportContactByPhone" />,
        img: "icon-call-calling",
      },
      {
        text: <FormattedMessage id="supportContactByEmail" />,
        img: "icon-sms-tracking",
      },
      {
        text: <FormattedMessage id="supportContactByTelegram" />,
        img: "icon-send-2",
      },
    ]

    return (
      <div className={classes.wrapper}>
        <Row>
          <Col span={24}>
            <Wrapper>
              <Row gutter={[16,16]}>
                <Col span={24} className={classes.info}>
                  <div className={classes.avatar}>
                    {
                      Services.shortName(name, "initials")
                    }
                  </div>
                  <div className={classes.name}>
                    {name}
                    <div className={classes.phone}>
                      {Services.shortName(phone, "phone")}
                    </div>
                  </div>
                </Col>
                <Col span={24} className={classes.contact}>
                  <div className={classes.mail}>
                    EMAIL:
                    <div>
                      {email}
                    </div>
                  </div>
                  <div className={classes.divider}>

                  </div>
                  <div className={classes.pinfl}>
                    <FormattedMessage id="labelPINFL" />:
                    <div>
                      {Services.shortName(pinfl, "pinfl")}
                    </div>
                  </div>
                </Col>
              </Row>
            </Wrapper>
            <Wrapper nopadding>
              <ListMenu borderIcon data={src}/>
            </Wrapper>
            <Wrapper nopadding noBackground>
              <PrimaryButton title={<FormattedMessage id="buttonLogOut" />} onClick={() => this.props.signIn()}/>
            </Wrapper>
          </Col>
          <LanguageModal
            open={this.state.showModal}
            showModal={() => showModal("showModal")}
            languageSelect={setLanguage}
          />
          <ContentModal
            open={this.state.showSupportModal}
            showModal={() => showModal("showSupportModal")}
            data={
              <Wrapper transparent nomargin>
                <Row gutter={[24,24]}>
                  <Col span={24}>
                    <h2><FormattedMessage id="titleContactSupport" /></h2>
                  </Col>
                  <Col span={24}>
                    <List
                      dataSource={supportModalMenuItems}
                      renderItem={(item) => (
                        <List.Item
                          onClick={()=>{}}
                          size="small"
                        >
                          <div className={classes.item}>
                            {<Icon name={item.img} size={24}/>} <span className={classes.text}>{item.text}</span> <span className={classes.arrow}>{mapIconItem("arrowRight")}</span>
                          </div>
                        </List.Item>
                      )}
                    />
                  </Col>
                  <Col span={24}>
                    <PrimaryButton title={<FormattedMessage id="buttonBack" />} bordered onClick={() => showModal("showSupportModal")}/>
                  </Col>
                </Row>
              </Wrapper>
            }
          />
        </Row>
      </div>

    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    signIn: (loading, isLogin) => dispatch(signIn({loading, isLogin})),
    setLanguage: (locale) => dispatch(setLanguage({locale}))
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Profile));