import React from 'react';
import classes from "./providerListItem.module.scss"
import {Image} from "antd";
import {Link} from "react-router-dom";

const ProviderListItem = (props) => {
  const {data} = props
  return (
    <div className={classes.wrapper}>
      <div className={classes.item}>
        <div className={classes.icon}>
          <Image src={data.icon}/>
        </div>
        <div>
          <div className={classes.title}>{data.title}</div>
          <div className={classes.description}>{data.description}</div>
        </div>
      </div>
    </div>
  );
};

export default ProviderListItem;