import React from 'react'
import Wrapper from "../../wrapper"
import {Col, Row} from "antd"
import classes from "./incompleteOperations.module.scss"
import mapIconItem from "../../../../services/mapIconItem";
import Icon from "../../Icon";
import {Link} from "react-router-dom";
import {useIntl} from "react-intl";

const IncompleteOperations = (props) => {
  const intl = useIntl()
  return (
    <Wrapper>
      <Row>
        <Col span={24}>
          <div className={classes.wrapper} onClick={props.action}>
            <div>
              {intl.formatMessage({id: "titleUnfinishedOperations"})}
              <div className={classes.title}>{props.title}</div>
            </div>
            <Icon name="icon-arrow-circle-right"/>
          </div>
        </Col>
      </Row>
    </Wrapper>
  )
}

export default IncompleteOperations