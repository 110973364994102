import React, { useRef, useState }from 'react';
import {Input} from "antd";
import classes from './inputNumber.module.scss'
import {FormattedMessage} from "react-intl";
import {useIntl} from 'react-intl'

const InputNumber = (props) => {
  const {value, onChange, noDigit, maxLength} = props
  const intl = useIntl()
  const formatValue = value => {
    if (value === undefined || value === null) {
      return ''
    }
    else if (noDigit){
      return `${value}`.replace(/[^0-9]/g,"")
    } else {
      return `${value}`.replace(/[^0-9]/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }
  }

  const [state, setState] = useState(false);


  const parseValue = value => {
    return value.replace(/\s/g, '')
  };

  const handleChange = e => {
    const inputValue = maxLength ? e.target.value.substring(0, maxLength) : e.target.value
    const parsedValue = parseValue(inputValue)
    onChange(formatValue(parsedValue))
  };

  return (
    <>
      <Input
        {...props}
        className={classes.input +` ${ state ? classes.focus : ""}` }
        value={formatValue(value)}
        onChange={handleChange}
        onFocus={() => setState(true)}
        onBlur={() => setState(false)}
        type="tel"
        inputMode="tel"
        placeholder={props.locale ? intl.formatMessage({id: props.locale}): props.placeholder ? props.placeholder : ""}
      />
      {
        props?.annotation &&
        <small className={classes.annotation}>{props.annotation}</small>
      }
    </>

  )
}

export default InputNumber