import mapIconItem from "./mapIconItem"
import mapFormItem from "./mapFormItem"
import mapClassName from "./mapClassName"
import formValidateRules from "./formValidateRules"
import userService from "./userService";
import redirectService from "./redirectService";
import shortName from "./shortName"
import demo from "./demo"
import uuid from "./uuid"

const Services = {
  mapIconItem,
  mapFormItem,
  mapClassName,
  formValidateRules,
  userService,
  redirectService,
  shortName,
  demo,
  uuid,
}

export default Services