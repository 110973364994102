import React, {Component} from 'react';
import {connect} from 'react-redux';
import Header from "../../../../Header";
import BottomMenu from "../../../../ui/menu/BottomMenu";
import Wrapper from "../../../../ui/wrapper";
import classes from "./cardBlock.module.scss"
import {Form, List, Radio, Input, DatePicker, Tabs, Col, Row} from "antd";
import PrimaryButton from "../../../../ui/buttons/PrimaryButton";
import DateSelect from "../../../../ui/selects/DateSelect";
import DateRangeSelect from "../../../../ui/selects/DateRangeSelect";
import withRouter from "../../../../../hoc/withRouter";
import Services from "../../../../../services/Services";
import TextInput from "../../../../ui/inputs/TextInput";
import ContentModal from "../../../../ui/modal/ContentModal/ContentModal";
import {setBackLink} from "../../../../../store/actions/authorization";
import {FormattedMessage} from "react-intl";
import TextArea from "../../../../ui/inputs/TextArea";



class CardBlock extends Component {
  blockForm = React.createRef()

  state = {
    periodType: false,
    step:1,
    showPeriodModal: true,
  }

  componentDidMount() {
    const {setBackLink, location: {pathname}, params:{id}} =this.props
    setBackLink(`/${pathname.split("/")[1]}/${pathname.split("/")[2]}/${id}`)
  }

  render() {
    const { RangePicker } = DatePicker;
    const getRules = Services.formValidateRules
    const {navigate, params:{id}, config, locale} = this.props
    const {periodType, step, showPeriodModal} = this.state
    const data = [
      {value: 1, label: "На время"},
      {value: 2, label: "Навсегда"},
      {value: 3, label: "Навсегда и заказать новую"},
    ]
    const changePeriod = (value) => {
      this.setState({periodType: value})
    }

    const onShowPeriodModal = (prevState) => {
      this.setState({
        showPeriodModal: !prevState
      })

    }

    const onStepChange = (currentStep, nextStep, maxStep) => {
      this.blockForm.current.validateFields().then(() =>{
        this.setState({step: nextStep})
      })
      if(currentStep === maxStep) {
        navigate(`/myBank/card/${id}`)
      }
    }

    const cardConfirmForm = () => {
      return (
        <Wrapper>
          <h2 style={{marginBottom: 40}}>
            <FormattedMessage id="titleWeSentAnSMS" />
          </h2>
          {Services.mapFormItem(formConfirm, this.blockForm)}
          <Row gutter={[16,16]}>
            <Col span={24}>
              <PrimaryButton
                title={<FormattedMessage id="buttonApply" />}
                onClick={() => onStepChange(step, step + 1, tabsItem.length)}
              />
            </Col>
            <Col span={24}>
              <PrimaryButton
                bordered={true}
                title={<FormattedMessage id="buttonSendNewSMS" />}
                onClick={()=> {}}
                timer={step === 2 ? 10 : false}
              />
            </Col>
          </Row>
        </Wrapper>
      )
    }

    const formConfirm = [
      {
        name: "smsCode",
        label: <FormattedMessage id="labelCodeFromSMS" />,
        children: <TextInput locale="placeholderEnterCode" placeholder="Введите код"/>,
        col: 24,
        rules: getRules("required"),
      },
    ]



    const cardBlockForm = () => {
      return (
        <>
          <Wrapper nopadding>
            <Form.Item name="time" rules={getRules("required")}>
              <Radio.Group className={classes.radio} onChange={({target}) => changePeriod(target.value)} value={periodType}>
                <List
                  size="small"
                  dataSource={config?.periodTypeList}
                  renderItem={(item) => <List.Item className={classes.item}><Radio value={item.value}>{item.label}</Radio></List.Item>}
                />
              </Radio.Group>
            </Form.Item>
          </Wrapper>
          {periodType === 1 &&
          <Wrapper nopadding>
            <Form.Item name="timeRange">
              <DateRangeSelect locale={locale.split("-")[0]} onClick={() => onShowPeriodModal(showPeriodModal)}/>
            </Form.Item>
          </Wrapper>
          }
          <Wrapper>
            <Form.Item name="reason" label={<FormattedMessage id="labelBlockReason" />}>
              <TextArea rows={5} locale="placeholderDescribeReason" placeholder="Опишите причину блокировки (не обязательно)"/>
            </Form.Item>
            <PrimaryButton title={<FormattedMessage id="buttonBlock" />} danger onClick={() => onStepChange(step, step + 1, tabsItem.length) }/>
          </Wrapper>

        </>
      )
    }

    const tabsItem = [
      {
        key: 1,
        children: cardBlockForm()
      },
      {
        key: 2,
        children: cardConfirmForm()
      },
    ]

    return (
      <div className={classes.wrapper}>
        <Header back={`/myBank/card/${id}`} title={<FormattedMessage id="titleBlockCard" />} empty />
        <Form ref={this.blockForm}>
          <Tabs
            activeKey={step}
            animated
            className={classes.tabs}
            items={tabsItem}
            onChange={id => onStepChange(step, id, tabsItem.length)}
            renderTabBar={()=>{}}
          />
          {/*<Wrapper nopadding>
            <Form.Item name="time">
              <Radio.Group className={classes.radio} onChange={({target}) => changePeriod(target.value)} value={periodType}>
                <List
                  size="small"
                  dataSource={data}
                  renderItem={(item) => <List.Item className={classes.item}><Radio value={item.value}>{item.label}</Radio></List.Item>}
                />
              </Radio.Group>
            </Form.Item>
          </Wrapper>
          {periodType === 1 &&
            <Wrapper nopadding>
              <Form.Item name="timeRange">
                <DateRangeSelect/>
              </Form.Item>
            </Wrapper>
          }
          <Wrapper>
            <Form.Item name="reason" label="Причина блокировки">
              <Input.TextArea rows={5} placeholder="Опишите причину блокировки (не обязательно)"/>
            </Form.Item>
            <PrimaryButton title="Заблокировать" danger/>
          </Wrapper>*/}
        </Form>
        <BottomMenu/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    locale: state.authorization.locale,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setBackLink: (backLink) => dispatch(setBackLink({backLink})),
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(CardBlock));