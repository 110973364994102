import React from 'react';
import mapClassName from "../../../services/mapClassName";

const Icon = (props) => {
  const {name, size, color} = props
  return (
    <i className={name} style={{fontSize: size, color: color}}  />
  );
};

export default Icon;