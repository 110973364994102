import { LOCALES } from './locales'

export const messages = {
  [LOCALES.RUSSIAN]: {
    //bottom menu
    bottomMenuHome: "Главная",
    bottomMenuCards: "Карты",
    bottomMenuPayments: "Платежи",
    bottomMenuTransfers: "Переводы",
    bottomMenuMyBank: "Мой банк",
    bottomMenuMyQR: "Мой QR",
    //buttons
    buttonReedMore: "Подробнее",
    buttonApply: "Подтвердить",
    buttonTransfer: "Перевести",
    buttonContinue: "Продолжить",
    buttonNextStep: "Следующий шаг",
    buttonLogOut: "Выйти",
    buttonBack: "Назад",
    buttonForgotAccessCode: "Забыли код доступа?",
    buttonNotRegistered: "Я не зарегистрирован",
    buttonSendNewSMS: "Отправить новый SMS-код",
    buttonFillOutApplication: "Заполнить заявку",
    buttonPay: "Оплатить",
    buttonBlockCard: "Блокировать карту",
    buttonCardReissue: "Перевыпуск карты",
    buttonLimits: "Лимиты",
    buttonShow: "Показать",
    buttonReissue: "Перевыпустить",
    buttonBlock: "Заблокировать",
    buttonGoToSupportChat: "Перейти в чат поддержки",
    buttonCopy: "Копировать",
    buttonSend: "Отправить",
    buttonSendQR: "Отправить QR",
    buttonCreateNewQRCode: "Создать новый QR",

    //titles
    titlePersonalAccount: "Личный кабинет",
    titleCreditType: "Тип кредита",
    titleCardType: "Тип карты",
    titleOrderCard: "Заказать карту",
    titleTransferType: "Тип перевода",
    titleHistoryOperations: "История операций",
    titleCredit: "Кредит",
    titleCredits: "Кредитные",
    titleCreditCards: "Кредитные карты",
    titleCreditCard40: "Кредитная карта До 40 дней без %",
    titleDebitCards: "Дебетовые карты",
    titleMicroloan: "Микрозайм",
    titleMicroloans: "Микрозаймы",
    titleAutoCredit: "Автокредит",
    titleAutoCredits: "Автокредиты",
    titleMortgage: "Ипотека",
    titleNotifications: "Уведомления",
    titleCards: "карты",
    titleWelcome: "Добро пожаловать",
    titleAuthorization: "Авторизация",
    titleSentSMSWithCode: "Мы отправили Вам СМС с кодом. Введите его в поле ниже.",
    titleSelectCitizenship: "Выберите гражданство",
    titleComeUpAccessCode: "Придумайте код доступа для настройки авторизации",
    titleContactSupport: "Связаться с поддержкой",
    titleAdditionalTerms: "Дополнительные условия",
    titleInstallmentCard: "Карта рассрочки",
    titleWeSentAnSMS: "Мы отправили Вам СМС с кодом. Введите его в поле ниже.",
    titleQRTranslation: "QR-перевод",
    titleBetweenYourAccounts: "Между своими счетами",
    titleByPhoneNumber: "По номеру телефона",
    titleToAnyBankCard: "На карту любого банка",
    titleInternationalTransfers: "Международные переводы",
    titleOnlineConversion: "Онлайн конверсия",
    titleByDetails: "По реквизитам",
    titleFavorites: "Избранные",
    titleAllSuppliers: "Все поставщики",
    titlePreviouslyPaid: "Ранее оплачивали",
    titleUnfinishedOperations: "Незавершенные операции",
    titleOrderCarLoan: "Заявка на автокредит",
    titleAccounts: "Счета",
    titleCardBalance: "Баланс карты",
    titleRequisites: "Реквизиты",
    titleBonuses: "Бонусов",
    titleCardDetails: "Реквизиты карты",
    titleExtract: "Выписка",
    titleCardBlocked: "Карта заблокирована",
    titleShowFullNumber: "Показать полный номер",
    titleBlockCard: "Заблокировать карту",
    titleSpecifyPeriod: "Укажите период",
    titleSelectPeriod: "Выберите период",
    titlePartialRepayment: "Частичное погашение",
    titleFullRepayment: "Полное погашение",
    titleAboutLoan: "Информация о кредите",
    titlePaymentSchedule: "График платежей",
    titleOnLoan: "по кредиту",
    titleLinkedAccount: "Связанный счет",
    titleToRepay: "Погасить",
    titlePartially: "частично",
    titleFully: "полностью",
    titleAccountInformation: "Информация о счете",
    titleCloseAccount: "Закрыть счет",
    titleClosingAccount: "Закрытие счета",
    titleCashWithdrawal: "Снятие наличных",
    titleDetailedAccountTerms: "Подробные условия счета",
    titleAccountDetails: "Реквизиты счета",
    titleSendAccountDetails: "Отправить реквизиты счета",
    titleSendQRCode: "Отправить QR-код",
    titleNewQRCode: "Новый QR-код",
    titleMyQRCode: "Мой QR-код",
    titleNew: "новые",
    titleIdentification: "Идентификация",
    titleAccessCodeSaveSuccessfully: "Новый код доступа настроен успешно",


    //labels
    labelBIK: "БИК",
    labelBankINN: "ИНН Банка",
    labelBankKPP: "КПП Банка",
    labelSecurityCode: "Ваш код доступа",
    labelYourPINFL: "Ваш ПИНФЛ",
    labelPINFL: "ПИНФЛ",
    labelCodeFromSMS: "Код из SMS",
    labelPhoneNumber: "Номер телефона",
    labelAcceptTermsOffer: "Я принимаю условия оферты",
    labelAgreeProcessingPersonalData: "Я согласен на обработку моих персональных данных",
    labelCitizenship: "Гражданство",
    labelWorkPlace: "Место работы",
    labelAmount: "Сумма",
    labelAmountIncome: "Сумма дохода",
    labelCreditAmount: "Сумма кредита",
    labelTransferAmount: "Сумма перевода",
    labelPaymentAmount: "Сумма платежа",
    labelDebtAmount: "сумма задолженности",
    labelExtraIncome: "Есть доп. доход",
    labelPrice: "Стоимость",
    labelInitialPayment: "Первоначальный взнос",
    labelCreditTerm: "Срок кредита",
    labelInsurance: "Страхование",
    labelCarBrand: "Марка авто",
    labelCarModel: "Модель авто",
    labelCardCreditLimit: "Кредитный лимит карты",
    labelDeliveryMethod: "Выберите способ получения",
    labelPickUpDepartment: "Заберу в отделении",
    labelCourierDelivery: "Доставка курьером",
    labelSelectBranch: "Выберите филиал",
    labelSelectDeliveryCity: "Выберите город доставки",
    labelSelectStreet: "Выберите улицу",
    labelHouse: "Дом",
    labelApartment: "Квартира",
    labelEntrance: "Подъезд",
    labelFloor: "Этаж",
    labelComment: "Комментарий",
    labelMyVISACard: "Моя карта VISA",
    labelLinkedCard: "Связанная карта",
    labelFrom: "Откуда",
    labelWhere: "Куда",
    labelRecipientFullName: "ФИО получателя",
    labelRecipientBank: "Банк получателя",
    labelChargeAmount: "Сумма списания",
    labelAmountToBeCredited: "Сумма к зачислению",
    labelAccountNumber: "Номер счета",
    labelRecipientAccountNumber: "Номер счета получателя",
    labelRecipientIIN: "ИИН получателя",
    labelRecipient: "Получатель",
    labelAmountToBePaid: "Сумма к оплате",
    labelNumberAccountOrPhone: "Номер счета/телефона",
    labelCarPurchasing: "Приобретение автомобиля",
    labelCurrentAccount: "Текущий счет",
    labelDepositAccount: "Депозитный счет",
    labelReason: "Причина",
    labelBlockReason: "Причина блокировки",
    labelPerMonth: "За месяц",
    labelForHalfYear: "За полгода",
    labelForYear: "За год",
    labelTotalContributed: "внесено всего",
    labelAccountBalance: "Баланс счета",
    labelCorrespondentAccount: "Корреспондентский счет",
    labelWhereToTopUp: "Куда пополнить",

    //placeholders
    placeholderEnterSecurityCode: "Введите код доступа",
    placeholderEnterCode: "Введите код",
    placeholderEnterNumber: "Введите номер",
    placeholderEnterPINFL: "Введите ПИНФЛ",
    placeholderConfirmCode: "Подтвердите код",
    placeholderSelectFromList: "Выберите из списка",
    placeholderYourWorkPlace: "Ваше место работы",
    placeholderEnterAmount: "Введите сумму",
    placeholderEnterAmountIncome: "Введите сумму дохода",
    placeholderEnterLoanAmount: "Введите сумму кредита",
    placeholderEnterLimitAmount: "Введите сумму лимита",
    placeholderEnterHousingCost: "Введите стоимость жилья",
    placeholderEnterBrand: "Введите марку",
    placeholderEnterModel: "Введите модель",
    placeholderHouseNumber: "Номер дома",
    placeholderApartmentNumber: "Номер квартиры",
    placeholderEntranceNumber: "Номер подъезда",
    placeholderNotNecessary: "(не обязательно)",
    placeholderEnterFullName: "Введите ФИО",
    placeholderEnterCardNumber: "Введите номер карты",
    placeholderSearchPayment: "Поиск платежа",
    placeholderEnterNumberAccountOrPhone: "Введите номер счета/телефона",
    placeholderChooseReason: "Выберите причину",
    placeholderEnterNameOnCard: "Введите имя указанное на карте",
    placeholderDescribeReason: "Опишите причину блокировки (не обязательно)",
    placeholderEnterEmail: "Введите E-mail",
    //errors
    errorRequired: "Обязательное поле",
    errorMinLength: 'Должно содержать больше {val , plural, zero {# символ} one {# символа} two {# символа} few {# символов} many {# символов} other {# символа}}',
    errorLength: 'Должно содержать {val , plural, zero {# символ} one {# символа} two {# символа} few {# символов} many {# символов} other {# символа}}',
    errorNumber: 'Должно содержать {val , plural, zero {# цифр} one {# цифру} two {# цифры} few {# цифр} many {# цифр} other {# цифр}}',
    errorNotEnoughLength: 'Слишком мало символов',
    errorNeedDate: 'Необходимо указать дату',
    errorLatitudeFormat: 'Не соответствует формату широты',
    errorLongitudeFormat: 'Не соответствует формату долготы',
    errorLongLatFormat: 'Не соответствует формату широты и долготы',
    errorEmailFormat: 'Не соответствует формату e-mail',
    errorPasswordsDontMatch: "Пароли не совпадают",
    //header
    headerLogin:"Вход",
    headerRegistration: "Регистрация",
    headerSettings: "Настройки",
    //SettingsList
    settingsListCards: "Карты",
    settingsListPayments: "Платежи",
    settingsListCredits: "Кредиты",
    settingsListTransfers: "Переводы",
    settingsListSupport: "Поддержка",
    settingsListMyBank: "Мой банк",
    settingsListScanQR: "Скан QR",
    settingsListApplicationLanguage: "Язык приложения",
    settingsListChangeAccessCode: "Изменение кода доступа",
    //Support
    supportContactByPhone: "Связаться по телефону",
    supportContactByEmail: "Связаться по Email",
    supportContactByTelegram: "Чат в Telegram",

    //TextMessage
    messageOrderSuccess: "Ваша заявка отправлена",
    messageOrderSuccessDescription: "Одобрение происходит в считанные секунды, ожидайте.",
    messageOrderSuccessDescriptionSubText: "Если обработка заявки займёт чуть больше времени," +
    " вы будете перенаправлены на следующий шаг. Статус одобрения будет отправлен в СМС.",
    messageCardBeReady: "Карта будет готова через 2-5 дней.",
    messageTranslationCompleted: "Перевод выполнен",
    messageTransferSentSuccessfully: "Ваш перевод успешно отправлен",

    //anotherText
    textMaximumAmount: "Максимальная сумма",
    textTransferCourse: "Курс на момент перевода",
    textCommission: "Комиссия",
    textStrictlyEqualPaymentAmount: "Обратите внимание:" +
      " при оплате услуг некоторых поставщиков необходимо вводить сумму платежа" +
      " строго равную сумме оплаты, указанной в квитанции поставщика." +
      " Если у Вас нет квитанции, Вы можете уточнить требуемую сумму оплаты непосредственно у поставщика.",
    textPayBefore: "Оплатить до",
    textCurrentCardWillBeClosed: "Текущая карта будет закрыта, а у новой будут другие реквизиты.",
    textPayLoanToday: "Оплатите кредит сегодня",
    textYouAreOverdue: "Вы на просрочке",
    textBefore: "до",
    textNotCharged: "не взимается",
    textHaveBankCardIssued: "Если у вас выпущены банковская карта или счет к кредиту," +
      " то с момента закрытия кредита ее обслуживание осуществляется" +
      " в соответствии с тарифами Банка.",
    textContactSupportForClose: "Для закрытия счета обратитесь в поддержку банка. По правилам банка счет будет закрыт через " +
      "{val, plural, zero {# дней} one {# день} two {# дня} few {# дней} many {# дней} other {# дня}}.",
    textAccountForBasicTransactions: "Счет для основных операций в различных валютах.",
    textAccountWithoutCommission: "Обслуживание счета без комиссии",
    textBanksPartnersWithoutCommission: "В банках Test и партнеров без комиссии.",
    textTransfersWithinLimits: "Переводы в рамках лимитов по тарифному пакету.",
    textShowQRToPerson: "Покажите QR тому, кто будет делать вам перевод",
    textPointCameraAtQRCode: "Наведите камеру на QR-код",
    textAppWillAutomaticallyRecognize: "Приложение автоматически распознает зашифрованную информацию и подставит ее в подходящие поля.",
    textPasswordMustHave: "Пароль должен содержать не менее {val , plural, zero {# цифр} one {# цифру} two {# цифры} few {# цифр} many {# цифр} other {# цифр}}",
    textIdentificationHasBeenCompleted: "Процесс идентификации успешно завершен.",
  },
  [LOCALES.ENGLISH]: {
    //bottom menu
    bottomMenuHome: "Home",
    bottomMenuCards: "Cards",
    bottomMenuPayments: "Payments",
    bottomMenuTransfers: "Transfers",
    bottomMenuMyBank: "My bank",
    bottomMenuMyQR: "My QR",
    //buttons
    buttonReedMore: "Reed more",
    buttonApply: "Apply",
    buttonTransfer: "Transfer",
    buttonContinue: "Continue",
    buttonNextStep: "Next step",
    buttonLogOut: "Log out",
    buttonBack: "Back",
    buttonForgotAccessCode: "Forgot the access code?",
    buttonNotRegistered: "I am not registered",
    buttonSendNewSMS: "Send a new SMS code",
    buttonFillOutApplication: "Fill out the application",
    buttonPay: "Pay",
    buttonBlockCard: "Block card",
    buttonCardReissue: "Card reissue",
    buttonLimits: "Limits",
    buttonShow: "Show",
    buttonReissue: "Reissue",
    buttonBlock: "Block",
    buttonGoToSupportChat: "Go to support chat",
    buttonCopy: "Copy",
    buttonSend: "Send",
    buttonSendQR: "Send QR",
    buttonCreateNewQRCode: "Create a new QR code",

    //Titles
    titlePersonalAccount: "Personal account",
    titleCreditType: "Type of credit",
    titleCardType: "Card type",
    titleOrderCard: "Order a card",
    titleTransferType: "Type of transfer",
    titleHistoryOperations: "History of operations",
    titleCredit: "Credit",
    titleCredits: "Credit",
    titleCreditCard40: "Credit card Up to 40 days without interest",
    titleCreditCards: "Credit cards",
    titleDebitCards: "Debit cards",
    titleMicroloan: "Microloan",
    titleMicroloans: "Microloans",
    titleAutoCredit: "Auto loan",
    titleAutoCredits: "Auto loans",
    titleMortgage: "Mortgage",
    titleCards: "cards",
    titleNotifications: "Notifications",
    titleWelcome: "Welcome",
    titleAuthorization: "Authorization",
    titleSentSMSWithCode: "We have sent you an SMS with a code. Enter its in the field below.",
    titleSelectCitizenship: "Select citizenship",
    titleComeUpAccessCode: "Come up with an access code to set up authorization",
    titleContactSupport: "Contact support",
    titleAdditionalTerms: "Additional terms",
    titleInstallmentCard: "Installment card",
    titleWeSentAnSMS: "We have sent you an SMS with a code. Enter it in the field below.",
    titleQRTranslation: "QR translation",
    titleBetweenYourAccounts: "Between your accounts",
    titleToAnyBankCard: "To a card of any bank",
    titleInternationalTransfers: "International transfers",
    titleOnlineConversion: "Online conversion",
    titleByDetails: "By details",
    titleFavorites: "Favorites",
    titleAllSuppliers: "All suppliers",
    titlePreviouslyPaid: "Previously paid",
    titleUnfinishedOperations: "Unfinished operations",
    titleOrderCarLoan: "Order for a car loan",
    titleAccounts: "Accounts",
    titleCardBalance: "Card balance",
    titleBonuses: "Bonuses",
    titleRequisites: "Requisites",
    titleCardDetails: "Card details",
    titleExtract: "Extract",
    titleCardBlocked: "Card blocked",
    titleShowFullNumber: "Show full number",
    titleBlockCard: "Block card",
    titleSpecifyPeriod: "Specify period",
    titleSelectPeriod: "Select period",
    titlePartialRepayment: "Partial repayment",
    titleFullRepayment: "Full repayment",
    titleAboutLoan: "Loan information",
    titlePaymentSchedule: "Payment schedule",
    titleOnLoan: "on loan",
    titleLinkedAccount: "Linked account",
    titleToRepay: "To repay",
    titlePartially: "partially",
    titleFully: "fully",
    titleAccountInformation: "Account information",
    titleCloseAccount: "Close account",
    titleClosingAccount: "Closing an account",
    titleCashWithdrawal: "Cash withdrawal",
    titleDetailedAccountTerms: "Detailed account terms",
    titleAccountDetails: "Account details",
    titleSendAccountDetails: "Send account details",
    titleSendQRCode: "Send QR code",
    titleNewQRCode: "New QR code",
    titleMyQRCode: "My QR code",
    titleNew: "new",
    titleIdentification: "Identification",
    titleAccessCodeSaveSuccessfully: "New access code configured successfully",


    //labels
    labelBIK: "BIK",
    labelBankINN: "Bank INN",
    labelBankKPP: "Bank KPP",
    labelSecurityCode: "Your access code",
    labelYourPINFL: "Your PINFL",
    labelPINFL: "PINFL",
    labelCodeFromSMS: "Code from the SMS",
    labelPhoneNumber: "Phone number",
    labelAcceptTermsOffer: "I accept the terms of the offer",
    labelAgreeProcessingPersonalData: "I agree to the processing of my personal data",
    labelCitizenship: "Citizenship",
    labelWorkPlace: "Place of work",
    labelAmount: "Amount",
    labelAmountIncome: "Amount of income",
    labelCreditAmount: "Loan amount",
    labelTransferAmount: "Transfer amount",
    labelPaymentAmount: "Payment amount",
    labelDebtAmount: "amount of debt",
    labelExtraIncome: "Have extra income",
    labelPrice: "Price",
    labelInitialPayment: "An initial fee",
    labelCreditTerm: "Loan term",
    labelInsurance: "Insurance",
    labelCarBrand: "Car brand",
    labelCarModel: "Car model",
    labelCardCreditLimit: "Card credit limit",
    labelDeliveryMethod: "Select a receiving method",
    labelPickUpDepartment: "I'll pick it up at the department",
    labelCourierDelivery: "Courier delivery",
    labelSelectBranch: "Select a branch",
    labelSelectDeliveryCity: "Select the delivery city",
    labelSelectStreet: "Select street",
    labelHouse: "House",
    labelApartment: "Apartment",
    labelEntrance: "Entrance",
    labelFloor: "Floor",
    labelComment: "Comment",
    labelMyVISACard: "My VISA card",
    labelLinkedCard: "Linked card",
    labelFrom: "From",
    labelWhere: "Where",
    labelRecipientFullName: "Recipient's full name",
    labelRecipientBank: "Recipient's bank",
    labelChargeAmount: "The amount of the charge",
    labelAmountToBeCredited: "Amount to be credited",
    labelAccountNumber: "Account number",
    labelRecipientAccountNumber: "Recipient's account number",
    labelRecipientIIN: "Recipient's IIN",
    labelRecipient: "Recipient",
    labelAmountToBePaid: "Amount to be paid",
    labelNumberAccountOrPhone: "Account/phone number",
    labelCarPurchasing: "Purchasing a car",
    labelCurrentAccount: "Current account",
    labelDepositAccount: "Deposit account",
    labelReason: "Reason",
    labelBlockReason: "Block reason",
    labelPerMonth: "Per month",
    labelForHalfYear: "For half a year",
    labelForYear: "For the year",
    labelTotalContributed: "total contributed",
    labelAccountBalance: "Account balance",
    labelCorrespondentAccount: "Correspondent account",
    labelWhereToTopUp: "Where to top up",

    //placeholders
    placeholderEnterSecurityCode: "Enter the access code",
    placeholderEnterCode: "Enter the code",
    placeholderEnterPINFL: "Enter PINFL",
    placeholderConfirmCode: "Confirm the code",
    placeholderSelectFromList: "Select from the list",
    placeholderYourWorkPlace: "Your place of work",
    placeholderEnterAmount: "Enter amount",
    placeholderEnterAmountIncome: "Enter the amount of income",
    placeholderEnterLoanAmount: "Enter loan amount",
    placeholderEnterLimitAmount: "Card credit limit",
    placeholderEnterHousingCost: "Enter the cost of housing",
    placeholderEnterBrand: "Enter the brand",
    placeholderEnterModel: "Enter model",
    placeholderHouseNumber: "House number",
    placeholderApartmentNumber: "Apartment number",
    placeholderEntranceNumber: "Entrance number",
    placeholderNotNecessary: "(not necessary)",
    placeholderEnterFullName: "Enter full name",
    placeholderEnterCardNumber: "Enter the card number",
    placeholderSearchPayment: "Search for payment",
    placeholderEnterNumberAccountOrPhone: "Enter account/phone number",
    placeholderChooseReason: "Choose a reason",
    placeholderEnterNameOnCard: "Enter the name shown on the card",
    placeholderDescribeReason: "Describe the reason for blocking (optional)",
    placeholderEnterEmail: "Enter Email",

    //errors
    errorRequired: "Required field",
    errorMinLength: 'Must contain more than {val, plural, one {# character} other {# characters}}',
    errorLength: 'Must contain {val, plural, one {# character} other {# characters}}',
    errorNumber: 'Must contain {val, plural, one {# digit} other {# digits}}',
    errorNotEnoughLength: 'Too few characters',
    errorNeedDate: 'Must contain date',
    errorLatitudeFormat: 'Does not match the latitude format',
    errorLongitudeFormat: 'Does not match the longitude format',
    errorLongLatFormat: 'Does not match the latitude and longitude format',
    errorEmailFormat: 'Does not match the e-mail format',
    errorPasswordsDontMatch: "Passwords don't match",
    //header
    headerLogin:"Login",
    headerRegistration: "Registration",
    headerSettings: "Settings",
    //SettingsList
    settingsListCards: "Cards",
    settingsListPayments: "Payments",
    settingsListCredits: "Credits",
    settingsListTransfers: "Transfers",
    settingsListSupport: "Support",
    settingsListMyBank: "My bank",
    settingsListScanQR: "Scan QR",
    settingsListApplicationLanguage: "Application language",
    settingsListChangeAccessCode: "Changing the access code",

    //Support
    supportContactByPhone: "Contact by phone",
    supportContactByEmail: "Contact by email",
    supportContactByTelegram: "Telegram Chat",

    //TextMessage
    messageOrderSuccess: "Your application has been sent",
    messageOrderSuccessDescription: "Your application has been sent",
    messageOrderSuccessDescriptionSubText: "If processing your application takes a little longer," +
      " you will be redirected to the next step. The approval status will be sent via SMS.",
    messageCardBeReady: "The card will be ready in 2-5 days.",
    messageTranslationCompleted: "Translation completed",
    messageTransferSentSuccessfully: "Your transfer has been sent successfully",

    //anotherText
    textMaximumAmount: "Maximum amount",
    textTransferCourse: "The course at the time of transfer",
    textCommission: "Commission",
    textStrictlyEqualPaymentAmount: "Please note: when paying for the services of some suppliers," +
      " you must enter a payment amount strictly equal to the payment amount indicated on the supplier's receipt." +
      " If you do not have a receipt, you can check the required payment amount directly with the supplier.",
    textPayBefore: "Pay before",
    textCurrentCardWillBeClosed: "The current card will be closed, and the new one will have other details.",
    textPayLoanToday: "Pay off the loan today",
    textYouAreOverdue: "You are overdue",
    textMandatoryPayment: "Mandatory payment",
    textBefore: "before",
    textNotCharged: "not charged",
    textHaveBankCardIssued: "If you have a bank card or loan account issued," +
      " then from the moment the loan is closed, its servicing is carried out" +
      " in accordance with the Bank's tariffs.",
    textContactSupportForClose: "To close your account, contact bank support. According to the bank's rules, the account will be closed after " +
      "{val, plural, one {# day} other {# days}}.",
    textAccountForBasicTransactions: "Account for basic transactions in various currencies.",
    textAccountWithoutCommission: "Account maintenance without commission",
    textBanksPartnersWithoutCommission: "In banks and partners without commission.",
    textTransfersWithinLimits: "Transfers within the limits of the tariff package.",
    textShowQRToPerson: "Show the QR to the person who will translate for you",
    textPointCameraAtQRCode: "Point your camera at the QR code",
    textAppWillAutomaticallyRecognize: "The application will automatically recognize encrypted information and insert it into the appropriate fields.",
    textPasswordMustHave: "Password must contain {val, plural, one {# digit} other {# digits}}",
    textIdentificationHasBeenCompleted: "The identification process has been successfully completed.",
  },
  [LOCALES.UZBEK]: {

  }
}