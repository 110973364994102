import React, {Component} from 'react';
import {connect} from 'react-redux';
import classes from "./calendar.module.scss"
import Header from "../../../../Header";
import BottomMenu from "../../../../ui/menu/BottomMenu";
import {Col, Row} from "antd";
import Wrapper from "../../../../ui/wrapper";
import Icon from "../../../../ui/Icon";
import withRouter from "../../../../../hoc/withRouter";
import {setBackLink} from "../../../../../store/actions/authorization";
import dayjs from 'dayjs'
import {FormattedMessage} from "react-intl";


class Calendar extends Component {

  componentDidMount() {
    const {setBackLink, location: {pathname}, params:{id}} =this.props
    setBackLink(`/${pathname.split("/")[1]}/${pathname.split("/")[2]}/${id}`)
  }

  render() {
    const{params:{id}} = this.props
    const data = [
      {
        year: 2023,
        list: [
          {
            date: dayjs('2024-02-22').format('D MMMM'),
            value: "247 145.87 UZS",
            success: true,
          },
          {
            date: dayjs('2024-02-20').format('D MMMM'),
            value: "247 145.87 UZS",
            success: true,
          },
        ]
      },
      {
        year: 2022,
        list: [
          {
            date: dayjs('2024-01-20').format('D MMMM'),
            value: "247 145.87 UZS",
            success: false,
          },
          {
            date: dayjs('2024-02-22').format('D MMMM'),
            value: "247 145.87 UZS",
            success: false,
          },
          {
            date: dayjs('2024-03-22').format('D MMMM'),
            value: "247 145.87 UZS",
            success: true,
          },
          {
            date: dayjs('2024-04-02').format('D MMMM'),
            value: "247 145.87 UZS",
            success: false,
          },
        ]
      },
      {
        year: 2021,
        list: [
          {
            date: dayjs('2024-01-20').format('D MMMM'),
            value: "247 145.87 UZS",
            success: false,
          },
          {
            date: dayjs('2024-02-22').format('D MMMM'),
            value: "247 145.87 UZS",
            success: false,
          },
          {
            date: dayjs('2024-03-22').format('D MMMM'),
            value: "247 145.87 UZS",
            success: true,
          },
          {
            date: dayjs('2024-04-02').format('D MMMM'),
            value: "247 145.87 UZS",
            success: false,
          },
        ]
      },
    ]
    return (
      <div className={classes.wrapper}>
        <Header title={<FormattedMessage id="titlePaymentSchedule" />} back={`/myBank/credit/${id}`} empty />
        <div style={{marginTop:24}}>

        </div>
        {
          data.map(el => {
            return(
              <Wrapper nopadding transparent>
                <h4>{el.year}</h4>
                <Row gutter={[8,8]}>
                  {el.list.map(i => {
                    return (
                      <Col span={12}>
                        <Wrapper nomargin>
                          <div className={classes.item}>
                            <div className={classes.icons}>
                              <Icon name="icon-calendar"/>
                              {i.success && <Icon name={"icon-tick-circle " + classes.success }/>}
                            </div>
                            <div className={classes.date}>
                              {i.date}
                            </div>
                            <div>
                              {i.value}
                            </div>
                          </div>
                        </Wrapper>
                      </Col>
                    )
                  })}
                </Row>
              </Wrapper>
            )
          })
        }
        <BottomMenu/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setBackLink: (backLink) => dispatch(setBackLink({backLink})),
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Calendar));