import React from 'react';
import {Flex} from "antd";
import classes from "./cardListItem.module.scss"
import Services from "../../../../services/Services";
import Icon from "../../Icon";
import mapClassName from "../../../../services/mapClassName";
import {Link, NavLink, Navigate} from "react-router-dom";

const CardListItem = (props) => {
  const {data} = props
  const classNames = () => {
    const style = ["green"]
    const property = Object.keys(props)
    return mapClassName(property.filter(i => style.includes(i)), classes)
  }

  const cardType = (type) => {
    switch (type) {
      case 1:
        return "visa"
      case 2:
        return "mc"
      case 3:
        return "uzCard"
      case 4:
        return "humo"
    }
  }

  return (
    <div className={classes.wrapper} onClick={() => props.navigate(data.link)}>

        {
          data.icon &&<div>
            <div className={`${classes.icon} ${classNames()}`}>
              <Icon name={data.icon}/>
            </div>
          </div>

        }
        {
          data.card &&
          <div className={Services.mapClassName(["card", cardType(data?.card.type)], classes)}>
            {data.card?.number && <span className={classes.number}>{data.card.number}</span>}
          </div>
        }
        <div className={classes.info}>
          <div className={classes.label}>{data.label}</div>
          <div className={classes.description}>{data.description}</div>
        </div>
        <div className={classes.value}>
          <div><div className={classes.summ + ` ${data?.status === 1 ? classes.success : data?.status === 2 ? classes.process : "" }`}>{data.value}</div></div>
          {
            data.cashback &&
            <div><div className={classes.cashback}>{Services.mapIconItem("iconCrown")}{data.cashback}</div></div>
          }
          {
            data.payment &&
            <div><div className={classes.payment}>{data.payment}</div></div>
          }
          {
            data.debt &&
            <div><div className={classes.debt}>{data.debt}</div></div>
          }
          {
            data.score &&
            <div><div className={classes.score}>{data.score}</div></div>
          }
        </div>


      </div>
  );
};

export default CardListItem;