import React, {Component} from 'react';
import {connect} from 'react-redux';
import classes from "./accountRequisites.module.scss"
import Header from "../../../../Header";
import BottomMenu from "../../../../ui/menu/BottomMenu";
import Wrapper from "../../../../ui/wrapper";
import {Col, List, QRCode, Row} from "antd";
import Icon from "../../../../ui/Icon";
import mapIconItem from "../../../../../services/mapIconItem";
import PrimaryButton from "../../../../ui/buttons/PrimaryButton";
import withRouter from "../../../../../hoc/withRouter";
import ContentModal from "../../../../ui/modal/ContentModal/ContentModal";
import {setBackLink} from "../../../../../store/actions/authorization";
import {FormattedMessage} from "react-intl";



class AccountRequisites extends Component {
  state = {
    sendForm: false,
    sendQrForm: false,
  }

  componentDidMount() {
    const {setBackLink, location: {pathname}, params:{id}} =this.props
    setBackLink(`/${pathname.split("/")[1]}/${pathname.split("/")[2]}/${id}`)
  }

  render() {
    const{params:{id}} = this.props
    const data = [
      {
        label: <FormattedMessage id="labelRecipient" />,
        value: "AINURA KAIDAROVA",
      },
      {
        label: <FormattedMessage id="labelAccountNumber" />,
        value: "16103000200000440001",
      },
      {
        label: <FormattedMessage id="labelRecipientBank" />,
        value: "JSC ALOHABANK",
      },
      {
        label: <FormattedMessage id="labelBIK" />,
        value: "JSCLUZ222",
      },
      {
        label: <FormattedMessage id="labelBankINN" />,
        value: "6573946393",
      },
      {
        label: <FormattedMessage id="labelBankKPP" />,
        value: "658465645",
      },
      {
        label: <FormattedMessage id="labelCorrespondentAccount" />,
        value: "2100284090001176001",
      },
    ]
    const joinValues = (arr, j1, j2) => {
      return arr.map(function(o) {
        return Object.keys(o).map(function(k) {
          return o[k];
        }).join(j1)
      }).join(j2);
    }

    const showModal = (state) => {

      const prevState = this.state[state]
      this.setState({
        [state]: !prevState
      });
    }

    const sendFormData = {
      title: <><FormattedMessage id="titleSendAccountDetails" />:</>,
      iconList: [
        {
          link: "",
          icon: "telegram",
        },
        {
          link: "",
          icon: "whatsapp",
        },
        {
          link: "",
          icon: "facebook",
        },
        {
          link: "",
          icon: "mail",
        },
      ],
    }

    const sendQrFormData = {
      title: <><FormattedMessage id="titleSendQRCode" />:</>,
      iconList: [
        {
          link: "",
          icon: "telegram",
        },
        {
          link: "",
          icon: "whatsapp",
        },
        {
          link: "",
          icon: "facebook",
        },
        {
          link: "",
          icon: "mail",
        },
      ],
    }

    const sendForm = (data, id) => {
      return <div className={classes.modalPadding}>
        <Row gutter={[16,16]}>
          <Col span={24}>
            <h3>{data?.title}</h3>
            {
              data?.text &&
              <p>{data?.text}</p>
            }
            {
              data?.iconList && <Row justify="center" gutter={[16,16]}>
                {
                  data?.iconList?.map((el, ind) => {
                    return <Col >
                      <a href={el.link} className={classes.icon}>{mapIconItem(el.icon)}</a>
                    </Col>
                  })
                }
              </Row>
            }
          </Col>
          <Col span={24}>
            <Row gutter={[16,16]}>
              <Col span={24}>
                <PrimaryButton bordered onClick={() => showModal(id)} title={<FormattedMessage id="buttonBack" />} small/>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    }

    return (
      <div className={classes.wrapper}>
        <Header back={`/myBank/account/${id}`} title={<FormattedMessage id="titleAccountDetails" />} empty />
        <Wrapper style={{margin: "24px,0", paddingBottom: 24}}>
          <List
            dataSource={data}
            renderItem={(item) => (
              <List.Item
                size="small"
              >
                <div className={classes.item}>
                  <label>{item.label}</label>
                  <div className={classes.value}>{item.value}</div>
                </div>
              </List.Item>
            )}
          />
          <div className={classes.qr}>
            <QRCode
              size={206}
              bordered={false}
              value={joinValues(data, ",", ";") || '-'}
            />
          </div>
          <Row gutter={[16,16]}>

            <Col span={24}>
              <PrimaryButton title={<FormattedMessage id="buttonCopy" />} />
            </Col>
            <Col span={12}>
              <PrimaryButton bordered title={<FormattedMessage id="buttonSend" />} onClick={()=>showModal("sendForm")} />
            </Col>
            <Col span={12}>
              <PrimaryButton bordered title={<FormattedMessage id="buttonSendQR" />} onClick={()=>showModal("sendQrForm")} />
            </Col>
          </Row>
        </Wrapper>
        <ContentModal
          open={this.state.sendForm}
          showModal={() => showModal("sendForm")}
          data={sendForm(sendFormData, "sendForm")}
        />
        <ContentModal
          open={this.state.sendQrForm}
          showModal={() => showModal("sendQrForm")}
          data={sendForm(sendQrFormData, "sendQrForm")}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setBackLink: (backLink) => dispatch(setBackLink({backLink})),
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(AccountRequisites));