import React, {Component} from 'react';
import {connect} from 'react-redux';
import classes from "./cardRequisites.module.scss"
import Header from "../../../../Header";
import Wrapper from "../../../../ui/wrapper";
import Card from "../../../../ui/infoBlocks/Card";
import Icon from "../../../../ui/Icon";
import ServiceList from "../../../../ui/infoBlocks/ServiceList";
import ListMenu from "../../../../ui/menu/ListMenu";
import BottomMenu from "../../../../ui/menu/BottomMenu";
import withRouter from "../../../../../hoc/withRouter";
import {setBackLink} from "../../../../../store/actions/authorization";
import {FormattedMessage} from "react-intl";

class CardRequisites extends Component {
  state = {
    showCvc: false,
    showNumber: false,
  }

  componentDidMount() {
    const {setBackLink, location: {pathname}, params:{id}} =this.props
    setBackLink(`/${pathname.split("/")[1]}/${pathname.split("/")[2]}/${id}`)
  }

  render() {
    const {params: {id}} = this.props
    const {showCvc, showNumber} = this.state
    const changeShow = (name) => {
      const prevState = this.state[name]
      this.setState({[name]: !prevState})
    }

    const src = [
      {
        text: <FormattedMessage id='titleShowFullNumber' />,
        icon: "icon-eye",
        action: () => changeShow("showNumber")
      },
      {
        text: <><FormattedMessage id='buttonShow' /> CVC/CVV</>,
        icon: "icon-check",
        action: () => changeShow("showCvc")
      },
    ]

    const cardData = {
      blocked: false,
      requisites: {
        balance: "190 182.22 UZS",
        bonus: "563.1 б.",
        cardNumber: showNumber ? "8574 2532 4405 2034 5576" : "•••• •••• •••• •••• ••••",
        cardDate: "03/25",
        cardCode: showCvc ? "356" : "•••",
        cardholderName: "AINURA KAIDAROVA",
        type: 2
      }
    }

    return (
      <div className={classes.wrapper}>
        <Header
          transparent
          back={`/myBank/card/${id}`}
          title={<FormattedMessage id='titleRequisites' />}
          showInfo={()=>{}}
        />
        <Wrapper nopadding style={{marginBottom: 24}}>
          <Card data={cardData}/>
        </Wrapper>

        <Wrapper nopadding>
          <ListMenu borderIcon data={src}/>
        </Wrapper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setBackLink: (backLink) => dispatch(setBackLink({backLink})),
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(CardRequisites));