import React from 'react';
import classes from './langSelect.module.scss'
import Rus from "../../../../images/language/flag-ru.svg"
import Down from "../../../../images/language/arrow_down.svg"
import mapIconItem from "../../../../services/mapIconItem";
import {LOCALES} from "../../../../i18n/locales";

const LangSelect = (props) => {
  const {lang, onSelect} = props

  const selectedLanguage = (language) => {
    switch (language) {
      case LOCALES.RUSSIAN:
        return <><span className={classes.flag}>{mapIconItem("flagRu")}</span><span className={classes.text}>RU</span></>
      case LOCALES.ENGLISH:
        return <><span className={classes.flag}>{mapIconItem("flagEn")}</span><span className={classes.text}>EN</span></>
      default:
        return <><span className={classes.flag}>{mapIconItem("flagRu")}</span><span className={classes.text}>RU</span></>
    }
  }

  return (
    <a className={classes.lang} onClick={() => onSelect()}>
      {selectedLanguage(lang)}
      {mapIconItem("arrowDown")}
    </a>
  );
};

export default LangSelect;