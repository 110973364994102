import React from 'react';
import {Select} from "antd";
import CardListItem from "../../infoBlocks/CardListItem";
import classes from "./paymentSelect.module.scss"
import mapIconItem from "../../../../services/mapIconItem";
import ProviderListItem from "../../infoBlocks/ProviderListItem";
import {useIntl} from "react-intl";

const PaymentSelect = (props) => {
  const { Option } = Select;
  const intl = useIntl()
  return (
    <div className={classes.wrapper} id="paymentSelect">
      <Select
        className={classes.select}
        suffixIcon={mapIconItem("arrowDown")}
        getPopupContainer={() => document.getElementById("paymentSelect")}
        {...props}
        placeholder={props.locale ? intl.formatMessage({id: props.locale}): props.placeholder ? props.placeholder : ""}
      >
        {props.data.map((el,ind)=>{
          return <Option className={classes.option} key={ind} value={el.value}>
            {
              el.card && <CardListItem data={el} />
            }
            {
              el.bank && <ProviderListItem data={el.bank} />
            }
          </Option>
        })}
      </Select>
    </div>
  );
};

export default PaymentSelect;