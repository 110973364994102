import React, {Component} from 'react'
import Header from "../../../Header"
import {Col, Flex, Row} from "antd"
import Wrapper from "../../../ui/wrapper"
import mapIconItem from "../../../../services/mapIconItem"
import classes from "./notification.module.scss"
import Icon from "../../../ui/Icon";
import {changeInput, setBackLink, signIn} from "../../../../store/actions/authorization";
import withRouter from "../../../../hoc/withRouter";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";

class Notification extends Component {
  render() {
    const {backLink, config} = this.props
    const data = [
      {
        date: "20.05.2024",
        message: [
          {
            icon: "icon-send-2",
            title: "Ваше мнение - это важно!",
            text: [
              "Оцените работу нашего консультанта.",
              "<a href=''>Подробнее</a>"
            ]
          }
        ]
      },
      {
        date: "14.05.2024",
        message: [
          {
            icon: "icon-password-check",
            title: "Одноразовые пароли",
            text: [
              "Никому не сообщайте код: <b>11122&</b><br/>Списание 1000 UZS в Internet-shop."
            ]
          },
          {
            icon: "icon-strongbox-2",
            title: "Снятие с депозита",
            text: [
              "Снятие с депозита UZ•7473<br/> на сумму: <b>183 000 UZS</b>",
              "Доступно: <b>465 000 000 UZS</b>"
            ]
          },
        ]
      },
      {
        date: "10.05.2024",
        message: [
          {
            icon: "icon-flash2",
            title: "2$ за перевод на зарубежные карты!",
            text: [
              "Совершайте мгновенные переводы по самым низким тарифам на зарубежные карты."
            ]
          },
        ]
      },
    ]

    return (
      <div className={classes.wrapper}>
        <Row gutter={[0,16]}>
          <Col span={24}>
            <Header
              back={backLink}
              title={<FormattedMessage id="titleNotifications" />}
              empty={24}
            />
          </Col>
          <Col span={24}>
            <Row >
              {
                config?.notificationList.map((el, num) => {
                  return (
                    <Col span={24}>
                      <Flex gap="middle" align="center" vertical>
                        <div className={classes.date + `${num === 0 ? " " + classes.new : ""}`}>{num === 0 ? <FormattedMessage id="titleNew" /> : el.date}</div>
                      </Flex>
                      {
                        el.message.map((i,key) => {
                          return (
                            <Wrapper key={key}>
                              <Row gutter={[8,8]}>
                                <Col span={24}>
                                  <Flex gap={8} wrap={false} align={"center"}>
                                    <div className={classes.icon}><Icon name={i.icon}/></div>
                                    <div className={classes.title}>
                                      {i.title}
                                    </div>
                                  </Flex>
                                </Col>
                                <Col span={24}>
                                  <Row gutter={[8,8]}>
                                    {
                                      i.text.map((t, ind) => {
                                        return <Col key={ind} span={24} className={classes.text}><div dangerouslySetInnerHTML={{__html: t}}/></Col>
                                      })
                                    }
                                  </Row>
                                </Col>
                              </Row>
                            </Wrapper>
                          )
                        })
                      }
                    </Col>
                  )
                })
              }
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
};

function mapStateToProps(state) {
  return {
    backLink : state.authorization.backLink
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notification));