import React, {Component} from 'react';
import {connect} from 'react-redux';
import classes from "./qrcodeRequisites.module.scss"
import withRouter from "../../../../../hoc/withRouter";
import Header from "../../../../Header/Header";
import Wrapper from "../../../../ui/wrapper";
import {Col, Divider, Form, notification, QRCode, Row, Tabs} from "antd";
import Icon from "../../../../ui/Icon";
import PrimaryButton from "../../../../ui/buttons/PrimaryButton";
import ContentModal from "../../../../ui/modal/ContentModal/ContentModal";
import mapIconItem from "../../../../../services/mapIconItem";
import PaymentSelect from "../../../../ui/selects/PaymentSelect";
import InputNumber from "../../../../ui/inputs/InputNumber";
import Services from "../../../../../services/Services";
import {setBackLink} from "../../../../../store/actions/authorization";
import sources from "../../../../../source/sources";
import {FormattedMessage} from "react-intl";



class QRCodeRequisites extends Component {
  myQRForm = React.createRef()

  state = {
    openModal: false,
    step: 1,
  }

  componentDidMount() {
    const {setBackLink, location: {pathname}, params:{id}} = this.props
    if(pathname.split("/")[1] === "transfers"){
      setBackLink(`/${pathname.split("/")[1]}/${id}`)
    } else {
      setBackLink(`/`)
    }
  }

  render() {
    const {navigate, backLink} = this.props
    const {openModal, step} = this.state
    const getRules = Services.formValidateRules

    const joinValues = (arr, j1, j2) => {
      return arr.map(function(o) {
        return Object.keys(o).map(function(k) {
          return o[k];
        }).join(j1)
      }).join(j2);
    }

    const notificationConfig = {
      className: "successNotification",
      closeIcon: false,
      message: <span>Перевод выполнен</span>,
      description: <>
        Ваш перевод успешно
        отправлен
      </>,
      icon: <Icon name="icon-verify" color={"#95D319"} size={32}/>,
      placement: "top",
    }

    const onStepChange = (currentStep, nextStep, maxStep) => {
      this.myQRForm.current.validateFields().then(() =>{
        this.setState({step: nextStep})
      })
      if(currentStep === maxStep) {
        this.myQRForm.current.validateFields().then(() =>{
          this.setState({step: 1})
          this.myQRForm.current.resetFields()
          //notification.success(notificationConfig)
        })
      }
    }

    const demoData = [
      {
        label: <FormattedMessage id="labelRecipient" />,
        value: "AINURA KAIDAROVA",
      },
      {
        label: <FormattedMessage id="labelAccountNumber" />,
        value: "16103000200000440001",
      },
      {
        label: <FormattedMessage id="labelRecipientBank" />,
        value: "JSC ALOHABANK",
      },
      {
        label: <FormattedMessage id="labelBIK" />,
        value: "JSCLUZ222",
      },
      {
        label: <FormattedMessage id="labelBankINN" />,
        value: "6573946393",
      },
      {
        label: <FormattedMessage id="labelBankKPP" />,
        value: "658465645",
      },
      {
        label: <FormattedMessage id="labelCorrespondentAccount" />,
        value: "2100284090001176001",
      },
    ]

    const paymentItems = [
      {
        blocked: false,
        card: {
          type: 1,
          number: '• 7811',
        },
        balance: '182.87 UZS',
        label: <FormattedMessage id='labelMyVISACard' />,
        description: 'RUB, UZS',
        value: '182.87 UZS',
      },
      {
        blocked: false,
        card: {
          type: 4,
          number: '• 7811',
        },
        balance: '247 145.87 UZS',
        label: <FormattedMessage id='labelLinkedCard' />,
        description: 'USD, RUB, UZS',
        value: '190 182.22 UZS',
      },
      {
        blocked: false,
        card: {
          type: 3,
          number: '• 7846',
        },
        balance: '46 718.22 UZS',
        label: <FormattedMessage id='labelLinkedCard' />,
        description: 'USD, RUB',
        value: '194.22 UZS',
      },
      {
        blocked: false,
        card: {
          type: 2,
          number: '• 7846',
        },
        balance: '46 718.22 UZS',
        label: <FormattedMessage id='labelLinkedCard' />,
        description: 'USD, RUB',
        value: '3 684.22 UZS',
      },
    ]

    const qrTransferItems = [
      {
        name: "paymentTo",
        label: <FormattedMessage id='labelWhereToTopUp' />,
        rules: getRules("required"),
        children: <PaymentSelect data={paymentItems} locale="placeholderChooseReason"/>
      },
      {
        name: "transferAmount",
        label: <FormattedMessage id='labelTransferAmount' />,
        children: <InputNumber placeholder="Введите сумму" locale="placeholderEnterAmount" addonAfter={"сум"}/>,
        col: 24,
        rules: getRules("required"),
      },
    ]

    const qrGeneratedRequisites = (
      <>
        <Row gutter={[16,16]}>
          <Col span={24}>
            <div className={classes.qr}>
              <QRCode
                size={280}
                bordered={false}
                value={joinValues(demoData, ",", ";") || '-'}
              />
            </div>
          </Col>
          <Col span={24}>
            <p className={classes.textCenter}>
              <span className={classes.supText}><FormattedMessage id='labelTransferAmount' />:</span>
              <h2>10 000 UZS</h2>
            </p>
            <div className={classes.textCenter}>
              <Icon name="icon-info-circle" size={16}/>
              <p>
                <FormattedMessage id='textShowQRToPerson' />
              </p>
            </div>
            <Divider/>
          </Col>
        </Row>

      </>
    )


    const qrTransferForm = [
      {
        key: 1,
        children: <Row>
          <Col span={24}>
            {
              Services.mapFormItem(qrTransferItems, this.myQRForm)
            }
            <p className={classes.supText}>
              <span><FormattedMessage id='textMaximumAmount' />: 60 000 000 сум</span>
            </p>
            <Divider/>
          </Col>
        </Row>
      },
      {
        key: 2,
        children: qrGeneratedRequisites
      },
    ]

    const onSendQR = (val) => {
      this.setState({openModal: val})
    }



    return (
      <div className={classes.wrapper}>
        <Header
          back={backLink}
          title={step === 1 ? <FormattedMessage id='titleNewQRCode' /> : <FormattedMessage id='titleMyQRCode' />}
          showInfo={step === 1 ? () => {} : false}
          sendQRCode={step === 2 ? onSendQR : false}
        />

        <Wrapper>
          <Row gutter={[16,16]}>
            <Col span={24}>
              <Form ref={this.myQRForm}>
                <Tabs
                  activeKey={step}
                  animated
                  items={qrTransferForm}
                  //onChange={id => onStepChange(step, id, tabsItem.length)}
                  renderTabBar={()=>{}}
                />
              </Form>
              <PrimaryButton
               /* title="Отправить"
                onClick={()=> {this.setState({openModal: true})}}*/
                title={step === 1 ? <FormattedMessage id='buttonContinue' /> : <FormattedMessage id='buttonCreateNewQRCode' />}
                onClick={() => onStepChange(step, step + 1, 2)}
              />
            </Col>
          </Row>
        </Wrapper>

        <ContentModal
          open={openModal}
          showModal={() => {this.setState({openModal: false})}}
          data={
            <Wrapper transparent style={{padding:24}}>
              <Row gutter={[16,24]}>
                <Col span={24}>
                  <h2>
                    <FormattedMessage id='titleSendQRCode' />:
                  </h2>
                </Col>
                <Col span={24}>
                  <Row gutter={[16,16]} justify="center">
                    <Col>
                      {mapIconItem("telegram")}
                    </Col>
                    <Col>
                      {mapIconItem("whatsapp")}
                    </Col>
                    <Col>
                      {mapIconItem("facebook")}
                    </Col>
                    <Col>
                      {mapIconItem("mail")}
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <PrimaryButton
                    title={<FormattedMessage id='buttonBack' />}
                    bordered
                    secondary
                    onClick={() => {this.setState({openModal: false})}}
                  />
                </Col>
              </Row>
            </Wrapper>
          }
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    backLink : state.authorization.backLink
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setBackLink: (backLink) => dispatch(setBackLink({backLink})),
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(QRCodeRequisites));