import React, {Component} from 'react';
import {connect} from 'react-redux';
import classes from "./orderCard.module.scss"
import Header from "../../../../Header/Header";
import withRouter from "../../../../../hoc/withRouter";
import Wrapper from "../../../../ui/wrapper";
import {Col, Form, Input, Row, Tabs, notification as noti} from "antd";
import Select from "../../../../ui/selects/Select";
import TextInput from "../../../../ui/inputs/TextInput";
import Services from "../../../../../services/Services";
import PrimaryButton from "../../../../ui/buttons/PrimaryButton";
import InputNumber from "../../../../ui/inputs/InputNumber";
import {setBackLink} from "../../../../../store/actions/authorization";
import {FormattedMessage} from "react-intl";
import TextArea from "../../../../ui/inputs/TextArea";



class OrderCard extends Component {
  orderForm = React.createRef()

  state = {
    step: 1,
  }

  componentDidMount() {
    const {setBackLink, location: {pathname}, params:{id, link}} =this.props
    setBackLink(`/${pathname.split("/")[1]}/${id}/${link}`)
  }

  render() {
    const {step} = this.state
    const {
      params:{id, link},
      config: {back, title, menuList, notification},
      navigate,
      signIn,
      locale,
    } = this.props
    const getRules = Services.formValidateRules
    const notificationConfig = {
      className: "successNotification",
      closeIcon: false,
      message: <span><FormattedMessage id="messageOrderSuccess" /></span>,
      description: <>
        <FormattedMessage id="messageOrderSuccessDescription" />
        <small>
          <FormattedMessage id="messageOrderSuccessDescriptionSubText" />
        </small>
      </>,
      icon: Services.mapIconItem("wave"),
      placement: "top",
    }

    const onStepChange = (currentStep, nextStep, maxStep) => {
      this.orderForm.current.validateFields().then(() =>{
        this.setState({step: nextStep})
      })
      if(currentStep === maxStep) {
        noti.success(notificationConfig)
        navigate(`/`)
      }
    }

    const deliveryMethodItems = [
      {label: <FormattedMessage id="labelPickUpDepartment" />, value: 1},
      {label: <FormattedMessage id="labelCourierDelivery" />, value: 2}
    ]

    const formCardInfo = [
      {
        name: "cardLimit",
        label: <FormattedMessage id="labelCardCreditLimit" />,
        rules: getRules("required"),
        children: <InputNumber
          placeholder="Введите сумму лимита"
          locale="placeholderEnterLimitAmount"
          addonAfter={"сум"}
        />,
        col: 24,
      },
      {
        name: "deliveryMethod",
        label: <FormattedMessage id="labelDeliveryMethod" />,
        rules: getRules("required"),
        children: <Select
          data={deliveryMethodItems}
          placeholder="Выберите из списка"
          locale="placeholderSelectFromList"
          onChange={e => this.setState({deliveryMethod: e})}
        />,
        col: 24,
      },
      {
        name: "branch",
        label: <FormattedMessage id="labelSelectBranch" />,
        rules: getRules("required"),
        children: <Select
          data={ [1,2].map((el, ind) => {return {label: locale === "ru-RU" ? "Отделение " + (ind + 1) : "Branch " + (ind + 1), value: ind + 1}})}
          placeholder="Выберите из списка"
          locale="placeholderSelectFromList"
        />,
        col: 24,
      },
      {
        name: "city",
        label: <FormattedMessage id="labelSelectDeliveryCity" />,
        rules: getRules("required"),
        children: <Select
          data={[{label: locale === "ru-RU" ? "Москва" : "Moscow", value: 1}]}
          placeholder="Выберите из списка"
          locale="placeholderSelectFromList"
        />,
        col: 24,
      },
      {
        name: "street",
        label: "Выберите улицу",
        rules: getRules("required"),
        children: <Select
          data={[{label: locale === "ru-RU" ? "Малая Никитская" : "Malaya Nikitskaya", value: 1}]}
          placeholder="Выберите из списка"
          locale="placeholderSelectFromList"
        />,
        col: 24,
      },
      {
        name: "home",
        label: <FormattedMessage id="labelHouse" />,
        children: <TextInput rows={5} placeholder="Номер дома" locale="placeholderHouseNumber"/>,
        col: 12,
      },
      {
        name: "apartment",
        label: <FormattedMessage id="labelApartment" />,
        children: <TextInput rows={5} placeholder="Номер квартиры" locale="placeholderApartmentNumber"/>,
        col: 12,
      },
      {
        name: "entrance",
        label: <FormattedMessage id="labelEntrance" />,
        children: <TextInput rows={5} placeholder="Номер подъезда" locale="placeholderEntranceNumber"/>,
        col: 12,
      },
      {
        name: "floor",
        label: <FormattedMessage id="labelFloor" />,
        children: <TextInput rows={5} placeholder="Этаж" locale="labelFloor"/>,
        col: 12,
      },
      {
        name: "comment",
        label: <FormattedMessage id="labelComment" />,
        children: <TextArea rows={5} placeholder={"(не обязательно)"} locale="placeholderNotNecessary"/>,
        col: 24,
      },
    ]

    const formConfirm = [
      {
        name: "smsCode",
        label: <FormattedMessage id="labelCodeFromSMS" />,
        children: <TextInput placeholder="Введите код" locale="placeholderEnterCode"/>,
        col: 24,
        rules: getRules("required"),
      },
    ]

    const cardInfoForm = () => {
      return (
        <>
          {Services.mapFormItem(formCardInfo.filter((el,ind)=> {
            if(this.state.deliveryMethod === 1){
              return id === "credit" ? ind < 3 : ind !== 0 && ind < 3
            } else if (this.state.deliveryMethod === 2){
              return id === "credit" ? ind !== 2 : ind !== 0 && ind !== 2
            } else return id === "credit" ? ind < 2 : ind < 2 && ind > 0

          }), this.orderForm)}
          {
            this.state.deliveryMethod === 2 &&
            <p><FormattedMessage id="messageCardBeReady" /></p>
          }
        </>
      )
    }

    const confirmForm = () => {
      return (
        <>
          <h2 style={{marginBottom: 40}}>
            <FormattedMessage id="titleWeSentAnSMS" />
          </h2>
          {Services.mapFormItem(formConfirm, this.orderForm)}
        </>
      )
    }

    const tabsItem = [
      {
        key: 1,
        children: cardInfoForm()
      },
      {
        key: 2,
        children: confirmForm()
      },
    ]


    return (
      <div className={classes.wrapper}>
        <Header
          back={`/cards/${id}/${link}`}
          title={title}
          notification={ signIn ? notification : false}
          empty={!signIn}
        />
        <Wrapper>
          <Row gutter={[16,16]}>
            <Col span={24}>
              <Form
                ref={this.orderForm}
                size="large"
                layout="vertical"
              >
                <Tabs
                  activeKey={step}
                  animated
                  items={tabsItem}
                  onChange={id => onStepChange(step, id, tabsItem.length)}
                  renderTabBar={()=>{}}
                />
              </Form>
            </Col>
            <Col span={24}>
              <Row gutter={[16,16]}>
                <Col span={24}>
                  <PrimaryButton
                    title={step === tabsItem.length ? <FormattedMessage id="buttonApply" /> : <FormattedMessage id="buttonContinue" />}
                    onClick={() => onStepChange(step, step + 1, tabsItem.length)}
                  />
                </Col>
                {
                  step === tabsItem.length &&
                  <Col span={24}>
                    <PrimaryButton
                      bordered={true}
                      title={<FormattedMessage id="buttonSendNewSMS" />}
                      onClick={()=> {}}
                      timer={step === 2 ? 10 : false}
                    />
                  </Col>
                }
              </Row>
            </Col>
          </Row>
        </Wrapper>
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    signIn: state.authorization.signIn,
    locale: state.authorization.locale,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setBackLink: (backLink) => dispatch(setBackLink({backLink})),
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(OrderCard));