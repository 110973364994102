import React, {Component} from 'react';
import {connect} from 'react-redux';
import classes from "./testStorage.module.scss"
import Header from "../../Header";
import Wrapper from "../../ui/wrapper";
import TextInput from "../../ui/inputs/TextInput";
import {Button, Form, Input} from "antd";
import Cookies from 'universal-cookie';
import PrimaryButton from "../../ui/buttons/PrimaryButton";



class TestStorage extends Component {

  localStorageForm = React.createRef()

  state = {
    localStorage: "",
    sessionStorage: "",
    cookie: ""
  }




  render() {
    const cookies = new Cookies();

    const setStorage = (name, value) => {
      switch (name) {
        case 1:
          localStorage.setItem("payHubStorage", value)
          break;
        case 2:
          sessionStorage.setItem("payHubStorage", value)
          break;
        case 3:
          cookies.set("payHubStorage", value, { path: '/' })
          break;
      }
    }

    const getStorage = () => {
      //const localStorage = localStorage.getItem('payHubStorage')
      //const sessionStorage = sessionStorage.getItem('payHubStorage')
      this.setState({
        localStorage: localStorage.getItem('payHubStorage'),
        sessionStorage: sessionStorage.getItem('payHubStorage'),
        cookie: cookies.get("payHubStorage")
      })
    }

    const clearStorage = () => {
      cookies.remove("payHubStorage")
      localStorage.clear()
      sessionStorage.clear()
      this.setState({
        localStorage: "",
        sessionStorage: "",
        cookie: ""
      })
    }

    return (
      <div className={classes.wrapper}>
        <Header
          loginLink={true}
          languageSelect={true}
        />
        <Wrapper>
          <div className={classes.result}>
            <label>localStorage</label>
            <div>{this.state.localStorage}</div>
            <label>sessionStorage</label>
            <div>{this.state.sessionStorage}</div>
            <label>cookie</label>
            <div>{this.state.cookie}</div>
          </div>

        </Wrapper>
        <Wrapper transparent nopadding>
          <PrimaryButton onClick={() => getStorage()} title="Проверить хранилище"/>
        </Wrapper>
        <Wrapper transparent nopadding>
          <PrimaryButton danger onClick={() => clearStorage()} title="Отчистить хранилище"/>
        </Wrapper>
        <Wrapper>
          <Form ref={this.localStorageForm}>
            <Form.Item
              name={"localStorage"}
              label="localStorage"
            >
              <Input
                addonAfter={<Button className={classes.btn} onClick={() => setStorage(1, this.localStorageForm.current?.getFieldValue("localStorage"))}>Сохранить</Button>}
                style={{width: "100%"}}
              />
            </Form.Item>
            <Form.Item
              name={"sessionStorage"}
              label="sessionStorage"
            >
              <Input
                addonAfter={<Button className={classes.btn} onClick={() => setStorage(2, this.localStorageForm.current?.getFieldValue("sessionStorage"))}>Сохранить</Button>}
                style={{width: "100%"}}
              />
            </Form.Item>
            <Form.Item
              name={"cookie"}
              label="cookie"
            >
              <Input
                addonAfter={<Button className={classes.btn} onClick={() => setStorage(3, this.localStorageForm.current?.getFieldValue("cookie"))}>Сохранить</Button>}
                style={{width: "100%"}}
              />
            </Form.Item>
          </Form>


        </Wrapper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
)(TestStorage);