import React, {Component, useState} from 'react';
import Header from "../../../../Header";
import ServiceList from "../../../../ui/infoBlocks/ServiceList";
import BottomMenu from "../../../../ui/menu/BottomMenu";
import CategoryItem from "../../../../ui/infoBlocks/CategoryItem";
import {Flex, List, Modal, Spin} from "antd";
import classes from "./creditDetail.module.scss";
import mapIconItem from "../../../../../services/mapIconItem";
import PrimaryButton from "../../../../ui/buttons/PrimaryButton";
import {connect} from "react-redux";
import withRouter from "../../../../../hoc/withRouter";
import sources from "../../../../../source/sources";
import Services from "../../../../../services/Services";
import {setBackLink} from "../../../../../store/actions/authorization";
import {FormattedMessage} from "react-intl";

class CreditDetail extends Component {
  state = {
    showModal: false,
    data: [],
    loading: true,
  }

  type = () => {
    const {location: {pathname}} = this.props
    const cat = pathname.split("/").filter(el => el !== "")
    return cat.join("_")
  }

  componentDidMount() {

    const {setBackLink, location: {pathname}, params:{id}, locale} =this.props

    sources.getCreditDetails(this.type(), locale)
      .then(({data}) => {
        console.log("DATA", data)
        this.setState({
          data,
          loading: false,
        })
      }).catch(error => {
      Services.redirectService(error)
    })

    setBackLink(`/${pathname.split("/")[1]}/${id}`)
  }


  render() {
    const  {
      location: {pathname},
      params: { id },
      config: { back, notification },
      navigate,
      signIn,
    } = this.props

    const parseTitle = () => {
      switch (id) {
        case "credit":
          return <FormattedMessage id="titleCredits" />
        case "debit":
          return <FormattedMessage id="titleDebitCards" />
        case "microloans":
          return <FormattedMessage id="titleMicroloans" />
        case "creditOffers":
          return <FormattedMessage id="settingsListCredits" />
        case "cards":
          return <FormattedMessage id="settingsListCards" />
        case "autoCredit":
          return <FormattedMessage id="titleAutoCredits" />
        case "mortgage":
          return <FormattedMessage id="titleMortgage" />
        default: return ""
      }
    }
    const parseCategory = () => {
      const cat = pathname.split("/")[1]
      switch (cat) {
        case "cards":
          return <FormattedMessage id="titleCards" />
        default: return ""
      }
    }

    const setModalState = (state) => {
      this.setState({
        showModal: state
      })
    }

    return (
      <div className={classes.wrapper}>
        <Header
          back={`/${back}/${id}`}
          title={parseTitle()}
          notification={ signIn ? notification : false}
          empty={!signIn}
        />
        {
          this.state.loading ? <Spin fullscreen={true}/> :
          <>
            <CategoryItem
              data={this.state.data?.listData}
              showModal={setModalState}
              navigate={navigate}
              link={pathname + "/order"}
            />
            <Modal
              open={this.state.showModal}
              footer={null}
              centered
              closable={false}
              onCancel={() => setModalState(false)}
              style={{bottom:0}}
            >
              <Flex vertical gap={20}>
                <h2><FormattedMessage id="titleAdditionalTerms" /></h2>
                <List
                  bordered={false}
                  dataSource={this.state.data?.modalData}
                  renderItem={(item) => (
                    <List.Item
                      onClick={()=>{}}
                      size="small"
                    >
                      <div className={classes.item}>
                        <span className={classes.title}>{item.title}</span> <span dangerouslySetInnerHTML={{__html: item.description}} className={classes.description}/>
                      </div>
                    </List.Item>
                  )}
                />
                <PrimaryButton
                  bordered
                  small
                  title={<FormattedMessage id="buttonBack" />}
                  onClick={() => setModalState(false)}
                />
              </Flex>
            </Modal>
          </>
        }
      </div>
    )
  }
};

function mapStateToProps(state) {
  return {
    signIn: state.authorization.signIn,
    locale: state.authorization.locale,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setBackLink: (backLink) => dispatch(setBackLink({backLink})),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreditDetail));