import React, {Component} from 'react';
import {connect} from 'react-redux';
import classes from "./cardReissue.module.scss"
import withRouter from "../../../../../hoc/withRouter";
import Header from "../../../../Header";
import Wrapper from "../../../../ui/wrapper";
import TextInput from "../../../../ui/inputs/TextInput";
import {Col, Form, Input, Row, Tabs} from "antd";
import Services from "../../../../../services/Services";
import PrimaryButton from "../../../../ui/buttons/PrimaryButton";
import Select from "../../../../ui/selects/Select";
import {setBackLink} from "../../../../../store/actions/authorization";
import {FormattedMessage} from "react-intl";



class CardReissue extends Component {
  reissueForm = React.createRef()
  state = {
    step: 1,
    deliveryMethod: 0,
  }

  componentDidMount() {
    const {setBackLink, location: {pathname}, params:{id}} =this.props
    setBackLink(`/${pathname.split("/")[1]}/${pathname.split("/")[2]}/${id}`)
  }

  render() {
    const getRules = Services.formValidateRules
    const {params: {id}, navigate, config} = this.props
    const {step} = this.state

    const onStepChange = (currentStep, nextStep, maxStep) => {

      this.reissueForm.current.validateFields().then(() =>{
        this.setState({step: nextStep})
      })
      if(currentStep === maxStep) {
        navigate(`/myBank/card/${id}`)
      }
    }

    const formCardInfo = [
      {
        name: "reason",
        label: <FormattedMessage id="labelReason" />,
        rules: getRules("required"),
        children: <Select data={config?.reasonList} locale="placeholderChooseReason" placeholder="Выберите причину"/>,
        col: 24,
      },
      {
        name: "cardholderName",
        label: "Имя на карте",
        children: <TextInput locale="placeholderEnterNameOnCard" placeholder="Введите имя указанное на карте"/>,
        col: 24,
      },
      {
        name: "deliveryMethod",
        label: <FormattedMessage id="labelDeliveryMethod" />,
        rules: getRules("required"),
        children: <Select
          data={config?.deliveryMethodList}
          placeholder="Выберите из списка"
          locale="placeholderSelectFromList"
          onChange={e => this.setState({deliveryMethod: e})}
        />,
        col: 24,
      },
      {
        name: "branch",
        label: <FormattedMessage id="labelSelectBranch" />,
        rules: getRules("required"),
        children: <Select
          data={config?.departmentList}
          locale="placeholderSelectFromList"
          placeholder="Выберите из списка"
        />,
        col: 24,
      },
      {
        name: "city",
        label: <FormattedMessage id="labelSelectDeliveryCity" />,
        rules: getRules("required"),
        children: <Select data={config?.cityList} locale="placeholderSelectFromList" placeholder="Выберите из списка"/>,
        col: 24,
      },
      {
        name: "street",
        label: <FormattedMessage id="labelSelectStreet" />,
        rules: getRules("required"),
        children: <Select data={config?.streetList} locale="placeholderSelectFromList" placeholder="Выберите из списка"/>,
        col: 24,
      },
      {
        name: "home",
        label: <FormattedMessage id="labelHouse" />,
        children: <TextInput rows={5} locale="placeholderHouseNumber" placeholder="Номер дома"/>,
        col: 12,
      },
      {
        name: "apartment",
        label: <FormattedMessage id="labelApartment" />,
        children: <TextInput rows={5} locale="placeholderApartmentNumber" placeholder="Номер квартиры"/>,
        col: 12,
      },
      {
        name: "entrance",
        label: <FormattedMessage id="labelEntrance" />,
        children: <TextInput rows={5} locale="placeholderEntranceNumber" placeholder="Номер подъезда"/>,
        col: 12,
      },
      {
        name: "floor",
        label: <FormattedMessage id="labelFloor" />,
        children: <TextInput rows={5} locale="labelFloor" placeholder="Этаж"/>,
        col: 12,
      },
      {
        name: "comment",
        label: <FormattedMessage id="labelComment" />,
        children: <Input.TextArea rows={5} locale="placeholderNotNecessary" placeholder="(не обязательно)"/>,
        col: 24,
      },
    ]

    const formConfirm = [
      {
        name: "smsCode",
        label: <FormattedMessage id="labelCodeFromSMS" />,
        children: <TextInput locale="placeholderEnterCode" placeholder="Введите код"/>,
        col: 24,
        rules: getRules("required"),
      },
    ]

    const cardInfoForm = () => {
      return (
        <>
          {Services.mapFormItem(formCardInfo.filter((el,ind)=> {
            if(this.state.deliveryMethod === 1){
              return ind < 4
            } else if (this.state.deliveryMethod === 2){
              return ind !== 3
            } else return ind < 3

          }), this.reissueForm)}
          <p><FormattedMessage id="textCommission" />: 300 UZS</p>
          <p><FormattedMessage id="textCurrentCardWillBeClosed" /></p>
        </>
      )
    }

    const confirmForm = () => {
      return (
        <>
          <h2 style={{marginBottom: 40}}>
            <FormattedMessage id="titleWeSentAnSMS" />
          </h2>
          {Services.mapFormItem(formConfirm, this.reissueForm)}
        </>
      )
    }

    const tabsItem = [
      {
        key: 1,
        children: cardInfoForm()
      },
      {
        key: 2,
        children: confirmForm()
      },
    ]

    return (
      <div className={classes.wrapper}>
        <Header
          back={`/myBank/card/${id}`}
          title={<FormattedMessage id="buttonCardReissue" />}
          empty
        />
        <Wrapper>
          <Form
            ref={this.reissueForm}
            size="large"
            layout="vertical"
            className={classes.form}
          >
            <Row gutter={[16,16]}>
              <Col span={24}>
                <Tabs
                  activeKey={step}
                  animated
                  className={classes.tabs}
                  items={tabsItem}
                  onChange={id => onStepChange(step, id, tabsItem.length)}
                  renderTabBar={()=>{}}
                />
              </Col>
              <Col span={24}>
                <Row gutter={[16,16]}>
                  <Col span={24}>
                    <PrimaryButton
                      title={step === tabsItem.length ? <FormattedMessage id="buttonApply" /> : <FormattedMessage id="buttonReissue" />}
                      onClick={() => onStepChange(step, step + 1, tabsItem.length)}
                    />
                  </Col>
                  {
                    step === tabsItem.length &&
                    <Col span={24}>
                      <PrimaryButton
                        bordered={true}
                        title={<FormattedMessage id="buttonSendNewSMS" />}
                        onClick={()=> {}}
                        timer={step === 2 ? 10 : false}
                      />
                    </Col>
                  }
                </Row>
              </Col>
            </Row>
          </Form>
        </Wrapper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setBackLink: (backLink) => dispatch(setBackLink({backLink})),
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(CardReissue));