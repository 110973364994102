import React, {Component} from 'react';
import {connect} from 'react-redux';
import img1 from "../../../../../images/demoImage/logo1.png";
import img2 from "../../../../../images/demoImage/logo2.png";
import img3 from "../../../../../images/demoImage/logo3.png";
import img4 from "../../../../../images/demoImage/logo4.png";
import classes from "../Payments/payments.module.scss";
import Header from "../../../../Header";
import Wrapper from "../../../../ui/wrapper";
import Search from "../../../../ui/inputs/Search";
import {Col, Row} from "antd";
import ServiceButton from "../../../../ui/buttons/ServiceButton";
import ProviderList from "../../../../ui/infoBlocks/ProviderList";
import BottomMenu from "../../../../ui/menu/BottomMenu";
import {setBackLink} from "../../../../../store/actions/authorization";
import withRouter from "../../../../../hoc/withRouter";
import {FormattedMessage} from "react-intl";



class Providers extends Component {

  componentDidMount() {
    const {setBackLink, location: {pathname}} =this.props
    setBackLink(`/${pathname.split("/")[1]}`)
  }

  render() {
    const {signIn, config} =this.props

    const favoriteData = [
      {
        icon: img1,
        title: "RentaNet",
        description: "Моб. связь",
        link: signIn ? "internet/RentaNet" : "/signIn"
      },
    ]

    const providersData = [
      {
        icon: img1,
        title: "RentaNet",
        description: "оплата за интернет",
        link: signIn ? "internet/RentaNet" : "/signIn"
      },
      {
        icon: img2,
        title: "RentaNet",
        description: "оплата за интернет",
        link: signIn ? "internet/RentaNet" : "/signIn"
      },
      {
        icon: img3,
        title: "RentaNet",
        description: "оплата за интернет",
        link: signIn ? "internet/RentaNet" : "/signIn"
      },
      {
        icon: img4,
        title: "RentaNet",
        description: "оплата за интернет",
        link: signIn ? "internet/RentaNet" : "/signIn"
      },
    ]

    return (
      <div className={classes.wrapper} style={signIn ? {paddingTop: 72} : {}}>
        <Header
          notification={5}
          profile={"Айнура Кайдарова"}
        />
        <Wrapper nopadding transparent style={{marginTop:24, marginBottom:16}}>
          <Search placeholder="Поиск платежа" locale="placeholderSearchPayment"/>
        </Wrapper>
        <Wrapper nopadding transparent style={{marginTop: 16, marginBottom: 16}}>
          <h4><FormattedMessage id='titlePreviouslyPaid' /></h4>
          <Wrapper nomargin  style={{paddingTop: 8, paddingBottom: 8}}>
            <ProviderList data={config.favoriteData.map((el, ind) => {return{...el, link: signIn ? el.link : "/signIn"}})}/>
          </Wrapper>
        </Wrapper>
        <Wrapper nopadding transparent style={{marginTop: 16, marginBottom: 16}}>
          <h4><FormattedMessage id='titleAllSuppliers' /></h4>
          <Wrapper nomargin style={{paddingTop: 8, paddingBottom: 8}}>
            <ProviderList data={config.providersData.map((el, ind) => {return{...el, link: signIn ? el.link : "/signIn"}})}/>
          </Wrapper>
        </Wrapper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    signIn: state.authorization.signIn,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setBackLink: (backLink) => dispatch(setBackLink({backLink})),
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Providers));