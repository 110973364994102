import React, {Component} from 'react';
import {connect} from 'react-redux';
import BottomMenu from "../../ui/menu/BottomMenu/BottomMenu";
import Header from "../../Header/Header";
import classes from "../IndexPage/index.module.scss";
import Wrapper from "../../ui/wrapper";
import {Col, Row} from "antd";

class ForgotAccessCode extends Component {
  render() {
    return (
      <div className={classes.wrapper}>
        <Header
          loginLink={true}
          languageSelect={true}
        />
        <Wrapper>
          {
            <Row gutter={[16,16]} style={{marginTop: 20}}>
              <Col span={24}>
                <a
                  type="media_type"
                  target={"_blank"}
                  style={{fontSize:20, background: "#007CE9", padding: 20, color: "#ffffff", textAlign: "center", display: "block", borderRadius:16}}
                  href="https://devmyid.uz/api/v1/oauth2/authorization?client_id=payhub_redirect-V5aeynDInnlj0jSd0Xtz6HjV6tbe3myiAY3kOZuw&response_type=code&redirect_uri=https://t.me/payhub_test_bot?start=success&scope=address,contacts,doc_data,common_data&method=strong&state=xyzABC123"
                >
                  Пройти идентификацию
                </a>
              </Col>
            </Row>
          }
        </Wrapper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
)(ForgotAccessCode);