import React from 'react';
import {ConfigProvider, List, Modal} from "antd";
import mapIconItem from "../../../../services/mapIconItem";
import classes from "./languageModal.module.scss"
import {LOCALES} from "../../../../i18n/locales";

const LanguageModal = (props) => {

  const {open, showModal, languageSelect} = props

  const src = [
    /*{
      text: "О'zbek",
      img: "flagOz",
      locale: LOCALES.UZBEK
    },*/
    {
      text: "Русский",
      img: "flagRu",
      locale: LOCALES.RUSSIAN
    },
    {
      text: "English",
      img: "flagEn",
      locale: LOCALES.ENGLISH
    },
  ]

  return (
    <Modal
      open={open}
      footer={null}
      centered
      closable={false}
      onCancel={showModal}
      className={classes.modal}
    >
      <ConfigProvider
        theme={{
          components: {
            List: {
              itemPaddingSM: '0px 0px',
            },
          },
        }}
      >
        <List
          //bordered
          dataSource={src}
          renderItem={(item) => (
            <List.Item
              onClick={()=>{
                languageSelect(item.locale)
                showModal()
              }}
              size="small"
            >
              <div className={classes.item}>
                {mapIconItem(item.img)} <span className={classes.text}>{item.text}</span> <span className={classes.arrow}>{mapIconItem("arrowRight")}</span>
              </div>
            </List.Item>
          )}
        />
      </ConfigProvider>

    </Modal>
  );
};

export default LanguageModal;