import React, {Component} from 'react';
import {connect} from 'react-redux';
import classes from './cardInfo.module.scss';
import Header from '../../../../Header';
import BottomMenu from '../../../../ui/menu/BottomMenu';
import ListMenu from '../../../../ui/menu/ListMenu';
import Wrapper from '../../../../ui/wrapper';
import ServiceList from '../../../../ui/infoBlocks/ServiceList';
import Card from '../../../../ui/infoBlocks/Card';
import Icon from '../../../../ui/Icon';
import withRouter from "../../../../../hoc/withRouter";
import {setBackLink} from "../../../../../store/actions/authorization";
import {FormattedMessage} from "react-intl";



class CardInfo extends Component {

  componentDidMount() {
    const {setBackLink, location: {pathname}} = this.props
    console.log("11111",  `/${pathname.split("/")[1]}/${pathname.split("/")[2]}`)
    setBackLink(`/${pathname.split("/")[1]}`)
  }

  render() {
    const {navigate, backLink} = this.props
    const src = [
      {
        text: <FormattedMessage id='buttonBlockCard' />,
        icon: "icon-lock",
        action: () => navigate("block")
      },
      {
        text: <FormattedMessage id='buttonCardReissue' />,
        icon: "icon-card-tick2",
        action: () => navigate("reissue")
      },
      {
        text: <FormattedMessage id='buttonLimits' />,
        icon: "icon-maximize-4",
      },
    ]

    const menuList = [
      {
        title: <FormattedMessage id='titleRequisites' />,
        description: <FormattedMessage id='titleCardDetails' />,
        icon: "icon-clipboard-text",
        link: "requisites"
      },
      {
        title: <FormattedMessage id='titleExtract' />,
        description: <FormattedMessage id='titleHistoryOperations' />,
        icon: "icon-receipt-search",
        link: "history"
      },
    ]

    const cardData = {
      blocked: false,
      info: {
        balance: "190 182.22 UZS",
        bonus: "563.1 б.",
        cardNumber: "•• 5576",
        cardDate: "03/25",
        cardCode: "•••",
        type: 2
      }
    }


    return (
      <div className={classes.wrapper}>
        <Header
          transparent
          back="/myBank"
          title="MasterCard WORLD"
          showInfo={()=>{}}
        />
        <Wrapper nopadding style={{marginBottom: 24}}>
          <Card data={cardData}/>
        </Wrapper>
        {
          cardData.blocked &&
            <span className={classes.block}><Icon name="icon-lock"/><FormattedMessage id="titleCardBlocked" /></span>
        }
        <Wrapper nopadding transparent>
          <ServiceList data={menuList} nopadding nomargin size={22} left borderIcon/>
        </Wrapper>
        <Wrapper nopadding>
          <ListMenu borderIcon data={src}/>
        </Wrapper>
        <BottomMenu/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    backLink: state.authorization.backLink,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setBackLink: (backLink) => dispatch(setBackLink({backLink})),
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(CardInfo));