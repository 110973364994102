import React from 'react'
import {useTelegram} from '../../hooks/useTelegram'
import { useNavigate, useLocation} from "react-router-dom"
import mapIconItem from "../../services/mapIconItem";

const TgNav = (props) => {
  const {tg, user, onClose } = useTelegram()
  const location = useLocation();
  const navigate = useNavigate();

  if(location.pathname === "/"){
    tg.BackButton.isVisible = false;
  } else {
    tg.BackButton.isVisible = true;
    tg.BackButton.onClick(() =>navigate(props.backLink))
  }

  return (
    <></>
    //<><span style={{position: "fixed", backgroundColor:"aliceblue", zIndex: "2", top: 16, left: 16}} onClick={() => navigate(props.backLink)}>{mapIconItem("arrowLeft")}</span></>
    /*<nav
      className={"header " + props?.className}
    >

    </nav>*/
  );
};

export default TgNav;