import React from 'react';
import classes from "./categoryList.module.scss"
import {Col, Row} from "antd";
import Wrapper from "../../wrapper";
import ServiceButton from "../../buttons/ServiceButton";
import PrimaryButton from "../../buttons/PrimaryButton";
import {FormattedMessage} from "react-intl";

const CategoryList = (props) => {
  const {data} = props
  return (
    <div >
      <Row gutter={[16,16]}>
        {
          data.map((el, key) => {
            return (

              <Col key={key} span={24} >
                <div className={classes.wrapper}>
                  <Wrapper {...props}>
                    <div className={classes.image} style={{backgroundImage:`url(${el.image})`}}/>
                    <Col span={24}>
                      <div className={classes.title}>{el.title}</div>
                      <div className={classes.description}>{el.description}</div>
                      <div className={classes.button}>
                        <PrimaryButton
                          title={<FormattedMessage id="buttonReedMore" />}
                          bordered
                          link={el.link}
                          small
                        />
                      </div>

                    </Col>

                  </Wrapper>
                </div>

              </Col>
            )
          })
        }
      </Row>
    </div>
  );
};

export default CategoryList;