import axios from "axios";
import uuid from "../services/uuid";
import Services from "../services/Services";

function getConfig() {
  return {
    headers: {
      'X-RqUid': uuid(),
      'x-auth-token' : Services.userService()
      //'X-Auth-Token' : Services.userService(),
      //"content-type": "application/json"
    }
  }
}

export default {
  //Network
  /*getProvider() {
    return axios.get(`https://api.proidea.tech/exchange/providers`);
  },

  getVeriAuth() {
    return axios.get(`https://api.proidea.tech/exchange/get_veri_auth`);
  },*/

  isAutorised() {
    return axios.get("/api/isAutorised", getConfig())
  },

  getCreditData(type, lang) {
    return axios.get(`/api/getCreditData?type=${type}&lang=${lang}`, getConfig())
  },

  getCreditDetails(type, lang) {
    return axios.get(`/api/getCreditDetails?type=${type}&lang=${lang}`, getConfig())
  },

  /*getAccessToken(params) {
    return axios.get(`https://devmyid.uz/api/v1/oauth2/authorization?client_id=payhub_redirect-V5aeynDInnlj0jSd0Xtz6HjV6tbe3myiAY3kOZuw&response_type=code&redirect_uri=tg://t.me/woopay_test_bot2?start=success&scope=address,contacts,doc_data,common_data&method=strong&state=xyzABC123`,);
  },*/
}