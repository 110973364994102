import {Col, Form, Row} from "antd";
import React from "react";
import uuid from "./uuid";

const mapFormItem = (data, ref) => {
  return (
    <Row gutter={24}>
      {
        data.map((el, ind) => {
          const valid = ref.current ? ref.current.getFieldError(el.name).length > 0 : false
          return (
            <Col span={el.col ? el.col : 24} key={el.key ? el.key : uuid()}>
              <Form.Item
                fieldKey={el.name}
                shouldUpdate
                validateTrigger={valid ? "onChange" : "onBlur"}
                {...el}
              >
                {el.children}
              </Form.Item>
            </Col>
          )
        })
      }
    </Row>

  )
}

export default mapFormItem