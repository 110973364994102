import React, {Component} from 'react'
import {connect} from 'react-redux'
import {AutoComplete, Card, Form, Select, Input, Button, Space, Col, Row, Tabs} from 'antd'
import classes from "./securityCode.module.scss"

import PasswordInput from "../../ui/inputs/PasswordInput"
import PrimaryButton from "../../ui/buttons/PrimaryButton"
import {Link} from "react-router-dom"
import {signIn, changeInput, setBackLink} from "../../../store/actions/authorization"
import PhoneInput from "../../ui/inputs/PhoneInput"
import Loader from "../../Loader/Loader"
import withRouter from "../../../hoc/withRouter"
import Services from "../../../services/Services"
import MaskInput from "../../ui/inputs/MaskInput"
import TextInput from "../../ui/inputs/TextInput"
import Header from "../../Header";
import {FormattedMessage} from "react-intl";



class SecurityCode extends Component {

  state = {
    loading: false,
    step: 1,
  }

  signInForm = React.createRef()
  componentDidMount() {
    this.props.setBackLink("/")
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //this.signInForm.current.setFieldsValue({...this.props.form})
  }

  render() {
    const getRules = Services.formValidateRules
    const {step} = this.state
    const {changeInput, navigate} = this.props

    const onStepChange = (currentStep, nextStep, maxStep) => {
      this.signInForm.current.validateFields().then(() =>{
        this.setState({step: nextStep})
      })
      if(currentStep === maxStep) {
        this.props.signIn()
        navigate("/")
      }
    }

    const formSecurityCode = [
      {
        name: "securityCode",
        label:  <FormattedMessage id="labelSecurityCode"/>,
        //rules: getRules("minLength", 8),
        children: <PasswordInput placeholder={"Введите код доступа"} locale="placeholderEnterSecurityCode"/>
      },
    ]

    const securityCodeForm = () => {
      return (
        <>
          <h2 style={{marginBottom: 40}}>
            <FormattedMessage id="titleWelcome" />,<br/>
            Айнура
          </h2>
          {Services.mapFormItem(formSecurityCode, this.signInForm)}
        </>
      )
    }

    const tabsItem = [

      {
        key: 1,
        label: `Код доступа`,
        children: securityCodeForm()
      },
    ]

    return (
      <div className={`${classes.wrapper} ${this.state.loading ? classes.loading : ""}`}>
        <Header
          back="/"
          title={<FormattedMessage id="titlePersonalAccount" />}
          empty={24}
        />
        <Card
          style={{borderRadius: 11}}
          bodyStyle={{padding: "24px 16px 32px 16px", }}
        >
          {
            <>
              <Form
                ref={this.signInForm}
                size="large"
                layout="vertical"
                className={classes.form}
              >
                <Tabs
                  activeKey={step}
                  animated
                  className={classes.tabs}
                  items={tabsItem}
                  onChange={id => onStepChange(step, id, tabsItem.length)}
                  renderTabBar={()=>{}}
                />
              </Form>
              <Row gutter={[16,16]} style={{marginTop:24}}>
                {
                  <Col span={24}>
                    <PrimaryButton
                      size="small"
                      onClick={() => onStepChange(step, step + 1, tabsItem.length)}
                      title={
                        step === tabsItem.length ?
                          <FormattedMessage id="buttonApply" /> :
                          step === 1 ?
                            <FormattedMessage id="buttonContinue" /> :
                            <FormattedMessage id="buttonNextStep" />}
                    />
                  </Col>
                }
                {
                  step === tabsItem.length &&
                    <Col span={24}>
                      <a onClick={() => navigate("/forgotAccessCode")} className={classes.link}><FormattedMessage id="buttonForgotAccessCode"/></a>
                    </Col>
                }
              </Row>
            </>
          }
        </Card>
        {this.state.loading && <Loader/>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    form: state.authorization.form,
    validate : state.authorization.validate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeInput: (name, value, filter) => dispatch(changeInput({name, value, filter})),
    signIn: () => dispatch(signIn()),
    setBackLink: (backLink) => dispatch(setBackLink({backLink})),

  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SecurityCode));