import React, {Component} from 'react'
import {Col, Menu, Row} from "antd"
import {Link, NavLink} from "react-router-dom"
import Services from "../../../../services/Services"
import classes from "./bottomMenu.module.scss"
import Icon from "../../Icon";
import {signIn} from "../../../../store/actions/authorization";
import {connect} from "react-redux";
import withRouter from "../../../../hoc/withRouter";
import {FormattedMessage} from "react-intl";

class BottomMenu extends Component{
  render() {
    const {signIn} = this.props
    const menuItems = [
      {
        label: "Главная",
        icon: "icon-home-2",
        link: "",
        key: "",
        lang_id: "bottomMenuHome",
      },
      {
        label: "Карты",
        icon: "icon-card",
        link: "cards",
        key: "cards",
        lang_id: "bottomMenuCards",
      },
      {
        label: "Платежи",
        icon: "icon-wallet2",
        link: "payments",
        key: "payments",
        lang_id: "bottomMenuPayments",
      },
      {
        label: "Переводы",
        icon: "icon-convert-card",
        link: "transfers",
        key: "transfers",
        lang_id: "bottomMenuTransfers",
      },
    ]

    const menuItemsAutorised = [
      {
        label: "Главная",
        icon: "icon-home-2",
        link: "",
        key: "",
        lang_id: "bottomMenuHome",
      },
      {
        label: "Мой банк",
        icon: "icon-bank",
        link: "myBank",
        key: "myBank",
        lang_id: "bottomMenuMyBank",
      },
      {
        label: "Платежи",
        icon: "icon-wallet2",
        link: "payments",
        key: "payments",
        lang_id: "bottomMenuPayments",
      },
      {
        label: "Мой QR",
        icon: "icon-scan-barcode",
        link: "myQRCode",
        key: "myQRCode",
        lang_id: "bottomMenuMyQR",
      },
    ]

    const onChangeRoute = ({key}) => {
      this.setState({
        key
      })
    }

    const getItems = (items) => {
      return items.map((el, ind) => {
        return {
          ...el,
          key: el.key,
          icon: <Icon name={el.icon}/>,
          label: el.children ? <a><FormattedMessage id={el.lang_id} /></a> : <NavLink key={ind} to={`/${el.key}`}><FormattedMessage id={el.lang_id} /></NavLink>,
        }
      })
    }

    return (
      <div className={classes.menuWrapper}>
        <Menu
          className={classes.menu}
          onClick={onChangeRoute}
          selectedKeys={`${this.props.location.pathname.split('/')[1]}`}
          mode="horizontal"
          items={getItems(signIn ? menuItemsAutorised : menuItems)}
          defaultSelectedKeys={['/addresses']}
          disabledOverflow={false}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    signIn: state.authorization.signIn,
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(BottomMenu));