import {FormattedMessage} from "react-intl";
import React from "react";



const formValidateRules = (id, val, term) => {

  switch (id) {
    case "required":
      return [
        {
          required: true,
          message: <FormattedMessage id="errorRequired" />
        }
      ];
    case "checked":
      return [
        ({ getFieldValue }) => ({
          validator: (_, value) => {
            return value ? Promise.resolve() : Promise.reject(() => <FormattedMessage id="errorRequired" />)
          }
        }),
      ];
    case "minLength":
      return [
        {
          required: true,
          message: <FormattedMessage id="errorRequired" />,
        },
        {
          type: 'string',
          min: val ? val : 2,
          message: val ? <FormattedMessage id="errorMinLength" values={{ val }} /> : <FormattedMessage id="errorNotEnoughLength" />,
        }
      ];
    case "length":
      return [
        {
          required: true,
          message: <FormattedMessage id="errorRequired" />,
        },
        {
          len: val,
          message: <FormattedMessage id="errorLength" values={{ val }} />,
        }
      ];
    case "phone":
      return [
        {
          required: true,
          message: <FormattedMessage id="errorRequired" />,
        },
        {
          len: val,
          message: <FormattedMessage id="errorNumber" values={{ val }} />,
        }
      ];
    case "date":
      return [
        {
          required: true, message: <FormattedMessage id="errorNeedDate" />
        }
      ];
    case "latitude":
      return [
        {
          required: true,
          message: <FormattedMessage id="errorRequired" />,
        },
        {
          pattern: `-?\\d{1,3}\\.\\d+`,
          message: <FormattedMessage id="errorLatitudeFormat" />
        }
      ];
    case "longitude":
      return [
        {
          required: true,
          message: <FormattedMessage id="errorRequired" />,
        },
        {
          pattern: `-?\\d{1,3}\\.\\d+`,
          message: <FormattedMessage id="errorLongitudeFormat" />
        }
      ];
    case "long&lat":
      return [
        {
          required: true,
          message: <FormattedMessage id="errorRequired" />,
        },
        {
          pattern: `^[-+]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?),\\s*[-+]?(180(\\.0+)?|((1[0-7]\\d)|([1-9]?\\d))(\\.\\d+)?)$`,
          message: <FormattedMessage id="errorLongLatFormat" />
        }
      ];
    case "email":
      return [
        {
          required: true,
          message: <FormattedMessage id="errorRequired" />,
        },
        {
          pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: <FormattedMessage id="errorEmailFormat" />
        }
      ];
    case "password":
      return [
        {
          required: true,
          message: <FormattedMessage id="errorRequired" />,
        },
        {
          min: val ? val : 2,
          message: val ? <FormattedMessage id="errorMinLength" values={{ val }} /> : <FormattedMessage id="errorNotEnoughLength" />,
        },
        {
          pattern: /^\d+$/,
          message: <FormattedMessage id="errorNumber" values={{ val }} />,
        },
        /*{
          pattern: /^(?=.*[a-z].*[a-z])(?=.*[!"#...\d].*[!"#...\d]).{8,}$/gmi,
          message: 'Должно содержать заглавные и строчные буквы, цифры и спецсимволы',
        },*/
      ]
    case "passwordConfirm":
      return [
        {
          required: true,
          message: <FormattedMessage id="errorRequired" />,
        },
        {
          min: val ? val : 2,
          message: val ? <FormattedMessage id="errorMinLength" values={{ val }} /> : <FormattedMessage id="errorNotEnoughLength" />,
        },
        {
          pattern: /^\d+$/,
          message: <FormattedMessage id="errorNumber" values={{ val }} />,
        },
        /*{
          pattern: /^(?=.*[a-z].*[a-z])(?=.*[!"#...\d].*[!"#...\d]).{8,}$/gmi,
          message: 'Должно содержать заглавные и строчные буквы, цифры и спецсимволы',
        },*/
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue([term]) === value) {
              return Promise.resolve();
            }
            return Promise.reject(() => <FormattedMessage id="errorPasswordsDontMatch" />);
          },
        }),
      ]
  }
}

export default formValidateRules