import React, {Component} from 'react';
import {connect} from 'react-redux';
import Header from "../../../../Header";
import BottomMenu from "../../../../ui/menu/BottomMenu";
import classes from "./creditList.module.scss"
import {Col, Row} from "antd";
import Wrapper from "../../../../ui/wrapper";
import ServiceButton from "../../../../ui/buttons/ServiceButton";
import ServiceList from "../../../../ui/infoBlocks/ServiceList";
import {setBackLink} from "../../../../../store/actions/authorization";



class CreditList extends Component {
  componentDidMount() {
    this.props.setBackLink("/")
  }

  render() {
    const {config: {back, title, menuList, notification},  signIn} = this.props

    return (
      <div className={classes.wrapper}>
        <Header
          back={back}
          title={title}
          notification={ signIn ? notification : false}
          empty={!signIn}
        />
        <Wrapper nopadding transparent>
          <ServiceList data={menuList} bordered nopadding nomargin left={true} size={24} height100/>
        </Wrapper>
        <BottomMenu/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    signIn: state.authorization.signIn,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setBackLink: (backLink) => dispatch(setBackLink({backLink})),
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(CreditList);