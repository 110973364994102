import React from 'react';
import classes from "./card.module.scss"
import {Col, Row} from "antd";
import mapIconItem from "../../../../services/mapIconItem";
import Services from "../../../../services/Services";
import Icon from "../../Icon";
import {FormattedMessage} from "react-intl";

const Card = (props) => {
  const {data: {info, requisites}} = props
  return (
    <div className={classes.wrapper}>
      {
        info &&
        <>
          <div className={classes.balance}>
            <label><FormattedMessage id='titleCardBalance' />:</label>
            {info.balance}
          </div>

          <div className={classes.bonus}>
            <label><FormattedMessage id='titleBonuses' /></label>
            <div><div className={classes.bonusValue}>{Services.mapIconItem("iconCrown")}{info.bonus}</div></div>
          </div>

          <div className={classes.cardInfo}>
            <Row gutter={8}>
              <Col span={7}>
                <div className={classes.cardNum}>
                  <label>Card Number</label>
                  <div className={classes.info}>{info.cardNumber}</div>
                </div>
              </Col>
              <Col span={6}>
                <div className={classes.cardDate}>
                  <label>Valid Till</label>
                  <div className={classes.info}>{info.cardDate}</div>
                </div>
              </Col>
              <Col span={5}>
                <div className={classes.cardCode}>
                  <label>CVC2/CVV2</label>
                  <div className={classes.info}>{info.cardCode}</div>
                </div>
              </Col>
              <Col span={6}>
                <div className={classes.cardLogo}>
                  {
                    mapIconItem("logoMC")
                  }
                </div>
              </Col>
            </Row>
          </div>
        </>
      }
      {
        requisites &&
          <>
            <div>
              <div className={classes.copy}>
                <div>
                  <label>Cardholder Name</label>
                  <div className={classes.info}>{requisites.cardNumber}</div>
                </div>

                <span className={classes.btn}><Icon name="icon-copy"/></span>

              </div>
            </div>
            <div className={classes.cardInfo}>
              <Row gutter={8}>
                <Col span={14}>
                  <div className={classes.cardNum}>
                    <label>Cardholder Name</label>
                    <div className={classes.infoReq}>{requisites.cardholderName}</div>
                  </div>
                </Col>
                <Col span={5}>
                  <div className={classes.cardDate}>
                    <label>Valid Till</label>
                    <div className={classes.infoReq}>{requisites.cardDate}</div>
                  </div>
                </Col>
                <Col span={5}>
                  <div className={classes.cardCode}>
                    <label>CVC/CVV</label>
                    <div className={classes.infoReq}>{requisites.cardCode}</div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className={classes.footer}>
              <Row gutter={8}>
                <Col span={18}>
                  <div>
                    <label>
                      Карта доступна до марта 2025<br/>
                      Никому не сообщайте данные своей карты
                    </label>
                  </div>

                </Col>
                <Col span={6}>
                  <div className={classes.cardLogo}>
                    {
                      mapIconItem("logoMC")
                    }
                  </div>
                </Col>
              </Row>
            </div>
          </>
      }

    </div>
  );
};

export default Card;